import React, { Component } from 'react'

//Import CSS
import '../alert-cookie/alert-cookie.css'

//Import internal components
import AppContext from '../../appContext'

//Import external components
import { Alert, Button } from 'react-bootstrap'

//Import image

class AlertCookie extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        };

        AlertCookie.contextType = AppContext;
        this.closeAlert = this.closeAlert.bind(this);

    }

    componentDidMount(){
        var acceptCookie = localStorage.getItem('acceptCookie')
        if(!acceptCookie){
            this.setState({ show: true })
        }
    }


    closeAlert() {
        this.setState({ show: false }, () => {
            localStorage.setItem('acceptCookie', true)
        })
    }

    render() {
        // const [show, setShow] = UseState(true);
        return (
            <div className='fixed-bottom'>

                <Alert show={this.state.show} variant="primary ">
                    <Alert.Heading>Política de Cookies!</Alert.Heading>
                    <div className='row'>
                        <div className='col-10'>
                            <p>
                                Possuímos uma política de cookies para garantir uma melhor experiência para você! Caso continue, vamos supor que você está de acordo com nossa política de 
                                <Alert.Link target='_blank' href="https://www.essilor.com.br/aviso-de-privacidade.html"> Aviso de Privacidade</Alert.Link>.
                            </p>
                        </div>
                        <div className='col-2 '>
                            <Button onClick={() => this.closeAlert(false)} variant="outline-primary " className='float-end'>
                                Entendi
                            </Button>
                        </div>
                    </div>
                </Alert>
            </div>
        );
    }
}

export default AlertCookie;