import React, { Component } from "react";

//Import CSS
import "./inputs.css";

//Import internal components

//Import external components

//Import images

class TrackingSearchButton extends Component {
  render() {
    return (
      <button className="tracking-search-button buttonSpace" type="button" onClick={this.props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="22.75" height="22.745" viewBox="0 0 22.75 22.745">
          <path
            className="x-follow-order__filter-search"
            id="np_search_860389_000000"
            d="M21.938,20.3l5.8,5.8a1.16,1.16,0,0,1-1.64,1.64l-5.8-5.8A9.281,9.281,0,1,1,21.938,20.3Zm-2.411-.77a6.961,6.961,0,1,0-9.845,0A6.961,6.961,0,0,0,19.528,19.528Z"
            transform="translate(-5.324 -5.324)"
            fill="#fff"
            fillRule="evenodd"
          />
        </svg>
        Buscar
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
          <g id="Group_13" data-name="Group 13" transform="translate(-117.064 -5.315)">
            <circle id="Ellipse_9" data-name="Ellipse 9" cx="10.5" cy="10.5" r="10.5" transform="translate(117.064 5.315)" fill="#fff" />
            <path
              className="x-follow-order__filter-arrow"
              id="np_arrow_2957554_000000"
              d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
              transform="translate(124.214 19.131) rotate(-90)"
              fill="#0066a0"
            />
          </g>
        </svg>
      </button>
    );
  }
}

export default TrackingSearchButton;
