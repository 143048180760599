import React, { Component } from 'react'
import RolesManagementTableBodyTr from './roles-management-table-body-tr'
import ReactPaginate from "react-paginate";

class RolesManagementTableBody extends Component {

  
  render() {
    const {
      alterarPerfilUsuario,
      removerUsuario,
      listaPapeis,
      listaUsuarios,
      inativarUsuario,
      isInativar
    } = this.props

    return (
      
      <tbody>
        
        {listaUsuarios.map((item, index) => {
          return (
            <RolesManagementTableBodyTr
              id={item.id}
              nome={item.nome + " " + item.sobrenome}
              email={item.email}
              perfil={item.id_perfil}
              cnpj={item.cnpj}
              inativo={item.inativo}
              erro={item.erro}
              userLogado={item.userLogado}
              cpf={item.cpf}
              removerUsuario={removerUsuario}
              alterarPerfilUsuario={alterarPerfilUsuario}
              listaPapeis={listaPapeis}
              inativarUsuario={inativarUsuario}
              isInativar={isInativar}
            />
          )
        })}
      </tbody>
    )
  }
}

export default RolesManagementTableBody
