import React, { Component } from "react";
import "./table.css";
import TableBodyDropdown from "./table-body-dropdown";

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetailsIndex: null,
      status: "",
      arrow: "",
    };
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changed !== this.props.changed) {
      this.setState({ showDetailsIndex: null, rowIndex: "" });
    }
  }

  toggleDetail(rowIndex) {
    this.setState(prevState => ({
      showDetailsIndex: prevState.showDetailsIndex === rowIndex ? null : rowIndex,
      arrow: prevState.showDetailsIndex === rowIndex ? "" : "inverse",
    }));
  }

  render() {
    const { showDetailsIndex, arrow } = this.state;

    return (
      <>
        {this.props.tablePaginate.map((row, rowIndex) => (
          <tbody className="tracking-main-table-body">
            <React.Fragment key={rowIndex}>
              <tr className="table-line-pointer" onClick={() => this.toggleDetail(rowIndex)} data-result={rowIndex}>
								<td className="financeiro-table-body-td">
									<spam className={`arrow-financeiro ${showDetailsIndex === rowIndex ? "inverse" : ""}`}></spam>
								</td>
                {Object.entries(row).map(([key, value], cellIndex) => {
                  if (key !== "detalhes" && key !== "status") {
                    if (key === "Valor em Aberto" || key === "valor") {
                      return (
                        <td className="financeiro-table-body-td" key={cellIndex}>
                          {parseFloat(value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                        </td>
                      );
                    } else {
                      return (
                        <td className="financeiro-table-body-td" key={cellIndex}>
                          {value}
                        </td>
                      );
                    }
                  } else if (key === "status") {
                    return (
                      <td key={cellIndex} className="financeiro-table-body-td">
                        <div className="financeiro-td-status-fatura">
                          <p data-status={value}>{value === "PAGO" ? "PAGO" : "EM ABERTO"}</p>
                        </div>
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
              {showDetailsIndex === rowIndex && (
                <tr className="financeiro-table-body-tr">
                  <td colSpan="10" className={`financeiro-table-body-td ${arrow} financeiro-table-body-td-dropdown`} data-details={rowIndex}>
                    {<TableBodyDropdown info={this.props.info} table={row.detalhes} cnpj_number={this.props.cnpj_number} />}
                  </td>
                </tr>
              )}
            </React.Fragment>
          </tbody>
        ))}
      </>
    );
  }
}

export default TableBody;
