import React, { Component } from 'react'
import logobanner from './img/logo-banner-2021.png'

class Banner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgnum: Math.floor(Math.random() * 7) + 1
        };
    }

    componentDidMount() {
        this.setState({ imgnum: Math.floor(Math.random() * 7) + 1 })
    }

  	render(){
        const {imgnum} = this.state;
        var imgLink = 'login-banner__main login-banner_' + imgnum;
        var imgLinkTablet = 'login-signin__sign-up--tablet-image_' + imgnum;

        function openLogin(e) {
            e.preventDefault();
            var element = document.getElementById("loginMobile");
            element.classList.add("is--active");
            var element1 = document.getElementById("loginGeral");
            element1.classList.add('has--no-scroll');
            document.querySelector('.login-banner__main').style.display = 'none'
        }

    	return (
            <div className={imgLink}>
                <div className='login-banner__color'>
                    <div className='login-banner__container'>
                        <div className='login-banner__logo'>    
                            <img alt="" src={logobanner} className='login-banner__logo-image' />
                        </div>
                        <div className='login-banner__title'>
                            <h1 className='login-banner__title-text'>
                                Este espaço é para você!
                        </h1>
                        </div>
                        <div className='login-banner__paragraph'>
                            <p className='login-banner__paragraph-text'>
                            Bem-vindo ao Essilor Conecta, um
                            ambiente único de relacionamento entre
                            a Essilor e seus parceiros. Conecte-se com
                            a gente e leve soluções inovadoras e
                            benefícios únicos da Essilor diretamente
                            para o seu negócio.
                        </p>
                            <a className='login-banner__button-mobile js--open-login' href='/#' onClick={openLogin}>Fazer login</a>
                        </div>
                        <div className='login-signin__sign-up--mobile'>
                            <p className='login-signin__sign-up--text'>
                                Ainda não tem uma conta?
                            </p>
                            <a href='/signup' className='login-signin__sign-up--link'>
                                Cadastre-se aqui
                            </a>
                        </div>
                        <div className='login-signin__sign-up--tablet'>
                            <div className={imgLinkTablet}></div>
                        </div>
                    </div>
                </div>
            </div>
    	);
  	}
}

export default Banner;