import React from "react";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";

const GenericAlertMessage = ({ message }) => (
	<div>
		{ message }
	</div>
);

const showGenericAlertMessage = (message) => {
	const html = ReactDOMServer.renderToStaticMarkup(
		<GenericAlertMessage message={ message }/>
	);

	return Swal.fire({
		html,
		icon: "warning",
		title: "Aviso",
		confirmButtonColor: "#00518b",
		allowOutsideClick: false,
		showCancelButton: false,
		confirmButtonText: "Ok!",
		customClass: {
			confirmButton: "x-follow-order__filter-btn",
		},
	});
};

export default showGenericAlertMessage;
