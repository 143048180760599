import React, { Component } from "react";

//Import CSS
import "bootstrap/dist/css/bootstrap.min.css";

//Import internal components

//Import external components
import Card from "react-bootstrap/Card";

//Import images

class OrderSummaryFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { order } = this.props;

    return (
      <Card.Body>
        <h3 className="x-web-ordering-pedido__summary--text">Informações da armação</h3>

        <ul className="x-web-ordering-pedido__summary--card shape">
          <li className="x-web-ordering-pedido__summary--subitem">Tipo de armação</li>
          <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_tipo_arm_value || "-"}</li>
        </ul>

        {order.control_horizontal && (
          <ul className="x-web-ordering-pedido__summary--card shape">
            <li className="x-web-ordering-pedido__summary--subitem">Horizontal</li>
            {!order.remoteEdgeDisabled && <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_info_arm_horizontal || "-"}</li>}

            {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
          </ul>
        )}

        {order.control_vertical && (
          <ul className="x-web-ordering-pedido__summary--card shape">
            <li className="x-web-ordering-pedido__summary--subitem">Vertical</li>
            {!order.remoteEdgeDisabled && <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_info_arm_vertical || "-"}</li>}

            {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
          </ul>
        )}

        {order.control_ponte && (
          <ul className="x-web-ordering-pedido__summary--card shape">
            <li className="x-web-ordering-pedido__summary--subitem">Ponte</li>
            {!order.remoteEdgeDisabled && <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_info_arm_ponte || "-"}</li>}

            {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
          </ul>
        )}

        {order.control_diagonal && (
          <ul className="x-web-ordering-pedido__summary--card shape">
            <li className="x-web-ordering-pedido__summary--subitem">Diagonal Maior</li>

            {!order.remoteEdgeDisabled && <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_info_arm_diametro || "-"}</li>}

            {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
          </ul>
        )}

        <ul className="x-web-ordering-pedido__summary--card shape">
          <li className="x-web-ordering-pedido__summary--subitem">Referência da Armação</li>
          <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_modelo_v2 || "-"}</li>
        </ul>
				<ul className="x-web-ordering-pedido__summary--card shape">
					<li className="x-web-ordering-pedido__summary--subitem">Cor</li>
					<li className="x-web-ordering-pedido__summary--subitem right">{order.cor_armacao_value || '-'}</li>
				</ul><ul className="x-web-ordering-pedido__summary--card shape">
					<li className="x-web-ordering-pedido__summary--subitem">Marca</li>
					<li className="x-web-ordering-pedido__summary--subitem right">{order.marca_armacao_value ? (order.marca_armacao_outras && order.marca_armacao_value === "OUTRAS"? (order.marca_armacao_value+" - "+order.marca_armacao_outras): order.marca_armacao_value): ""}</li>
				</ul>
        {order.control_modelo && (
          <ul className="x-web-ordering-pedido__summary--card shape">
            <li className="x-web-ordering-pedido__summary--subitem">Modelo da armação</li>
            <li className="x-web-ordering-pedido__summary--subitem right">{order.frame_modelo || "-"}</li>
          </ul>
        )}

        <h3 className="x-web-ordering-pedido__summary--text">Dados Adicionais</h3>

        <ul className="x-web-ordering-pedido__summary--card shape">
          <li className="x-web-ordering-pedido__summary--subitem">Observação</li>
          <li className="x-web-ordering-pedido__summary--subitem right">
            {order.descricao || order?.pedidoColorirNaOtica == true ? (
              <>
                {order.descricao ? order.descricao : ''}
                {(order?.pedidoColorirNaOtica == true) && <b> - Cliente escolheu colorir na ótica.</b>}
              </>
            ) : (
              "-"
            )}
          </li>
        </ul>
      </Card.Body>
    );
  }
}

export default OrderSummaryFrame;
