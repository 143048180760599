import React, { Component } from 'react'
import './main.css'
import { Link } from 'react-router-dom'
import marcas from './img/marcas2.png'
import logoessilor from './img/logo-essilor.png'
import footermobile1 from './img/footer-mobile-1.png'
import footermobile2 from './img/footer-mobile-2.png'
import footermobile3 from './img/footer-mobile-3.png'
import footermobile4 from './img/footer-mobile-4.png'
import footermobile5 from './img/footer-mobile-5.png'
import footermobile6 from './img/footer-mobile-6.png'
import footerfacebooklogo from './img/footer-facebook-logo.png'
import AppContext from '../appContext'
import Termos from './termos'
import Moment from 'moment'

class Footer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			loading: true,
		}

		Footer.contextType = AppContext
		this.showPopup = this.showPopup.bind(this)
		this.hidePopup = this.hidePopup.bind(this)
	}

	showPopup(e) {
		e.preventDefault()
		var popup = document.getElementById('popup')
		this.setState({ loading: false })
		popup.classList.remove('hide-popup')
		document.getElementById('myNav').style.display = 'block'
		if (document.getElementById('headerBtn') && document.getElementById('headerBtn1')) {
			document.getElementById('headerBtn').style.visibility = 'hidden'
			document.getElementById('headerBtn1').style.visibility = 'hidden'
		}
		this.context.hideLoading();
	}

	hidePopup() {
		if (document.getElementById('headerBtn') && document.getElementById('headerBtn1')) {
			document.getElementById('headerBtn').style.visibility = ''
			document.getElementById('headerBtn1').style.visibility = ''
		}
		document.getElementById('myNav').style.display = 'none'
		var popup = document.getElementById('popup')
		popup.classList.add('hide-popup')
	}

	render() {
		const ano = Moment().format('YYYY'); 
		return (
			<footer className="x-footer">
				<div className="x-footer__brands marcas_footer">
					<img src={marcas} alt="" className="x-footer__brand-img" />
				</div>
				{/* <div className="x-footer__brands is--mobile">
					<div className="x-footer__brand-row">
						<div className="x-footer__brand-item">
							<img src={footermobile1} alt="" className="x-footer__brand-img" />
						</div>
						<div className="x-footer__brand-item">
							<img src={footermobile2} alt="" className="x-footer__brand-img" />
						</div>
						<div className="x-footer__brand-item">
							<img src={footermobile3} alt="" className="x-footer__brand-img" />
						</div>
					</div>
					<div className="x-footer__brand-row">
						<div className="x-footer__brand-item">
							<img src={footermobile4} alt="" className="x-footer__brand-img" />
						</div>
						<div className="x-footer__brand-item">
							<img src={footermobile5} alt="" className="x-footer__brand-img" />
						</div>
						<div className="x-footer__brand-item">
							<img src={footermobile6} alt="" className="x-footer__brand-img" />
						</div>
					</div>
				</div> */}
				<div className="x-footer__bottom">
					<div className="x-footer__links">
						<Link to="/faq" className="x-footer__link">Perguntas frequentes</Link>
						<span className="x-footer__circle"></span>
						<a href="/#" className="x-footer__link js--input-terms" htmlFor="terms" onClick={this.showPopup}>
							Termos do site
						</a>
					</div>
					<div className="x-footer__facebook">
						<p className="x-footer__facebook-text">Siga nossa página</p>
						<img src={footerfacebooklogo} alt="" className="x-footer__facebook-logo" />
						<a href="https://www.facebook.com/EssilorConecta" className="x-footer__facebook-link" target='_blank'>
							@EssilorConecta
						</a>
					</div>
					<div className="footer-links__right">
						<div className="footer-links__right-container">
							<div className="footer-links__right-copyright">
								@{ano} - Todos os direitos reservados
							</div>
							<img alt="" src={logoessilor} className="footer-links__right-image" />
						</div>
					</div>
				</div>
				<div id="myNav" className="overlay">
					<section className="signup-popup">
						<div className="signup-popup__container hide-popup" id="popup">
							<Termos />
						</div>
					</section>
				</div>
			</footer>
		)
	}
}

export default Footer
