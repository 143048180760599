import React, { Component } from "react";
import AppContext from "../../appContext";
import ProductCardResult from "./card-result/product-card-result";
import ProductForm from "./form/product-form";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      result: null,
      errorMessage: "",
      isResult: false,
    };

    Product.contextType = AppContext;

    this.setResult = this.setResult.bind(this);
    this.setError = this.setError.bind(this);
    this.cleanSeach = this.cleanSeach.bind(this);
  }

  componentDidMount(){
     if(this.props.selected_option !== "produtos"){
      this.props.handleChangeOption("produtos");
    }
  }
  setResult(jsonResult) {
    this.setState({ result: jsonResult, isResult: true });
  }

  setError(error) {
    this.setState({ errorMessage: error, isResult: true });
  }

  cleanSeach() {
    this.setState({ result: null, errorMessage: "", isResult: false });
  }

  render() {
    return (
      <div className="row mt-3 product-managemente-container">
        <div className="product-managemente-card">
          <div className="col-12">
            <h1 className="conecta-h1" style={{ fontSize: "1.25rem" }}>
              <strong>Consultar Produto</strong>
            </h1>
          </div>
          <div className="col-12">
            <ProductForm laboratorios={this.props.laboratorios} setResult={this.setResult} setError={this.setError} cleanSeach={this.cleanSeach} />
            {this.state.isResult && <ProductCardResult data={this.state.result} error={this.state.errorMessage} />}
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
