import React, { Component } from 'react'
import HeaderLogo from '../main/header/header-logo'

class HeaderFaq extends Component {

  render() {
    return (
        <div className="header__container">
           <HeaderLogo></HeaderLogo>
        </div>
    );
  }
}

export default HeaderFaq;