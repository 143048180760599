import React, { Component } from 'react'

//Import CSS
import './confirmed.css'

//Import internal components

//Import external components

//Import images

class IconConfirmed extends Component {
	constructor(props) {
		super(props)

		this.state = {
		}
	}

	render() {

		return (

			<div className="x-order-confirmed__container">
				<svg
					className="x-order-confirmed__icon"
					xmlns="http://www.w3.org/2000/svg"
					width="165.236"
					height="137.886"
				>
					<g id="icon" transform="translate(-575.764 -114.947)">

						<circle
							id="Ellipse_14"
							data-name="Ellipse 14"
							cx="60.5"
							cy="60.5"
							r="60.5"
							transform="translate(620 131.833)"
							fill="#0066b3"
							opacity="0.07"
						/>
						<g
							id="np_success_2909316_000000"
							transform="translate(575.764 114.947)"
						>
							<path
								id="Path_75"
								data-name="Path 75"
								d="M79.327,54.071A11.355,11.355,0,0,0,90.685,42.712a11.7,11.7,0,0,0-.062-1.187,16.622,16.622,0,0,0,6.838.3.9.9,0,0,0,.466-1.567c-2.1-1.971-2.749-5.827-2.749-9.087,0-5.418-5.656-5.151-5.656-5.151-4.339,0-5.475,4.663-5.509,6.361a11.215,11.215,0,0,0-4.685-1.022,11.358,11.358,0,0,0,0,22.717Zm-5.5-18.57c.761,2.317,2.885,5.605,8.831,5.605,0,0,.278,5.458,3.975,6.991A9.075,9.075,0,1,1,73.824,35.5Z"
								transform="translate(17.612 -2.43)"
								fill="#0066b3"
							/>
							<path
								id="Path_76"
								data-name="Path 76"
								d="M110.6,103.75,98.068,66.426a13.665,13.665,0,0,0-9.876-8.967l-7.917-1.834a12.951,12.951,0,0,1-8.081-5.793L56.151,23.93a5.938,5.938,0,0,1-.466,1.914l-3.76,8.6,12.863,27.5a22,22,0,0,1,1.726,13.2C65.408,81.334,64,90.705,64,98.315c0,8.894,1.55,14.243,2.459,16.606a2.267,2.267,0,0,0,2.118,1.431H98.268a2.269,2.269,0,0,0,2.272-2.226l.125-6.5L89.272,79.761a1.136,1.136,0,1,1,2.1-.863l9.4,22.994v-.023l2.238,4.878a4.085,4.085,0,0,0,7.587-3Z"
								transform="translate(10.331 -3.376)"
								fill="#0066b3"
							/>
							<path
								id="Path_77"
								data-name="Path 77"
								d="M70.083,32.979l2.959-6.769A3.632,3.632,0,0,0,66.534,23L52.978,47.8A21.69,21.69,0,0,1,38.234,58.6c-5.889,1.21-12.971,4.282-15.975,11.937-5.372,13.687-10.7,28.01-12.937,34.558a4.024,4.024,0,0,0,2.3,5.032,4.069,4.069,0,0,0,1.516.3,4.022,4.022,0,0,0,3.487-2.01L22.7,98.671,31.51,77.965a1.135,1.135,0,0,1,2.09.886L25.4,98.126v17.231a2.266,2.266,0,0,0,2.266,2.272H49.792a2.26,2.26,0,0,0,2.266-2.226c.119-7.366.67-19.115.977-25.437a16.888,16.888,0,0,1,2.039-7.349c2.323-4.214,3.2-7.292,1.795-11.512-1.732-5.191-.437-6.872-.437-6.872Z"
								transform="translate(-9.104 -4.651)"
								fill="#0066b3"
							/>
							<path
								id="Path_78"
								data-name="Path 78"
								d="M15.943,50.119a5.119,5.119,0,0,0,1.675-.3c-.006.1-.028.2-.028.3a5.169,5.169,0,0,0,9.03,3.453,11.317,11.317,0,0,0,19.457-9.558,4.258,4.258,0,0,0,3.362-6.758A4.266,4.266,0,0,0,47.155,30a5.089,5.089,0,0,0-4.061-6.389c0-.034.011-.062.011-.1a4.27,4.27,0,0,0-8.155-1.777,5.008,5.008,0,0,0-4.782.153,4.257,4.257,0,0,0-7.57,1.5,5.189,5.189,0,0,0-4.112,5.071,5.091,5.091,0,0,0,.267,1.562,5.168,5.168,0,0,0-4.611,5.128,4.991,4.991,0,0,0,.068.653A5.043,5.043,0,0,0,11.763,41.9a5.158,5.158,0,0,0,4.18,8.218Zm18.929,4.753a9.05,9.05,0,0,1-6.44-2.686,4.268,4.268,0,0,0,3.805-4.231,4.041,4.041,0,0,0-.187-1.176,4.268,4.268,0,0,0,2.823-4.009c0-.011-.006-.023-.006-.034a4.266,4.266,0,0,0,3.964-4.248,4.169,4.169,0,0,0-.108-.926,9.082,9.082,0,0,1-3.85,17.31Z"
								transform="translate(-8.354 -5.504)"
								fill="#0066b3"
							/>
							<path
								id="Path_79"
								data-name="Path 79"
								d="M51.775,22.906a2.271,2.271,0,0,0,2.272-2.272v-8.57a2.272,2.272,0,0,0-4.543,0v8.57A2.271,2.271,0,0,0,51.775,22.906Z"
								transform="translate(9.232 -9.793)"
								fill="#0066b3"
							/>
							<path
								id="Path_80"
								data-name="Path 80"
								d="M56.014,24.2a2.271,2.271,0,0,0,1.607-.664l6.06-6.06a2.273,2.273,0,0,0-3.214-3.214l-6.06,6.06A2.272,2.272,0,0,0,56.014,24.2Z"
								transform="translate(11.155 -8.064)"
								fill="#0066b3"
							/>
							<path
								id="Path_81"
								data-name="Path 81"
								d="M47.587,23.541A2.273,2.273,0,1,0,50.8,20.326l-6.06-6.06a2.273,2.273,0,0,0-3.214,3.214Z"
								transform="translate(5.31 -8.063)"
								fill="#0066b3"
							/>
						</g>
					</g>
				</svg>
			</div>
		)
	}
}

export default IconConfirmed
