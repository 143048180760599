import React, { Component, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { FileEarmarkTextFill } from "react-bootstrap-icons";
import Swal from "sweetalert2";

import PDFOrderHeader from "./pdf-order-header";
import PDFOrderDados from "./pdf-order-dados";
import PDFOrderProdutos from "./pdf-order-produtos";
import PDFOrderDadosAdicionais from "./pdf-order-dados-adicionais";
import PDFOrderFooter from "./pdf-order-footer";

//Import CSS
import "./pdf-order.css";
import AppContext from "../../appContext";

class PDFOrderAtacado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      order: null,
      header: null,
      dados: null,
      produtos: null,
      quantProdutos: 0,
      observacao: null,
      footer: null,
      empresa_faturamento: "",
      empresa_faturamento_cnpj: "",
      cnpj_number:"",
    };

    PDFOrderAtacado.contextType = AppContext;
  }

  reactToPrintTrigger = () => {
    const { label, btn_class, icon } = this.props;

    return (
      <Button className={btn_class ? btn_class : "pdf-button"} variant="link">
        {icon && <FileEarmarkTextFill size={22} className="me-2" />}
        {label ? label : "Visualizar Pedido"}
      </Button>
    );
  };

  handleOnBeforeGetContent = () => {

    
    const { laboratorio, otica, pedido_laboratotio, redeOticas, local } = this.props;
    this.context.showLoading("");
   
    
    var header = {};
    if (redeOticas || local === "tracking")  {
      header = {
        client_id: localStorage.getItem("clientid"),
        access_token: localStorage.getItem("token"),
        laboratorio: laboratorio,
        otica: otica,
      };
    } else {
      header = {
        client_id: localStorage.getItem("clientid"),
        access_token: localStorage.getItem("token"),
        laboratorio: laboratorio,
      };
    }

    return new Promise((resolve: any) => {
      const requestOptions = {
        method: "GET",
        headers: header,
      };

      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pedidos-atacado/" + pedido_laboratotio, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            this.context.hideLoading();
            Swal.fire({
              text: "Erro ao tentar recuperar as informações do pedido. Tente novamente mais tarde.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        })
        .then(json => {
          if (json) {
            if (json.hasOwnProperty("codigo")) {
              this.context.hideLoading();
              Swal.fire({
                text: json.mensagem,
                icon: "error",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              });
            } else {
              this.getEmpresaFaturamento(() => {
                this.setState(
                  {
                    header: this.setHeader(json),
                    dados: this.setDados(json),
                    produtos: this.setProdutos(json),
                    observacao: this.setObservacao(json),
                    footer: this.setFooter(json),
                    order: json,
                  },
                  resolve,
                );
                this.context.hideLoading();
              });
            }
          }
        });
    });
  };

  getEmpresaFaturamento = callback => {
    const { otica } = this.props;
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: otica ? otica : this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState(
          {
            empresa_faturamento: json.nome,
            empresa_faturamento_cnpj: json.cnpj,
          },
          callback,
        );
      });
  };

  setValueOrder(json) {
    var order = {};
    order.valorLiquido = "";

    return order;
  }

  setHeader(json) {
    return {
      pedido: json.id,
      laboratorio: this.props.laboratorio_nome,
      valor_pedido: json.orcamento?.valorLiquido || false,
    };
  }

  setDados(json) {
    return {
      numero_pedido: json.idPedidoOtica,
      nome_cliente: json.hasOwnProperty("paciente") && json.paciente ? json.paciente.nome : "",
      nome_medico: json.hasOwnProperty("medico") && json.medico ? json.medico.nome : "",
      cpf_cliente: json.hasOwnProperty("paciente") && json.paciente ? json.paciente.cpf : "",
      crm_medico: json.hasOwnProperty("medico") && json.medico ? json.medico.crm : "",
      empresa_faturamento: this.state.empresa_faturamento,
      promo_code: json.promocao && json.promocao.hasOwnProperty("voucher") ? json.promocao.voucher : "",
      dados_promo_nome: json.promocao && json.promocao.hasOwnProperty("campanha") ? json.promocao.campanha.nome : "",
    };
  }

  setProdutos(json) {
    var produtos, quantProdutos;

    if (json.hasOwnProperty("produtos")) {
      produtos = json.produtos;
      quantProdutos = json.produtos ? Object.keys(json.produtos).length : 0;
    }

    this.setState({ id: json.id });

    return {
      produtos: produtos,
      quantProdutos: quantProdutos,
    };
  }

  setObservacao(json) {
    var response = null;

    if (json.hasOwnProperty("observacao")) {
      response = {
        observacao: json.observacao,
      };
    }

    return response;
  }

  setFooter(json) {
    const { prazo, laboratorio_nome } = this.props;

    return {
      pedido: json.id,
      laboratorio: laboratorio_nome,
      prazo: prazo,
    };
  }

  render() {
    return (
      <>
        <ReactToPrint
          documentTitle={`Essilor Conecta - Pedido ${this.state.id}`}
          onBeforeGetContent={this.handleOnBeforeGetContent}
          trigger={this.reactToPrintTrigger}
          content={() => this.componentRef}
        />

        <div style={{ display: "none" }}>
          {this.state.order && (
            <div className="pdf-order container" ref={el => (this.componentRef = el)}>
              <PDFOrderHeader dados={this.state.header}></PDFOrderHeader>
              <PDFOrderDados dados={this.state.dados}></PDFOrderDados>
              <PDFOrderProdutos dados={this.state.produtos}></PDFOrderProdutos>
              <PDFOrderDadosAdicionais dados={this.state.observacao}></PDFOrderDadosAdicionais>
              <PDFOrderFooter footer={this.state.footer}></PDFOrderFooter>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default PDFOrderAtacado;
