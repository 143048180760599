import React, { Component } from 'react'
import '../../index/index.css'
import Footer from '../footer'

//Import images
import logobanner from '../../index/img/logo-banner-2021.png'

class Maintenance extends Component {
    render() {
        return (
            <div className="login" id="main-body">
                <main>
                    <div className="container">
                        <div className="row text-center mt-5">
                            <div className="col-12">
                                <img
                                    className="rounded mx-auto d-block"
                                    alt="logo Essilor"
                                    width="150"
                                    src={logobanner}
                                />
                            </div>
                            <div className="col-12 my-4">
                                <h1 className="display-3">Estamos em manutenção!</h1>
                                <h1 className="display-4 text-muted">Voltaremos em breve!</h1>
                            </div>
                        </div>
                    </div>
                    <div class="text-center p-5 my-5" style={{height: "200px", backgroundColor: "#edf4fa", color:"#00409a"}}>
                        <blockquote class="blockquote mb-0">
                            <p className="align-middle">Desculpe pelo transtorno, mas no momento estamos realizando algumas manutenções. Estaremos online em breve!!</p>
                            <footer class="blockquote-footer">
                                <small>
                                    Suporte <cite title="Source Title">Essilor</cite>
                                </small>
                            </footer>
                        </blockquote>
                    </div>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}

export default Maintenance;