import React, { Component } from 'react'
import pedidoslogo from '../img/pedidos-logo.jpg'
import guiaPedidos from '../img/pdf/Guide_Conecta_Pedidos.pdf';
import guiaTracking from '../img/pdf/Guide_Conecta_Tracking.pdf';

class Tutorial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            window_width: ''
        }
        this.openTutorial = this.openTutorial.bind(this);
        this.hideTutorial = this.hideTutorial.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }


    openTutorial(e) {
        this.setState({ [e.target.id]: e.target.value },
            () => {
                this.setState({ open: true });
            }
        );
    }

    hideTutorial(e) {
        this.setState({ [e.target.id]: e.target.value },
            () => {
                this.setState({ open: false });
            }
        );
    }

    updateWindowDimensions() {
        this.setState({
            window_width: (window.innerWidth) * 0.75,
            window_height: (window.innerWidth) * 0.38
        });
    }

    render() {
        return (
            <div className="x-web-ordering__intro">

                <img src="https://ec2.beta.essilorconecta.com.br/modulos/pedidos.png" className="guia-atacado-img" alt="Conecta Pedidos" />
 
                <div className="x-web-ordering__intro--container">
                    <p className="x-web-ordering__intro--subtitle">
                        Dúvidas? <a href="#" onClick={this.openTutorial} className="x-web-ordering__intro--link x-web-ordering__intro--subtitle-bold">Assista ao tutorial</a> ou baixe nosso <a href={guiaPedidos} target="blank" className="x-web-ordering__intro--link x-web-ordering__intro--subtitle-bold">Guia do Conecta Pedidos</a> e <a href={guiaTracking} target="blank" className="x-web-ordering__intro--link x-web-ordering__intro--subtitle-bold">Guia do Conecta Tracking</a>.
                    </p>
                </div>
                <div className={`${this.state.open === true ? 'show-tutorial' : 'hide-tutorial'}`}>
                    <div className='tutorial-close' onClick={this.hideTutorial}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='close-desk' width="17.089" height="17.119" viewBox="0 0 17.089 17.119">

                            <path className="a" d="M20.862,36.982a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434l6.072-6.072,6.072,6.072a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434,1.432,1.432,0,0,0,0-2.053l-6.072-6.072,6.072-6.072A1.452,1.452,0,0,0,35,20.731L28.929,26.8l-6.043-6.014a1.452,1.452,0,1,0-2.053,2.053L26.9,28.915l-6.043,6.043a1.4,1.4,0,0,0,0,2.024Z"
                                transform="translate(-20.399 -20.297)"
                                fill="#b5b5b5"
                            />
                        </svg>
                    </div>
                    <center>
                        <div>
                            <iframe src="https://www.loom.com/embed/3b3e02f12179438fb383ad8ce1aac6a4" frameborder="0" width={this.state.window_width} height={this.state.window_height} webkitallowfullscreen mozallowfullscreen allowfullscreen />
                        </div>
                    </center>
                </div>
            </div>
        );
    }
}

export default Tutorial;
