import React, { Component } from "react";

//Import CSS
import "./confirmed.css";

//Import internal components
import AppContext from "../../appContext";
import CustomButton from "../../components/buttons/button";
import OrderSummaryPDF from "./order-summary-pdf";
import IconConfirmed from "./confirmed-icon";

//Import external components
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import moment from "moment";
import PDFOrderAtacado from "../pdf-order/pdf-order";

//Import images

class ConfirmedAtacado extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectL: false,
      redirectWO: false,
      confirmed: false,
      pedido: "",
      valor: "",
      prazo: "",
      newOrder: "",
      jsonBody: {},
      pedidoOtica: "",
      empresa_faturamento: "",
      empresa_faturamento_cnpj: "",
      labName: "",
      produtos: "",
      orcamento: "",
    };

    ConfirmedAtacado.contextType = AppContext;

    if (localStorage.getItem("token") !== null) {
      var token = localStorage.getItem("token");

      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt = JSON.parse(jsonPayload);

      const role = jwt.modulos.find(element => element === "Atacado");

      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
    if (this.props.location.state === undefined && this.state.confirmed === false) {
      this.state = { redirectWO: true };
    }
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.setState({
        jsonBody: this.props.location.state.jsonBody,
        pedidoOtica: this.props.location.state.pedidoOtica,
        prazo: this.props.location.state.prazo,
        empresa_faturamento: this.props.location.state.empresa_faturamento,
        empresa_faturamento_cnpj: this.props.location.state.empresa_faturamento_cnpj,
        pedido: this.props.location.state.pedido,
        labName: this.props.location.state.labName,
        produtos: this.props.location.state.produtos,
        orcamento: this.props.location.state.orcamento,
        valor: this.props.location.state.valor,
        newOrder: this.props.location.state.newOrder,
      });
    }
  }

  newOrder = e => {
    e.preventDefault();
    this.setState({ redirectPromo: true });
  };

  render() {
    const { pedido, prazo, newOrder } = this.props.location.state;
    let idPedido = this.state.jsonBody?.id ? this.state.jsonBody.id : pedido;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    if (this.state.redirectL) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectWO) {
      return <Redirect to="/atacado-labs" />;
    }

    return (
      <div className="x-order-confirmed-atacado">
        <main className="x-order-confirmed__main" id="main-body">
          <IconConfirmed />

          <div className="x-order-confirmed__container1">
            <h1 className="x-order-confirmed__title">Seu pedido foi confirmado</h1>
            <p className="x-order-confirmed__paragraph">Parabéns! Você completou seu pedido.</p>
            <div className="x-order-confirmed__list">
              <p className="x-order-confirmed__item">
                O número do seu pedido no <br />
                laboratório <small className="x-order-confirmed__item--lab">{this.state?.labName || ""}</small> é{" "}
                <small className="x-order-confirmed__item--number">{idPedido}</small>
              </p>

              <p style={{ textAlign: "center" }} className="x-order-confirmed__item js--paragraph">
                A data de saída estimada é <br />
                <small className="x-order-confirmed__item--number">{moment(prazo).format("DD/MM/YYYY")}</small>
              </p>
            </div>
            {/* PDF ATACADO */}
            <PDFOrderAtacado
              label="Imprimir Pedido"
              btn_class="pdf-pedido__summary--print"
              icon={false}
              prazo={moment(prazo).format("DD/MM/YYYY")}
              laboratorio={this.props.location.state.empresa_faturamento_cnpj}
              otica={this.props.location.state.otica.cnpj}
              laboratorio_nome={this.props.location.state.labName}
              pedido_laboratotio={pedido}
              redeOticas={this.props.location.state.redeOticas}
              local={"confirmed-atacado"}
            />
            <div className="button-mobile-only">
              <CustomButton
                label="Fazer novo pedido"
                width="100%"
                icon_arrow_right
                orange_button
                onClick={() => {
                  window.location = newOrder;
                }}
              />
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default ConfirmedAtacado;
