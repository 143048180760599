import React from "react";

const RequestiaLink = () => {
	return (
		<strong>
			<a
				href="https://essilorluxottica.requestia.com/"
				target="_blank"
				rel="noopener noreferrer"
				style={{ color: "#007bff", textDecoration: "underline" }}>
				Requestia (https://essilorluxottica.requestia.com/)
			</a>
		</strong>
	);
};

export default RequestiaLink;