import React, { Component } from "react";

//Import CSS
import "./media-upload.css";

//Import internal components
import AppContext from "../appContext";
import MediaUploadScreen from "./media-upload-screen";

//Import external components
import { Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Alert from "sweetalert2";

//Import images

class MediaUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
      auth: "",
    };

    MediaUpload.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    this.context.showLoading("", <Spinner animation="border" variant="secondary" />);

    e.preventDefault();
    var email = this.state.email.toLowerCase();

    this.setState({
      submitted: true,
      disabled: true,
    });
    const { password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ usuario: email, senha: password }),
    };

    fetch(process.env.REACT_APP_LABS_API + "user_auth.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(jsonText => {
        if (jsonText.status === "erro") {
          if (jsonText.code === 422) {
            this.context.hideLoading();
            Swal.fire({
              html: "Usuário não possui conta! Redirecionando...",
              icon: "warning",
              title: "Aviso!",
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonColor: "#00518b",
              confirmButtonText: "Ok!",
            });
          } else {
            this.context.hideLoading();
            Alert.fire({
              text: "Erro na autenticação!",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });

            return;
          }
        } else {
          let json = jsonText;

          if (typeof jsonText === "string" || jsonText instanceof String) {
            json = JSON.parse(jsonText);
          }

          localStorage.setItem("isUsuarioSemCnpj", true);
          localStorage.setItem("token", json.access_token);
          localStorage.setItem("session_token", json.session_token);
          localStorage.setItem("grant_token", json.grant_token);
          this.setState({
            auth: true,
          });
          this.context.hideLoading();
        }
      });
  }

  render() {
    const { email, password } = this.state;

    return (
      <div className="media-upload" id="main-body">
        {!this.state.auth && (
          <div className="media-upload-login">
            <form onSubmit={this.handleSubmit}>
              <label className="" htmlFor="email-cpf">
                E-mail*
              </label>
              <br />
              <input
                className="media-upload-input-text"
                title="email@email.com.br"
                name="email"
                pattern="([a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,3}$)"
                id="email-cpf"
                placeholder="email@email.com.br"
                required={true}
                type="text"
                value={email}
                onChange={this.handleChange}
              />
              <br />

              <label className="" htmlFor="senha">
                Senha
              </label>
              <br />

              <input
                className="media-upload-input-text"
                name="password"
                id="password"
                placeholder="Senha"
                required={true}
                type="password"
                value={password}
                onChange={this.handleChange}
              />
              <br />

              <center>
                <Button variant="secondary" size="lg" type="submit" title="Entrar">
                  Entrar
                </Button>
              </center>
            </form>
          </div>
        )}

        {this.state.auth && (
          <div className="media-upload-content">
            <MediaUploadScreen />
          </div>
        )}
      </div>
    );
  }
}

export default MediaUpload;
