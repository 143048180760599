import React, { Component } from "react";
import "./roles-management-description.css";
import { Link } from "react-router-dom";
import AppContext from "../../appContext";

class RolesManagementDescription extends Component {
  constructor(props) {
    super(props);
    RolesManagementDescription.contextType = AppContext;
  }

  render() {
    const gestaoPapeis = () => {
      const papel = this.context.userSession.papel;
      const categoria = this.context.userSession.papelCategoria;

      if (categoria === "OTICA" || categoria === "MEDICO" || categoria === "LABORATORIO") {
        if (papel === "Consultor Laboratório") {
          return "Consulte clientes pelo CPF, E-mail ou CNPJ da empresa a qual estão vinculados.";
        }
        return (
          <>
            <span>Confira quais são os demais usuários do Essilor Conecta vinculados à sua empresa.</span>
            <br></br>
            <span>A gestão e manutenção dos usuários abaixo são de sua responsabilidade.</span>
          </>
        );
      } else if (categoria === "ESSILOR") {
        return "Consulte colaboradores pelo CPF, E-mail ou CNPJ da empresa a qual estão vinculados.";
      }
    };

    return (
      <div className="x-user-consultant__left">
        <h1 className="x-user-consultant__title">Gestão de Papéis</h1>
        <span className="x-user-consultant__shape"></span>
        <p className="x-user-consultant__paragraph">
          {gestaoPapeis()}
          {/* <Link to="/modulopapel" target="_blank">
            Clique aqui
          </Link>{" "}
          para ver a lista de perfis disponíveis no Conecta e a quais módulos eles têm acesso. */}
        </p>
      </div>
    );
  }
}

export default RolesManagementDescription;
