import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Alert from "sweetalert2";
import AppContext from "../../appContext";

class Lab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      link: this.props.link,
      lab: this.props.lab,
      logo: this.props.logo,
      name: this.props.labName,
      labLogo: false,
      IsCor_AR: this.props.IsCor_AR,
      IsGrowmocao: this.props.IsGrowmocao,
      IsPreferido: this.props.IsPreferido,
      colorirNaOtica : this.props.colorirNaOtica,
      IsPacto : this.props.IsPacto,
    };

    Lab.contextType = AppContext;
    this.checkHealth = this.checkHealth.bind(this);
  }

  componentDidMount() {
    var tester = new Image();
    tester.onload = this.imageFound;
    tester.onerror = this.imageNotFound;
    tester.src = this.props.logo;
  }

  imageFound = () => {
    this.setState({ labLogo: true });
  };

  imageNotFound = () => {
    this.setState({ labLogo: false });
  };

  checkHealth() {
    this.context.showLoading("Validando laboratório");
    const requestOptions = {
      method: "GET",
      headers: {
        laboratorio: this.state.lab,
      },
    };

    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/health", requestOptions)
      .then(response => {
        if (response.ok) {
          this.setState({ statusProduct: response.status }, () => {
            fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/health", requestOptions).then(response => {
              if (response.ok) {
                this.setState({ statusOrder: response.status }, async () => {
                  if (this.state.statusProduct === 200 && this.state.statusOrder === 200) {
                    this.setState({ redirect: true }, this.context.hideLoading());
                  }
                });
              } else {
                return Promise.reject(response);
              }
            });
          });
        } else {
          return Promise.reject(response);
        }
      })
      .catch(error => {
        this.context.hideLoading();
        Alert.fire({
          icon: "warning",
          html: "O serviço está temporariamente indisponível para este laboratório.<br /> O time do Essilor Conecta já está atuando para resolver o problema.",
          width: "auto",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      });
  }

  goToOrder = e => {
    e.preventDefault();
    this.checkHealth();
  };

  render() {
    if (this.state.redirect) {
      if (this.state.link.includes("http")) {
        window.location.href = this.state.link;
      } else {
        return (
          
          <Redirect
            to={{
              pathname: this.state.link,
              state: {
                lab: this.state.lab,
                logo: this.state.logo,
                name: this.state.name,
                telefone: this.props.telefone,
                site: this.props.site,
                email: this.props.email,
                promo: this.props.promo,
                corteRemoto: this.props.corteRemoto,
                detailOrder: this.props.detailOrder,
                promo_code: "",
                promo_proximo_par: null,
                IsCor_AR: this.state.IsCor_AR,
                IsGrowmocao: this.state.IsGrowmocao,
                CorteMontagemObrigatorio: this.props.CorteMontagemObrigatorio,
                colorirNaOtica: this.props.colorirNaOtica,
                IsPacto: this.props.IsPacto,
              },
            }}
          />
        );
      }
    }

    var labObject = null;

    if (this.state.labLogo) {
      labObject = <img className="labSelect" src={this.props.logo} alt="Escolha seu laboratório" />;
    } else {
      labObject = (
        <label className="" htmlFor="labSelect">
          {this.props.labName}
        </label>
      );
    }
    return (
      <div className="labSelect-container">
        <a className="" rel="noopener noreferrer" id="labSelect" onClick={this.goToOrder}>
          {labObject}
        </a>
      </div>
    );
  }
}

export default Lab;
