import React, { Component } from 'react'

//Import CSS
import './text-area.css'

//Import internal components

//Import external components
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

//Import images

class TextArea extends Component {
	render() {

		return (
			<div className="crm-input" style={{ width: this.props.width }}>

				<label className="crm-input-label" htmlFor={this.props.name}>
					{this.props.label}
				</label>

				<textarea
					type='textarea'
					autoComplete={this.props.autocomplete}
					defaultValue={this.props.value}
					name={this.props.name}
					id={this.props.id ? this.props.id : this.props.name}
					aria={this.props.aria}

					required={this.props.required}
					readOnly={this.props.readOnly}
					disabled={this.props.disabled}

					onkeypress={this.props.onkeypress}
					onblur={this.props.onblur}
					onChange={this.props.onChange}
					onClick={this.props.onClick}
					onFocus={this.props.onFocus}
				/>


				{this.props.tooltip && <OverlayTrigger
					placement="right"
					delay={{ show: 250, hide: 400 }}
					overlay={<Tooltip id="button-tooltip">{this.props.tooltip}</Tooltip>}
				>
					<span className="tooltip-shape">
					</span>
				</OverlayTrigger>}
			</div>
		)
	}
}

export default TextArea
