import React, { Component } from 'react'

//Import CSS
import './button-rightleft.css'

//Import internal components

//Import external components

//Import images

class ButtonRL extends Component {
	render() {

		return (
			<div className="buttonrl-radio--container">

				<input
					name={this.props.name}
					id={this.props.id_both}
					value="ambos"
					onClick={this.props.onClick}
					defaultChecked={this.props.defaultChecked_both}
					className="buttonrl-radio--input"
					type="radio"
					data-tooltip
					aria-label="Clique na opção dos olhos"
					disabled={this.props.disabled_both}
				/>
				<label
					name={this.props.id_both}
					htmlFor={this.props.id_both}
					id={this.props.id_both}
					className="buttonrl-radio--label buttonrl-radio--left js--selectAmbos"
				>
					Ambos os olhos
				</label>

				<input
					name={this.props.name}
					id={this.props.id_right}
					value="direito"
					onClick={this.props.onClick}
					defaultChecked={this.props.defaultChecked_right}
					className="buttonrl-radio--input"
					type="radio"
					disabled={this.props.disabled_right}
				/>
				<label
					name={this.props.id_right}
					htmlFor={this.props.id_right}
					id={this.props.id_right}
					className="buttonrl-radio--label js--selectDireito"
				>
					Olho direito
				</label>

				<input
					name={this.props.name}
					id={this.props.id_left}
					value="esquerdo"
					onClick={this.props.onClick}
					defaultChecked={this.props.defaultChecked_left}
					className="buttonrl-radio--input"
					type="radio"
					disabled={this.props.disabled_left}
				/>
				<label
					name={this.props.id_left}
					htmlFor={this.props.id_left}
					id={this.props.id_left}
					className="buttonrl-radio--label buttonrl-radio--right js--selectEsquerdo"
				>
					Olho esquerdo
				</label>
			</div>
		)
	}
}

export default ButtonRL
