export const allowedTypes = [
	"REDE",
	"OTICA"
];

export const disallowedTypes = [
	"BALCONISTA",
	"FORNECEDORES",
	"CONSUMIDORES",
	"DISTRIBUIDOR"
];

export const accountTypeMap = {
	multiAccount: [ "REDE", "BALCONISTA", "OTICA", "FORNECEDOR", "CONSUMIDORES", "DISTRIBUIDOR" ],
	affiliates: [ "AFILIADOS", "EMPREGADOS" ],
	laboratory: [ "LAB PARTICIPACAO" ],
	independentLab: [ "LABORATORIOS", "LAB ALLIANCE" ]
};