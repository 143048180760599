import React, { Component } from 'react'

//Import CSS
import '../../main/main.css'

//Import internal components

//Import external components
import { Redirect } from 'react-router'
import Swal from 'sweetalert2'

//Import images

class Back extends Component {
    constructor(props) {
        super(props);

        this.state ={
            link: '/',
            redirect: false
        }
    }

    componentDidMount(){
        if(localStorage.getItem("token") !== null){
            this.setState({link: "/home"});
        }
    }

    handleClick = () => {
        Swal.fire({
            title: 'Aviso',
            text: "Tem certeza que deseja voltar para a página inicial do Essilor Conecta? Todos os dados do seu pedido serão perdidos.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            cancelButtonText: "Cancelar"
          }).then((result) => {
            if (result.value) {
              this.setState({ redirect: true });
            }
          })
    }

  	render(){

        if(this.state.redirect){
            return <Redirect to={this.state.link} />
        }

    	return (
            <div className="x-my-account__back">
                    <a href="#" className="x-follow-order__back" onClick={this.handleClick} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.31" height="14.994" viewBox="0 0 21.31 14.994">
                            <path id="np_arrow_2410576_000000"
                                d="M8,21.137l6.709-5.525a.807.807,0,0,0,.1-1.116.824.824,0,0,0-1.116-.1L8.287,18.843V.789a.789.789,0,0,0-1.579,0V18.843L1.294,14.392a.827.827,0,0,0-1.116.1.8.8,0,0,0,.1,1.116l6.709,5.525a.825.825,0,0,0,1.011,0Z"
                                transform="translate(21.31) rotate(90)" fill="#424b4a" />
                        </svg>
                        <span className="x-follow-order__back-text">Voltar ao Meu Essilor Conecta</span>
                    </a>
            </div>
    	);
    }
}

export default Back;