import React, { Component } from 'react'
import './index.css'
import Top from './index-top'
import Content from './index-content'
import Carousel from './carousel'
import Footer from '../main/footer'


class Index extends Component {
  	render(){
    	return (
      	<div className="login" id="main-body">
            <main className='container login-main'>
                <Top></Top>
      		    <Content></Content>
				<Carousel></Carousel>
            </main>
			<Footer></Footer>
      	</div>
    	);
  	}
}

export default Index;