import React, { Component } from "react";
import "./media-center.css";
import Back from "../main/back";
import AppContext from "../appContext";
import MediaCenterForm from "./form/media-center-form";
import MediaCenterNoFile from "./no-file/media-center-no-file";
import MediaCenterCard from "./card/media-center-card";
import MediaCenterBanner from "./banner/media-center-banner";
import ReactPaginate from "react-paginate";
import UploadLogoOtica from "../main/upload-logo-otica/upload-logo-otica";
import Swal from "sweetalert2";
import popup from "../media-center/images/popup.jpg";
class MediaCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      midias: [],
      midiasAux: [],
      marca: "",
      tipo_arquivo: "",
      categoria: "",
      publico: "",
      no_media: false,
      offset: 0,
      data: [],
      perPage: 12,
      currentPage: 0,
      banner: [],
      load: true,
      armacao: false,
      alliance: "false",
    };

    MediaCenter.contextType = AppContext;
    this.handleChangeMarca = this.handleChangeMarca.bind(this);
    this.handleChangeTipoArquivo = this.handleChangeTipoArquivo.bind(this);
    this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
    this.handleChangePublico = this.handleChangePublico.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleChangeBanner = this.handleChangeBanner.bind(this);
    this.getBanner = this.getBanner.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.filtrarMidias = this.filtrarMidias.bind(this);
    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
  }

  componentDidMount() {
    this.context.showLoading();
    this.getBanner();
    var cliente = this.context.userSession.conta;

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        access_token: localStorage.getItem("token"),
        idcliente: cliente,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "sensidia/gestao_clientes/get_clientes_referencial.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json?.segmentacao_rede_otica === "CHILLI BEANS" || json?.nome_fantasia === "CHILLI BEANS") {
          this.setState({ armacao: true });
        }

        if (json.tipo_cliente === "LAB ALLIANCE" || json.tipo_cliente ==="LABORATORIOS") {
          this.setState({ alliance: "true" });
        }
        this.getMidias(this.state.marca, this.state.tipo_arquivo, this.state.categoria, this.state.publico);

      });

  }

  handleChangeMarca(e) {
    this.setState(
      {
        marca: e.target.value,
        offset: "",
      },
      this.filtrarMidias,
    );
  }

  handleChangeTipoArquivo(e) {
    this.setState(
      {
        tipo_arquivo: e.target.value,
        offset: "",
      },
      this.filtrarMidias,
    );
  }

  handleChangeCategoria(e) {
    this.setState(
      {
        categoria: e.target.value,
        offset: "",
      },
      this.filtrarMidias,
    );
  }

  handleChangePublico(e) {
    this.setState(
      {
        publico: e.target.value,
        offset: "",
      },
      this.filtrarMidias,
    );
  }

  handleChangeNome() {
    this.setState(
      {
        nome: document.getElementById("search-media").value,
        offset: "",
      },
      this.filtrarMidias,
    );
  }

  filtrarMidias() {
    this.context.showLoading();

    var filtroMidias = this.state.midiasAux.filter(item => {
      return (
        item.nome.toLowerCase().includes(this.state.nome.toLowerCase()) &&
        ((item.marca !== null && item.marca.includes(this.state.marca)) || (!item.marca && !this.state.marca)) &&
        ((item.tipo_arquivo !== null && item.tipo_arquivo.includes(this.state.tipo_arquivo)) || (!item.tipo_arquivo && !this.state.tipo_arquivo)) &&
        ((item.categoria !== null && item.categoria.includes(this.state.categoria)) || (!item.categoria && !this.state.categoria)) &&
        ((item.publico !== null && item.publico.includes(this.state.publico)) || (!item.publico && !this.state.publico))
      );
    });

    if (filtroMidias.length === 0) {
      this.setState({ midias: [], no_media: true }, this.context.hideLoading());
    } else {
      const slice = filtroMidias.slice(this.state.offset, this.state.offset + this.state.perPage);

      this.setState(
        {
          midias: slice,
          pageCount: Math.ceil(filtroMidias.length / this.state.perPage),
          no_media: false,
        },
        this.context.hideLoading(),
      );
    }
  }

  limparFiltros() {
    this.setState({ nome: "", marca: "", tipo_arquivo: "", categoria: "", publico: "", offset: "" }, this.filtrarMidias);
  }

  getMidias(s_marca, s_tipo_arquivo, s_categoria, s_publico) {
    this.context.showLoading();

    let midias_array = [];
    var especialista = this.context.especialista && this.context.especialista.includes("VARILUX") ? true : false;
    
    
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        papel: this.context.userSession.papel,
        especialista: especialista,
        alliance: this.state.alliance,
        marca: s_marca,
        tipo_arquivo: s_tipo_arquivo,
        categoria: s_categoria,
        publico: s_publico,
      }),
    };

    // fetch('http://localhost/essilor/get_midias.php', requestOptions)
    fetch(process.env.REACT_APP_LABS_API + "OEC/midia/get_midias.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status == "200") {
          for (var i = 0; i < json.items.length; i++) {
            var pushObj = {
              midia_id: json.items[i].Id,
              record_name: json.items[i].RecordName,
              nome: json.items[i].Nome_c,
              tipo: json.items[i].TipoMidia_c,
              url_midia: json.items[i].url_midia_c,
              url_miniatura: json.items[i].URLMiniatura_c,
              url_preview: json.items[i].URLImagemPreview_c,
              previewList: json.items[i].previewList,
              customizavel: json.items[i].customizavel_c,
              texto_sugerido: json.items[i].TextoSugerido_c,
              descricao: json.items[i].Descricao_c,
              marca: json.items[i].Marca_c,
              tipo_arquivo: json.items[i].TipoArquivo_c,
              categoria: json.items[i].Categoria_c,
              publico: json.items[i].Publico_c,
            };

            midias_array.push(pushObj);
          }
          if (this.state.armacao) {
            const resultadoFiltrado = midias_array.filter(item => {
              if (item.categoria) {
                const categorias = item.categoria.split(",");
                return !categorias.includes("ARMACAO");
              }
              return true;
            });
            midias_array = resultadoFiltrado;
          }

          const slice = midias_array.slice(this.state.offset, this.state.offset + this.state.perPage);
          this.setState({
            midias: slice,
            midiasAux: midias_array,
            pageCount: Math.ceil(midias_array.length / this.state.perPage),
          });
        }
        if (midias_array.length === 0) {
          this.setState({ no_media: true });
        } else {
          this.setState({ no_media: false });
        }
        this.context.hideLoading();

        /*Swal.fire({
          imageUrl: popup.toString(),
          // imageHeight: 600,
          imageAlt: "",
        });*/
      });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.filtrarMidias();
      },
    );

    window.scrollTo(0, 0);
  };

  handleChangeBanner() {
    this.setState(
      {
        nome: "",
        marca: this.state.banner[0].marca !== null ? this.state.banner[0].marca : "",
        tipo_arquivo: this.state.banner[0].tipo_arquivo !== null ? this.state.banner[0].tipo_arquivo : "",
        categoria: this.state.banner[0].categoria !== null ? this.state.banner[0].categoria : "",
        publico: this.state.banner[0].publico !== null ? this.state.banner[0].publico : "",
        offset: "",
      },
      this.filtrarMidias,
    );
    // if (this.state.banner[0].marca != null){
    //   document.getElementById('download_search_brand').value= this.state.banner[0].marca
    // }
    // if (this.state.banner[0].tipo_arquivo != null){
    //   document.getElementById('download_search_file').value= this.state.banner[0].tipo_arquivo
    // }
    // if (this.state.banner[0].categoria != null){
    //   document.getElementById('download_search_category').value= this.state.banner[0].categoria
    // }
    // if (this.state.banner[0].publico != null){
    //   document.getElementById('download_search_public').value= this.state.banner[0].publico
    // }

    document.getElementById("search-media").value = "";

    document.getElementById("download_search_brand").value = this.state.banner[0].marca !== null ? this.state.banner[0].marca : "";

    document.getElementById("download_search_file").value = this.state.banner[0].tipo_arquivo !== null ? this.state.banner[0].tipo_arquivo : "";

    document.getElementById("download_search_category").value = this.state.banner[0].categoria !== null ? this.state.banner[0].categoria : "";

    document.getElementById("download_search_public").value = this.state.banner[0].publico !== null ? this.state.banner[0].publico : "";
    window.scrollTo(0, 0);
  }

  getBanner() {
    let banner_array = [];

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        papel: this.context.userSession.papel,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_media_banner.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status == "200") {
          for (var i = 0; i < json.items.length; i++) {
            var pushObj = {
              url_banner_desktop: json.items[i].URLBannerDesktop_c,
              url_banner_mobile: json.items[i].URLBannerMobile_c,
              marca: json.items[i].Marca_c,
              categoria: json.items[i].Categoria_c,
              tipo_arquivo: json.items[i].TipoArquivo_c,
              publico: json.items[i].Public_c,
            };
            banner_array.push(pushObj);
          }
          this.setState({
            banner: banner_array,
          });
        }
      });
  }

  onChangeSearch() {
    var search_for = document.getElementById("search-media").value;

    this.context.showLoading();

    let midias_array = [];
    var especialista = this.context.especialista && this.context.especialista.includes("VARILUX") ? true : false;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        papel: this.context.userSession.papel,
        especialista: especialista,
        alliance:this.state.alliance,
        name: search_for,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "OEC/midia/get_midias_search_by_name.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status == "200") {
          for (var i = 0; i < json.items.length; i++) {
            var pushObj = {
              midia_id: json.items[i].Id,
              record_name: json.items[i].RecordName,
              nome: json.items[i].Nome_c,
              tipo: json.items[i].TipoMidia_c,
              url_midia: json.items[i].url_midia_c,
              url_miniatura: json.items[i].URLMiniatura_c,
              url_preview: json.items[i].URLImagemPreview_c,
              customizavel: json.items[i].customizavel_c,
              texto_sugerido: json.items[i].TextoSugerido_c,
              descricao: json.items[i].Descricao_c,
            };
            midias_array.push(pushObj);
          }

          if (this.state.armacao) {
            const resultadoFiltrado = midias_array.filter(item => {
              if (item.categoria) {
                const categorias = item.categoria.split(",");
                return !categorias.includes("ARMACAO");
              }
              return true;
            });
            midias_array = resultadoFiltrado;
          }

          const slice = midias_array.slice(this.state.offset, this.state.offset + this.state.perPage);
          this.setState({
            midias: slice,
            pageCount: Math.ceil(midias_array.length / this.state.perPage),
          });
        }
        if (midias_array.length === 0) {
          this.setState({ no_media: true });
        } else {
          this.setState({ no_media: false });
        }
        this.context.hideLoading();
      });
  }

  render() {
    const { midias, banner } = this.state;
    return (
      <div id="main-body" className="container">
        <div className="mt-5">
          <Back />
        </div>
        <div className="title-container">
          <h1 className="main-title">Marketing Center</h1>
          <span className="title-shape"></span>
        </div>
        <div className="row mb-5">
          <div className="col-xs-12 col-lg-8">
            <div className="subtitle-container">
              <p className="subtitle">
                Agora você pode fazer download ou compartilhar as campanhas, promoções e produtos Essilor direto aqui do Marketing Center. E mais: você ainda
                pode incluir sua logo e customizar algumas postagens!
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-lg-4 oticaMedia mb-5 pb-5">
            <div className="main-otica">
              <p class="upload-content-paragraph">Personalize conteúdos para redes sociais adicionando a logo da sua loja!</p>
              <UploadLogoOtica />
            </div>
          </div>

          {/* <div className="col-12 col-md-3">
				<p className="pl-4 pr-4">
				Como fazer suas campanhas digitais.{' '}
				<strong>
					<a href="https://camara.fgv.br/sites/camara.fgv.br/files/artigos/teste33_0_3.pdf"
					target="_blank"
					download="ebook">
					Baixe o e-book!
					</a>
				</strong>
				</p>
			</div> */}
        </div>
        {/* <div className="search-media">
					<SearchMedia
						label="Busque pelo nome:"
						name="search-media"
					/>
			</div> */}
        <MediaCenterForm
          marca={this.props.marca}
          handleChangeMarca={this.handleChangeMarca}
          tipo_arquivo={this.props.tipo_arquivo}
          handleChangeTipoArquivo={this.handleChangeTipoArquivo}
          categoria={this.props.categoria}
          handleChangeCategoria={this.handleChangeCategoria}
          publico={this.props.publico}
          handleChangePublico={this.handleChangePublico}
          value={this.props.value}
          handleChangeNome={this.handleChangeNome}
          filtrarMidias={this.filtrarMidias}
          limparFiltros={this.limparFiltros}
        />
        <div className="row">
          {midias.map((item, index) => {
            return (
              <MediaCenterCard
                key={index}
                midia_id={item.midia_id}
                record_name={item.record_name}
                nome={item.nome}
                tipo={item.tipo}
                url_miniatura={item.url_miniatura}
                url_preview={item.url_preview}
                previewList={item.previewList}
                url_midia={item.url_midia}
                customizavel={item.customizavel}
                texto_sugerido={item.texto_sugerido}
                descricao={item.descricao}
              />
            );
          })}
        </div>

        {this.state.no_media && <MediaCenterNoFile />}

        {!this.state.no_media && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"Anterior"}
              nextLabel={"Próximo"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}

        {banner.map((item, index) => {
          return (
            <MediaCenterBanner
              key={index}
              handleChangeBanner={this.handleChangeBanner}
              url_banner_desktop={item.url_banner_desktop}
              url_banner_mobile={item.url_banner_mobile}
            />
          );
        })}
      </div>
    );
  }
}

export default MediaCenter;
