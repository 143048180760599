import React, { Component } from "react";

import "./links-uteis.css";
import AppContext from "../../appContext";

class LinksUteis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };

    LinksUteis.contextType = AppContext;
  }

  componentDidMount() {
    this.context.showLoading("Carregando...");

    const requestOptions = {
      method: "GET",
    };

    fetch(process.env.REACT_APP_LABS_API + "get_useful_links.php", requestOptions)
      .then(response => {
        this.context.hideLoading();
        return response.json();
      })
      .then(data => {
        if (data.status === "sucesso") {
          if (data.items) {
            this.setState({ links: data.items });
          }
        }
      })
      .catch(error => {
        console.error("Erro ao buscar os dados:", error);
      });
  }

  render() {
    const { links } = this.state;

    return (
      <div className="links-uteis_container">
        <div className="links-uteis-main">
          <div className="title-container">
            <h1 className="main-title">Links Úteis</h1>
            <span className="title-shape"></span>
          </div>
          <div className="links">
            <ul>
              {links.map((link, index) => (
                <li key={index}>
                  <a href={link.url} target=" " rel=" ">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default LinksUteis;
