import React, { Component } from "react";
import Module from "./module";
import AppContext from "../appContext";

class CarouselEssilor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modulos: [],
      especialista: "",
      load: true,
    };

    CarouselEssilor.contextType = AppContext;
  }

  componentDidMount() {}

  render() {
    return (
      <div className="x-my-essilor">
        <h3 className="x-my-essilor__title">{this.props.categoria}</h3>
        <ul className="x-my-essilor__list">
          {this.props.modulos.map((item, index) => {
            return (
              <Module
                key={index}
                nome={item.nome}
                logo={item.logo}
                url={item.sso ? item.url + "?grant_token=" + localStorage.getItem("grant_token") : item.url}
                urlPadrao={item.sso ? item.url : ""}
                message={item.message}
                mensagemNovidade={item.mensagemNovidade}
                dataLimiteNovidade={item.dataLimiteNovidade}
                target={item.tab === true ? "_blank" : ""}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

export default CarouselEssilor;
