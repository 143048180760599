async function getLabs(parametros) {

    if(parametros.papelCategoria !== "ESSILOR"){
    return parametros.conta;
  }

  var laboratorios = [];

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      cnpjs: parametros.laboratorio,
      papel: parametros.papel,
      conta: parametros.conta,
    }),
  };

  return fetch(process.env.REACT_APP_LABS_API + "get_labs.php", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.items) {
        if (parametros.tracking) {
          laboratorios = ValidTracking(json);
        } else {
          laboratorios = ValidBasic(json);
        }
        if (parametros.removeItens) {
          laboratorios = RemoveItens(laboratorios, parametros.removeItens);
        }
      }

      return laboratorios;
    });
  
  
  
}

function ValidBasic(json) {
  let labsa = [];
  if (json.items) {
    for (var i = 0; i < json.items.length; i++) {
      var pushObj = {
        label: json.items[i].Nome_c,
        value: json.items[i].CNPJ_c,
      };
      labsa.push(pushObj);
    }
  }
  return labsa;
}

function ValidTracking(json) {
  let labsa = [];
  if (json.items) {
    for (var i = 0; i < json.items.length; i++) {
      if (json.items[i].Tracking_c === true) {
        var pushObj = {
          label: json.items[i].Nome_c,
          value: json.items[i].CNPJ_c,
          detailOrder: json.items[i].DetalhesDoPedido_c,
          phone: json.items[i].Telefone_c,
          logo: json.items[i].URL_Logo_c,
          email: json.items[i].Email_c,
          site: json.items[i].Site_c,
        };
        labsa.push(pushObj);
      }
    }
  }
  return labsa;
}

function RemoveItens(laboratorios, itens) {
  return laboratorios.filter(lab => !itens.includes(lab.label));
}

export { getLabs };
