import React, { Component } from 'react'

//Import CSS

//Import internal components
import AppContext from '../appContext'

//Import external components

//Import images

class ProfileMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nome_conta: '',
            papel_usuario: ''
        }

		ProfileMessage.contextType = AppContext
    }

    componentDidMount(){

        this.setState({ papel_usuario: this.context.userSession.papel});

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'cnpj': this.context.userSession.conta }),
        }

		fetch(process.env.REACT_APP_LABS_API + 'get_account_data.php', requestOptions)

        .then(response => {
            return response.json();
        }
        ).then(json => {
            this.setState({ nome_conta: json.nome })
        })
    }


  	render(){
        const { nome_conta, papel_usuario } = this.state

        const papeis_sem_a = ["Usuário não associado a Ótica", "Usuário não associado a CNPJ"]

    	return (
            <div className="my-account__info">

				<div className='conecta-h1-container'>
					<h1 className='conecta-h1-with-dot'>Editar Meu Perfil</h1>
				</div>

				<p className="my-account__paragraph">
                    Você está conectado {papeis_sem_a.includes(papel_usuario)?"":"à "}<b>{nome_conta}</b> como <b>{papel_usuario}</b>.
					Se precisar alterar seu papel, solicite ao seu consultor Essilor.
                </p>

				<div className="my-account__menu-anchor">
					<a href="#my-account__profile">Perfil</a>
					<a href="#my-account__endereco">Endereço</a>
					<a href="#my-account__empresas">Empresas Vinculadas</a>
					<a href="#my-account__dados">Dados de Acesso</a>
				</div>

            </div>
    	)
  	}
}

export default ProfileMessage