async function get_users_data(parametros) {
  var folders = [];

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({
      email: parametros.email,
      motivo: parametros.motivo,
    }),
  };
  try {
    return fetch(process.env.REACT_APP_LABS_API + "get_users_data.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        folders = json.items[0].FinancialCenterFolders_c.split(", ");
        return folders;
      })
      .catch(error => {
        console.error("Erro ao buscar os dados: ", error);
      });
  } catch (error) {
    console.error("Erro ao buscar os dados: ", error);
  }
}

export { get_users_data };
