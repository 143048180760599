import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import "./roles-management-table.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircleFill } from "react-bootstrap-icons";
import AppContext from "../../appContext";
import { XCircleFill, PersonDashFill } from "react-bootstrap-icons";

class RolesManagementTableBodyTr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listaPapeis: [],
    };

    RolesManagementTableBodyTr.contextType = AppContext;
  }

  removerAcessoUsuario = () => {
    const { removerUsuario, id, cnpj } = this.props;

    removerUsuario(id, cnpj);
  };

  alterarUsuario = e => {
    const { name, value, selectedIndex } = e.target;
    const { alterarPerfilUsuario, id, cnpj } = this.props;

    alterarPerfilUsuario(id, value, e.target.options[selectedIndex].text, cnpj);
  };

  inativarUsuario = () => {
    const { id, email, inativarUsuario } = this.props;

    inativarUsuario(id, email);
  };

  render() {
    const { id, nome, email, perfil, listaPapeis, cnpj, cpf, isInativar, inativo, userLogado, erro } = this.props;

    var desvinculado = listaPapeis.some(elem => {
      return elem.id === perfil;
    });
    var perfilAux = perfil;
    var desvinculadoInativo = inativo;
    perfilAux = desvinculado ? perfil : "default";

    if (!desvinculado && !cnpj) {
      perfilAux = "default";
      desvinculadoInativo = !desvinculado;
    }

    var cpfMask = cpf ? cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : "";
    var cnpjMask = cnpj ? cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : "";
    return (
      <tr id={id}>
        <td className="roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>{nome}</td>
        <td className="roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>{email}</td>
        <td className="roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>{cpfMask}</td>
        <td className="roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>{cnpjMask}</td>
        <td className="roles-management-table-body-tr" >
          <div className="roles-box">
            <span className="roles-select-arrow"></span>
            <select
              title="Clique nesse campo"
              name="perfil"
              id="perfil"
              value={perfilAux}
              onChange={this.alterarUsuario}
              className="roles-select"
              disabled={inativo}>
              <option key="0" value="default" disabled selected className="roles-select-option">
                Selecione
              </option>
              {listaPapeis.map((item, index) => (
                <option key={index} value={item.id} className="roles-select-option" disabled={item.disabled}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
        </td>
        {(inativo || userLogado) ? (
          <>
            <td colSpan={2} class="text-center align-middle roles-management-table-body-tr" style={{ width: "35%", color: "gray" }}>
              <strong>{erro}</strong>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Usuário não pode ser editado.</Tooltip>}>
                <span color="red" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                  <QuestionCircleFill />
                </span>
              </OverlayTrigger>
            </td>
          </>
        ) : (
          <>
            <td class="text-start align-middle roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>
              <Button variant="link" className="btn-excluir ps-5" onClick={this.removerAcessoUsuario} disabled={desvinculadoInativo}>
                <div className="row">
                  <div className="col-4">
                    <XCircleFill color="#0394db" size={25} />
                  </div>
                  <div className="col-8 justify-content-center align-self-center">
                    <p  style={{fontSize: "0.8rem" }}>Desvincular</p>
                  </div>
                </div>
              </Button>
            </td>
            {isInativar && (
              <td class="text-start align-middle roles-management-table-body-tr" style={{fontSize: "0.8rem" }}>
                <Button variant="link" onClick={this.inativarUsuario} className="btn-excluir" disabled={inativo}>
                  <div className="row">
                    <div className="col-4">
                      <PersonDashFill color="red" size={25} />
                    </div>
                    <div className="col-8 justify-content-center align-self-center">
                      <p  style={{fontSize: "0.8rem" }}>Inativar</p>
                    </div>
                  </div>
                </Button>
              </td>
            )}
          </>
        )}
      </tr>
    );
  }
}

export default RolesManagementTableBodyTr;
