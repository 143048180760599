import React, { Component } from "react";

//Import CSS
import "bootstrap/dist/css/bootstrap.min.css";

//Import internal components

//Import external components
import Card from "react-bootstrap/Card";

//Import images

class OrderSummaryLens extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { order } = this.props;

    return (
      <Card.Body>
        <h3 className="x-web-ordering-pedido__summary--text">Produto</h3>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Tipo de produção</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_tp_producao_value || "-"}</li>
        </ul>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Tipo de produto</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_tp_produto_value || "-"}</li>
        </ul>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Produto escolhido</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_esc_produto_nome || "-"}</li>
        </ul>

        <h3 className="x-web-ordering-pedido__summary--text">Serviços Adicionais</h3>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">AR</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_servicos_value || "-"}</li>
        </ul>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Coloração</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_coloracao_value || "-"}</li>
        </ul>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Cor</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.cor_value || "-"}</li>
        </ul>

        <ul className="x-web-ordering-pedido__summary--card-text">
          <li className="x-web-ordering-pedido__summary--subitem-text title">Corte/Montagem</li>
          <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.lente_corte_value || "-"}</li>
        </ul>
        {order.pacto.useVoucher.voucherPacto_ok && (
          <>
            <ul className="x-web-ordering-pedido__summary--card-text">
              <li className="x-web-ordering-pedido__summary--subitem-text title">Voucher Pacto</li>
              <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.pacto.data[0].descPilar}</li>
            </ul>
            <ul className="x-web-ordering-pedido__summary--card-text">
              <li className="x-web-ordering-pedido__summary--subitem-text title">Codigo do Voucher Pacto</li>
              <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.pacto.data[0].listaVouchers[0].codigoVoucher}</li>
            </ul>
          </>
        )}
      </Card.Body>
    );
  }
}

export default OrderSummaryLens;
