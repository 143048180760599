import React, { Component } from "react";
import AppContext from "../../appContext";
import Swal from "sweetalert2";

class TableParLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status_par: this.props.par.status,
    };

    TableParLine.contextType = AppContext;

    this.updatePar = this.updatePar.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  updatePar(newStatus) {
    this.context.showLoading("Carregando...");

    const requestOptions = {
      method: "PATCH",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: this.props.lab,
      },
      body: JSON.stringify({
        idPar: this.props.par.idPar,
        statusPar: newStatus,
      }),
    };

    const voucherId = this.props.voucherId;

    fetch(process.env.REACT_APP_ESSILOR_API + `gestao-pedidos-laboratorio/v1/vouchers/${voucherId}`, requestOptions)
      .then(response => {
        this.context.hideLoading();

        if (response.status === 204) {
			return {};
		} else {
			return response.json();
		}
      })
      .then(json => {
        if (json[0] && json[0]?.codigo > 300) {
          Swal.fire({
            text: json[0]?.mensagem,
            icon: "error",
            title: "Ocorreu um erro na solicitação",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          this.setState({
            status_par: newStatus,
          });
        }
      });
  }

  onChange(event) {
    const newStatus = event.currentTarget.value;

    Swal.fire({
	  html: `Tem certeza que deseja alterar o status do <b>${this.props.par.idPar}º par</b> do cupom <b>${this.props.voucherId}</b> para <b>${this.status.find(s => s.value === newStatus).label}</b>?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
	  cancelButtonColor: "red",
      customClass: {
        confirmButton: "x-follow-order__filter-btn",
      },
    }).then(result => {
      if (result.value === true) {
        this.updatePar(newStatus);
      } else {
		this.setState({
			status_par: this.state.status_par,
		});
	  }
    });
  }

  status = [
    { value: "ORDER_USED", label: "USADO" },
    { value: "ORDER_UNUSED", label: "NÃO USADO" },
  ];

  render() {
    return (
      <tr>
        <td>{this.props.par.idPar}</td>
        <td>{this.props.par.produto.rpl}</td>
        <td>{`${this.props.par.servicos[0]?.codigoRef || this.props.par.produto.servicoAssociado} - ${this.props.par.servicos[0]?.tipo || 'ANTIRREFLEXO'}`}</td>
        <td>
          {/* <Select
		  	className="status_par"
            width="100%"
            id="status_par"
            itens={this.state.status}
            defaultValue={this.state.status.filter(status => status.value === this.props.par.status)[0].value}
          /> */}
          <div className="par-select">
            <select
              className="par-select-input"
              name="par-select"
              id="par-select"
                value={this.status.filter(status => status.value === this.state.status_par)[0].value}
            //   defaultValue={this.status.filter(status => status.value === this.state.status_par)[0].value}
              onChange={this.onChange}>
              {this.status.map(status => {
                return (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                );
              })}
            </select>
            <span className="par-select-arrow"></span>
          </div>
        </td>
      </tr>
    );
  }
}

export default TableParLine;
