import React from 'react'
import AppContext from '../../appContext'

class MergeImageLogo extends React.Component {
  constructor(props) {
    super(props)
    this.state = { preview: '', logo_otica: '', imagem: '' }

    MergeImageLogo.contextType = AppContext
  }

  componentDidMount() {
    const {
      imagem,
      widthLogo,
      heigthLogo,
      setBase64DownloadImage,
      customizavel
    } = this.props

    this.converterImagem(imagem);

    const canvas = this.refs.canvas
    const context = canvas.getContext('2d')
    const imagemRef = this.refs.imagem
    const logo = this.refs.logo

    var maxWidth = widthLogo // Max width for the image
    var maxHeight = heigthLogo // Max height for the image
    var ratio = 0 // Used for aspect ratio

    imagemRef.onload = () => {
      canvas.width = imagemRef.width
      canvas.height = imagemRef.height
      context.drawImage(imagemRef, 0, 0, imagemRef.width, imagemRef.height)
      this.obterLogo(this.context.userSession.conta)
    }

    logo.onload = () => {
      if (customizavel) {
        var width = logo.width // Current image width
        var height = logo.height // Current image height

        // Check if the current width is larger than the max
        if (width > maxWidth) {
          ratio = maxWidth / width // get ratio for scaling image
          logo.width = maxWidth // Set new width
          logo.height = height * ratio // Scale height based on ratio
          height = height * ratio // Reset height to match scaled image
          width = width * ratio // Reset width to match scaled image
        }

        // Check if current height is larger than max
        if (height > maxHeight) {
          ratio = maxHeight / height // get ratio for scaling image
          logo.height = maxHeight // Set new height
          logo.width = width * ratio // Scale width based on ratio
          width = width * ratio // Reset width to match scaled image
          height = height * ratio // Reset height to match scaled image
        }

        context.beginPath()
        context.rect(
          canvas.width - (width + 20),
          canvas.height - (height + 20),
          width - 5,
          height - 5,
        )
        context.fillStyle = 'white'
        context.fill()
        // context.lineWidth = 5
        context.strokeStyle = 'white'
        context.stroke()
        context.drawImage(
          logo,
          canvas.width - (width + 20),
          canvas.height - (height + 20),
          width - 5,
          height - 5,
        )

      }

      const b64 = canvas.toDataURL()
      this.setState({ preview: b64 }, this.context.hideLoading())
      if (setBase64DownloadImage) {
        setBase64DownloadImage(b64)
      }
    }
  }

  converterImagem(imagem) {
    this.context.showLoading();
    const url = process.env.REACT_APP_LABS_API + `imageBase.php`
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'caminho': imagem }),
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          this.setState({
            imagem: 'data:image/png;base64,' + `${json.base}`,
          })
        } else {
          this.setState({
            imagem: '',
          }, this.context.hideLoading())
        }

      })
  }

  obterLogo(cnpj) {
    fetch(process.env.REACT_APP_LABS_API + `getLogoOtica.php?cnpjOtica=${cnpj}`)
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          this.setState({
            logo_otica: 'data:image/png;base64,' + `${json.base}`,
          })
        } else {
          this.setState({
            logo_otica: '',
          }, this.context.hideLoading())
        }
      })
  }

  render() {
    const { widthCanva, heightCanva } = this.props
    return (
      <div>
        {this.state.preview && (
          <img src={this.state.preview} className="img-modal" alt="" />
        )}
        <canvas
          ref="canvas"
          width={widthCanva}
          height={heightCanva}
          className="d-none"
        />
        <img
          ref="imagem"
          src={this.state.imagem}
          className="d-none"
		  crossOrigin="anonymous"
		  alt=""
        />
        <img
          ref="logo"
          src={this.state.logo_otica}
          className="d-none"
		  crossOrigin="anonymous"
		  alt=""
        />
      </div>
    )
  }
}

export default MergeImageLogo
