import React, { Component } from 'react'
import './media-center-form.css'
import MediaCenterSelect from './select/media-center-select'
import SearchMedia from '../search-media/search-media'
import { Button } from 'react-bootstrap'

class MediaCenterForm extends Component {

	constructor(props) {
		super(props)

		this.limparForm = this.limparForm.bind(this)
		this._handleKeyDown = this._handleKeyDown.bind(this)
	}

	limparForm() {
		this.myFormRef.reset()
		this.props.limparFiltros();
	}

	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			this.props.filtrarMidias()
		}
	}

	render() {
		const { value } = this.props
		return (
			<div className="x-downloads__search">
				<form className="x-downloads__search--form" action="#" ref={(el) => this.myFormRef = el}>
					<div className="row mb-3">
						<div className="col-xs-12 col-md-3 mt-3">
							<MediaCenterSelect
								label={'Marca:'}
								name={'download_search_brand'}
								id={'download_search_brand'}
								value={this.props.marca}
								onChange={this.props.handleChangeMarca}
								textSelected="Selecione"
								onKeyDown={this._handleKeyDown}
								itens={[
									{ id: 'ALAN_MIKLI', nome: 'Alan Mikli' },
									{ id: 'ARMANI_EXCHANGE', nome: 'Armani Exchange' },
									{ id: 'ARNETTE', nome: 'Arnette' },
									{ id: 'BLUE_UV', nome: 'Blue UV' },
									{ id: 'BRASILOR', nome: 'Brasilor' },
									{ id: 'BVLGARI', nome: 'Bvlgari' },
									{ id: 'BURBERRY', nome: 'Burberry' },
									{ id: 'CHANEL', nome: 'Chanel' },
									{ id: 'COACH', nome: 'Coach' },
									{ id: 'CRIZAL', nome: 'Crizal' },
									{ id: 'DOLCE_AND_GABBANA', nome: 'Dolce&Gabbana' },
									{ id: 'EMPORIO_ARMANI', nome: 'Emporio Armani' },
									{ id: 'EMPORIO_ARMANI_KIDS', nome: 'Emporio Armani KIDS' },
									{ id: 'ESPACE', nome: 'Espace' },
									{ id: 'ESSILOR', nome: 'Essilor' },
									{ id: 'EYEZEN', nome: 'Eyezen' },
									{ id: 'GIORGIO_ARMANI', nome: 'Giorgio Armani' },
									{ id: 'GRAZI', nome: 'GRAZI' },
									{ id: 'JEAN_MONNIER', nome: 'Jean Monnier' },
									{ id: 'KIPLING', nome: 'KIPLING' },
									{ id: 'KODAK', nome: 'Lentes Kodak' },
									{ id: 'MICHAEL_KORS', nome: 'Michael Kors' },
									{ id: 'MIRAFLEX', nome: 'Miraflex' },
									{ id: 'MIU_MIU', nome: 'Miu Miu' },
									{ id: 'OLIVER_PEOPLES', nome: 'Oliver Peoples' },
									{ id: 'OPTIFOG', nome: 'Optifog' },
									{ id: 'PERSOL', nome: 'Persol' },
									{ id: 'POLO', nome: 'Polo' },
									{ id: 'POLYPOWER', nome: 'Polypower' },
									{ id: 'PRADA', nome: 'Prada' },
									{ id: 'PRADA_LINEA_ROSSA', nome: 'Prada Linea Rossa' },
									{ id: 'RALPH', nome: 'Ralph' },
									{ id: 'RALPH_LAUREN', nome: 'Ralph Lauren' },
									{ id: 'RAYBAN', nome: 'Ray-Ban' },
									{ id: 'RAYBAN_AUTHENTIC', nome: 'Ray-Ban Authentic' },
									{ id: 'STARK', nome: 'Stark' },
									{ id: 'STELLEST', nome: 'Stellest' },
									{ id: 'SWAROVSKI', nome: 'Swarovski' },
									{ id: 'TECNOl', nome: 'Tecnol' },
									{ id: 'TIFFANY', nome: 'Tiffany' },
									{ id: 'TRANSITIONS', nome: 'Transitions' },
									{ id: 'VARILUX', nome: 'Varilux' },
									{ id: 'VERSACE', nome: 'Versace' },
									{ id: 'VERSACE_KIDS', nome: 'Versace KIDS' },
									{ id: 'VOGUE', nome: 'VOGUE' },
									{ id: 'XPERIO', nome: 'Xperio' }
								]}
							/>
						</div>
						<div className="col-xs-12 col-md-3 mt-3">
							<MediaCenterSelect
								label={'Tipo de Arquivo:'}
								name={'download_search_file'}
								id={'download_search_file'}
								value={this.props.tipo_arquivo}
								onChange={this.props.handleChangeTipoArquivo}
								textSelected="Selecione"
								onKeyDown={this._handleKeyDown}
								itens={[
									{ id: 'ADESIVOS', nome: 'Adesivos' },
									{ id: 'CAMISAS', nome: 'Camisas' },
									{ id: 'EBOOK', nome: 'E-book' },
									{ id: 'EMAILS', nome: 'E-mails' },
									{ id: 'FOLHETO', nome: 'Folheto' },
									{ id: 'LOGOTIPOS', nome: 'Logotipos' },
									{ id: 'OUTDOOR', nome: 'Outdoor' },
									{ id: 'PAINEL_PARA_LOJA', nome: 'Painel para Loja' },
									{ id: 'REDES_SOCIAIS', nome: 'Redes Sociais' },
									{ id: 'SACOLAS', nome: 'Sacolas' },
									{ id: 'SPOTS_DE_RADIO', nome: 'Spots de Rádio' },
									{ id: 'TABELAS', nome: 'Tabelas' },
									{ id: 'VIDEOS', nome: 'Vídeos' },
								]}
							/>
						</div>
						<div className="col-xs-12 col-md-3 mt-3">
							<MediaCenterSelect
								label={'Categoria:'}
								name={'download_search_category'}
								id={'download_search_category'}
								value={this.props.categoria}
								onChange={this.props.handleChangeCategoria}
								textSelected="Selecione"
								onKeyDown={this._handleKeyDown}
								itens={[
									{ id: 'AGENDA_OTICA', nome: 'Agenda ótica' },
									{ id: 'DATAS_ESPECIAIS', nome: 'Datas Especiais' },
									// { id: 'CONSULTA', nome: 'Consulta' },
									// { id: 'DIA_DAS_CRIANCAS', nome: 'Dia das crianças' },
									// { id: 'DIA_DAS_MAES', nome: 'Dia das mães' },
									// { id: 'DIA_DOS_AVOS', nome: 'Dia dos avós' },
									// { id: 'DIA_DOS_NAMORADOS', nome: 'Dia dos namorados' },
									// { id: 'DIA_DOS_PAIS', nome: 'Dia dos pais' },
									// { id: 'DICAS_DE_LENTES', nome: 'Dicas de Lentes' },
									// { id: 'GARANTIA', nome: 'Garantia' },
									{ id: 'MARCA', nome: 'Marca' },
									// { id: 'MODA', nome: 'Moda' },
									// { id: 'POS_VENDA', nome: 'Pós-venda' },
									{ id: 'PRODUTOS_E_TECNOLOGIAS', nome: 'Produtos e Tecnologias' },
									{ id: 'PROMOCOES', nome: 'Promoções' },
									{ id: 'PROTOCOLO_DE_SEGURANCA', nome: 'Protocolo de segurança' },
									{ id: 'RELACIONAMENTO', nome: 'Relacionamento' },
									{ id: 'ARMACAO', nome: 'Armações Luxottica' },
									// { id: 'SAUDE_VISUAL', nome: 'Saúde visual' },
									// { id: 'TROQUE_SUAS_LENTES', nome: 'Troque suas Lentes' },
								]}
							/>
						</div>
						<div className="col-xs-12 col-md-3 mt-3">
							<MediaCenterSelect
								label={'Público:'}
								name={'download_search_public'}
								id={'download_search_public'}
								value={this.props.publico}
								onChange={this.props.handleChangePublico}
								textSelected="Selecione"
								onKeyDown={this._handleKeyDown}
								itens={[
									{ id: 'MULTIFOCAL_INICIANTE', nome: 'Multifocal - Iniciante' },
									{ id: 'MULTIFOCAL_USUARIO', nome: 'Multifocal - Usuário' },
									{ id: 'VISAO_SIMPLES', nome: 'Visão Simples' },
								]}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-xs-12 col-md-6">
							<SearchMedia
								label="Nome:"
								name="search-media"
								value={value}
								onChange={this.props.handleChangeNome}
								onKeyDown={this._handleKeyDown}
							/>
						</div>
						<div className="col-xs-12 col-md-3">
							{/* <button className="midia-search-button buttonSpace" style={{ Width: "100% !important" }} type="button" onClick={this.props.filtrarMidias}>
								<svg xmlns="http://www.w3.org/2000/svg" width="22.75" height="22.745" viewBox="0 0 22.75 22.745">
									<path
										className="x-follow-order__filter-search"
										id="np_search_860389_000000"
										d="M21.938,20.3l5.8,5.8a1.16,1.16,0,0,1-1.64,1.64l-5.8-5.8A9.281,9.281,0,1,1,21.938,20.3Zm-2.411-.77a6.961,6.961,0,1,0-9.845,0A6.961,6.961,0,0,0,19.528,19.528Z"
										transform="translate(-5.324 -5.324)"
										fill="#fff"
										fillRule="evenodd"
									/>
								</svg>
									Buscar mídia
				<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
									<g
										id="Group_13"
										data-name="Group 13"
										transform="translate(-117.064 -5.315)"
									>
										<circle
											id="Ellipse_9"
											data-name="Ellipse 9"
											cx="10.5"
											cy="10.5"
											r="10.5"
											transform="translate(117.064 5.315)"
											fill="#fff"
										/>
										<path
											className="x-follow-order__filter-arrow"
											id="np_arrow_2957554_000000"
											d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
											transform="translate(124.214 19.131) rotate(-90)"
											fill="#0066a0"
										/>
									</g>
								</svg>
							</button> */}

						</div>
						<div className="col-xs-12 col-md-3 mt-4 pt-3 text-center">
							<strong><a href="javascript: void(0)" className="limparFiltro align-middle" onClick={this.limparForm}>Limpar Filtros</a></strong>
						</div>
					</div>
				</form>
			</div >
		)
	}
}

export default MediaCenterForm
