import CnpjUtils from "../../shered/utils/CnpjUtils";

async function getAccountData(parametros) {
	const cnpj = CnpjUtils.cleanCnpj(parametros.cnpj || parametros)

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ cnpj }),
	};

	return fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json

		});
}
export { getAccountData };
