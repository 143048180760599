import React, { Component } from 'react'
import './lab.css'
import Back from '../main/back'
import LabDescription from './description/lab-description';
import LabForm from './form/lab-form';
import LabCard from './card/lab-card';
import AppContext from '../appContext';
import Swal from 'sweetalert2'
import { Redirect } from 'react-router-dom';

class Lab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            labs: [],
            redirectL: false
        }

        this.buscarLabs = this.buscarLabs.bind(this)
        Lab.contextType = AppContext
    }

    componentDidMount() {
        if (!localStorage.getItem('token')) {
            this.setState({ redirectL: true })
        }
    }

    buscarLabs = (estado) => {
        this.context.showLoading()
        var LabsAux = [];

        const requestOptions = {
            method: 'GET',
            headers: {
                client_id: localStorage.getItem('clientid'),
                access_token: localStorage.getItem('token'),
            }
        };

        fetch(process.env.REACT_APP_ESSILOR_API + `gestao-laboratorios/v1/laboratorios?uf=${estado}`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(json => {
                if (json.length > 0) {
                    if (!json[0].codigo) {
                        json.map(item => {
                            var pushObj = {
                                lablogo: item.logo,
                                labname: item.nome,
                                adress: item.endereco.logradouro,
                                city: item.endereco.uf,
                                zip: item.endereco.cep,
                                phone: item.contato.telefone,
                                lablink: item.contato.site,
                                labEmail: item.contato.email
                            }

                            LabsAux.push(pushObj);
                        })
                        this.setState({ labs: LabsAux })
                    } else {
                        Swal.fire({
                            text: json[0].mensagem,
                            icon: 'warning',
                            title: 'Aviso',
                            confirmButtonColor: '#00518b',
                            customClass: {
                                confirmButton: 'x-follow-order__filter-btn',
                            },
                        })
                    }
                } else {
                    Swal.fire({
                        text: "Não foi encontrado nenhum laboratório para esse estado.",
                        icon: 'warning',
                        title: 'Aviso',
                        confirmButtonColor: '#00518b',
                        customClass: {
                            confirmButton: 'x-follow-order__filter-btn',
                        },
                    })
                }

                this.context.hideLoading()
            })
    }


    render() {
        if (this.state.redirectL) {
            return <Redirect to="/" />;
        }

        return (
            <main className="main-lab">
                <Back />
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <LabDescription />
                    </div>
                    <div className="col-12 col-lg-8">
                        <LabForm buscarLabs={this.buscarLabs} />
                        {this.state.labs.length > 0 && (
                            <div className="row mt-5">
                                {this.state.labs.map((item, index) => {
                                    return (
                                        <div key={index} className="col-12 col-md-6 col-lg-12 col-xl-6 pl-0 mt-2">
                                            <LabCard lab={item} />
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    }
}

export default Lab;
