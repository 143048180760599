import React, { Component } from 'react'

class PDFOrderFooter extends Component {

    render() {
        const { footer } = this.props;

        return (
            <div className="pdf-order-footer row">
                <div className="col-6 text-left">
                    <p>
                        O número do seu pedido no laboratório {footer.laboratorio} é{' '}
                        <small>
                            {footer.pedido}
                        </small>
                    </p>
                </div>
                <div className="col-6 text-end">
                    <p style={{ textAlign: 'center' }} className="">
                        A data de saída estimada é{' '}
                        <small className="">
                            {footer.prazo}
                        </small>
                    </p>
                </div>
            </div>
        )
    }
}

export default PDFOrderFooter