import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./../pedido-atacado.css";
import "./modal-matriz-dioptrias.css";
import Input from "../../components/inputs/input";
import AppContext from "../../appContext";
import Swal from "sweetalert2";

class ModalMatrizDioptrias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      matrizDioptrias: [],
      efericoPositivo: [],
      esfericoNegativo: [],
      esferico: [],
      cilindrico: [],
      inserirProdutos: this.props.produtosTabela,
      valoresInputs: [],
    };
    ModalMatrizDioptrias.contextType = AppContext;
    this.inserirMapeamento = this.inserirMapeamento.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.esferico !== this.props.esferico || this.props.esferico !== this.state.esferico) {
      this.setState({ esferico: this.props.esferico });
    }
    if (prevProps.cilindrico !== this.props.cilindrico || this.props.cilindrico.length !== this.state.cilindrico.length) {
      this.setState({ cilindrico: this.props.cilindrico });
    }
    if (prevProps.produto.codigo !== this.props.produto.codigo) {
      this.setState({ matrizDioptrias: [], valoresInputs: [] });
    }
  }
  async inserirMapeamento(props) {

    
    let mensagem = "Os seguintes produtos não foram encontrados no laboratório: <br>";
    let error = false;
    let status = "";
    var inserirProdutos = this.props.produtosTabela;
    this.context.showLoading(`Adicionando produto 1 de ${this.state.matrizDioptrias.length}...`);
    var produtosInseridos = 0;
    const promises = this.state.matrizDioptrias.map(async (item, index) => {
      
      
      var url = `${process.env.REACT_APP_ESSILOR_API}gestao-produtos-laboratorio/v1/produtos-atacado?tipoProduto=${this.props.tipoProduto}&codigoLinhaProduto=${this.props.produto.codigo}&grauEsferico=${item.esferico}&grauCilindrico=${item.cilindrico}`;

      let requestOptions = [];
      if ((this.props?.redes?.length > 0 && this.context.userSession.redeOticas)) {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            client_id: localStorage.getItem("clientid"),
            laboratorio: this.props.laboratorio,
            otica: this.props?.dados_empresa_faturamento_cnpj,
          },
        };
      } else {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            client_id: localStorage.getItem("clientid"),
          laboratorio: this.props.laboratorio,
          },
        };
      }
      const responser = await fetch(url, requestOptions).then(response => {
        if (response.status === 200) {
          status = response.status;
        } else if (response.status !== 200) {
          mensagem = "Falha de comunicação com o laboratório. Tente novamente mais tarde.";
        }
        return response.json();
      });
      const data = await responser;
      if (status === 200 && !data[0]) {
        error = true;
        mensagem += `<b> &bull; ${this.props.produto_nome} </b> com <b> ${`Esférico  ${item.esferico}`} </b> e <b>${` Cilíndrico ${item.cilindrico}</b>`} <br>`;
        const newValuesInputs = { ...this.state.valoresInputs };
        if (newValuesInputs[`${item.esferico}${item.cilindrico}`]) {
          newValuesInputs[`${item.esferico}${item.cilindrico}`].disabled = true;
          newValuesInputs[`${item.esferico}${item.cilindrico}`].quantidade = "";
          newValuesInputs[`${item.esferico}${item.cilindrico}`].className = ";";
        }
        this.setState({ valoresInputs: newValuesInputs });
      }

      if (data && data[0]) {
        for (var i = 0; i < data.length; i++) {
          if (!inserirProdutos.some(item => item.codigo === data[i].codigo)) {
            inserirProdutos.push({
              codigo: data[i].codigo,
              descricao: data[i].descricao,
              quantidade: item.quantidade,
            });
          } else if (inserirProdutos.some(item => item.codigo === data[i].codigo)) {
            const index = inserirProdutos.findIndex(item => item.codigo === data[i].codigo);
            inserirProdutos[index].quantidade = item.quantidade;
          }
        }
        this.setState({ inserirProdutos: inserirProdutos });
        this.props.cleanFields("dioptrias");
      }
      produtosInseridos+= 1;
      this.context.showLoading(`Adicionando produto ${produtosInseridos} de ${this.state.matrizDioptrias.length}...`);
      return data;
    });
    await Promise.all(promises);
    if (error) {
      Swal.fire({
        html: mensagem,
        icon: "warning",
        title: "Aviso",
        width: "600px",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      }).then(result => {
        this.context.hideLoading();
      });
      this.setState({ matrizDioptrias: [], inserirProdutos: [] });
    } else {
      this.setState({ matrizDioptrias: [], valoresInputs: [] });
      props.onHide();
    }
    this.props.handleOrderChange({ produtosTabela: inserirProdutos });
    this.context.hideLoading();
  }

  handleQuantityChange = (event, esferico, cilindrico, colIndex) => {
    const { value } = event.target;
    const { matrizDioptrias } = this.state;

    const novoItem = { cilindrico, esferico, quantidade: parseInt(value) };

    const index = matrizDioptrias.findIndex(item => item?.cilindrico === cilindrico && item?.esferico === esferico);
    if (value === "" || parseInt(value) === 0) {
      if (index !== -1) {
        matrizDioptrias.splice(index, 1);
      }
    } else {
      if (index !== -1) {
        matrizDioptrias[index] = novoItem;
      } else {
        matrizDioptrias.push(novoItem);
      }
    }
    const valoresInputs = { ...this.state.valoresInputs };

    valoresInputs[esferico + cilindrico] = {
      quantidade: value,
      esferico: esferico,
      cilindrico: cilindrico,
      disabled: false,
      className: value.trim() === "" ? "" : "focusInputMatriz",
    };
    this.setState({ matrizDioptrias: matrizDioptrias });
    this.setState({ valoresInputs: valoresInputs });
  };

  //corrije a ordem do campo cilindrico para ordernar
  MyVerticallyCenteredModal = props => {
    const { esferico } = this.state;
    var cilindrico = this.state.cilindrico;
    const compareFunction = (a, b) => {
      const valueA = parseFloat(a.label);
      const valueB = parseFloat(b.label);

      if (valueA <= 0 && valueB <= 0) {
        return valueB - valueA;
      } else if (valueA < 0) {
        return -1;
      } else if (valueB < 0) {
        return 1;
      } else {
        return valueA - valueB;
      }
    };
    cilindrico.sort(compareFunction);
    const criarLinhas = (data, rowIndex) => {
      return (
        <tr className="mapaDioptriasTR" key={rowIndex}>
          <div className="th-matriz-dioptrias">
            <div className="th-container-matriz-dioptrias">
              <th>{data.value}</th>{" "}
            </div>
          </div>
          {/* Adicionando o cabeçalho com o valor do índice */}
          {cilindrico.map((item, colIndex) => (
            <td key={colIndex} className="td-matriz-dioptrias">
              <div className="div-container-input">
                <Input
                  width={50}
                  onChange={event => this.handleQuantityChange(event, data.value, item.value, colIndex)}
                  className={"input-matriz-dioptrias " + this.state.valoresInputs[`${data.value}${item.value}`]?.className}
                  value={this.state.valoresInputs[`${data.value}${item.value}`]?.quantidade}
                  disabled={this.state.valoresInputs[`${data.value}${item.value}`]?.disabled}
                  mask={[
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                />
              </div>
            </td>
          ))}
        </tr>
      );
    };

    const cabecalhoHTML = (
      <tr className="mapaDioptriasTR">
        <th className="th-matriz-dioptrias" id="thEmpty"></th> {/* Cabeçalho vazio para a coluna do índice */}
        {cilindrico.map((value, label) => (
          <th className="th-matriz-dioptrias" key={label}>
            {value.value}
          </th>
        ))}
      </tr>
    );

    const { positivos, negativos } = esferico.reduce(
      (acc, item) => {
        const numero = parseFloat(item.value);
        if (numero >= 0) {
          acc.positivos.push(item);
        } else {
          acc.negativos.push(item);
        }
        return acc;
      },
      { positivos: [], negativos: [] },
    );

    var tabSemPsitivos = "";
    var tabSemNegativos = "";
    var tabelaNegativo = "";
    var tabUmaLinhaPositivo = "";
    var tabUmaLinhaNegativo = "";
    var tabelaPositiva = "";
    if (negativos.length === 0) {
      tabSemNegativos = "tabSemPsitivosOuNegativos";
      tabelaNegativo = "TableDioptriasNotFound";
    }
    if (positivos.length === 0 ) {
      tabSemPsitivos = "tabSemPsitivosOuNegativos";
      tabelaPositiva = "TableDioptriasNotFound";
    }
    if (positivos.length === 1 && cilindrico.length === 1) {
      tabUmaLinhaPositivo = "tabUmaLinha";
    }
    if (negativos.length === 1 && cilindrico.length === 1) {
      tabUmaLinhaNegativo = "tabUmaLinha";
    }

    negativos.sort((b, a) => a.value - b.value);

    const corpoHTMLPositivo = positivos.map(criarLinhas);
    const corpoHTMLNegativo = negativos.map(criarLinhas);
    return (
      <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <spam>Mapa de Dioptrias</spam>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="containerModalMatriz">
            <div className="divContainerTable">
              
               
                <div className={`${tabelaNegativo} divTable`}> 
                <div className="CilindricoContainer">
                  <p>Cilíndrico</p>
                </div>
                  <div className="EsfericoContainer">
                    <p>Esférico ( + ) </p>
                  </div>

                  <div className={`divCardTable ${tabSemPsitivos}`}>
                    <table border="1" id={`tablePositive ${tabUmaLinhaPositivo}`}>
                      {positivos.length !== 0 && (
                        <>
                          <thead class="table-header-horizontal">{cabecalhoHTML}</thead>
                          <tbody>{corpoHTMLPositivo}</tbody>
                        </>
                      )}
                      {positivos.length === 0 && (
                        <div className="containerDioptriasNotFound">
                          <p className="dioptriasNotFound">Sem dioptrias cadastradas.</p>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
             

          
                
                <div className={`${tabelaPositiva} divTable`}><div className="CilindricoContainer">
                  <p>Cilíndrico</p>
                </div>
                  <div className="EsfericoContainer">
                    <p>Esférico ( - )</p>
                  </div>
                  <div className={`divCardTable ${tabSemNegativos}`}>
                    <table border="1" id={`tableNegative ${tabUmaLinhaNegativo}`}>
                      {negativos.length !== 0 && (
                        <>
                          <thead class="table-header-horizontal">{cabecalhoHTML}</thead>
                          <tbody>{corpoHTMLNegativo}</tbody>
                        </>
                      )}
                      {negativos.length === 0 && (
                        <div className="containerDioptriasNotFound">
                          <p className="dioptriasNotFound">Sem dioptrias cadastradas.</p>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer id="contained-modal-title-vcenter">
          <Button
            onClick={event => {
              this.inserirMapeamento(props);
            }}>
            Consultar e incluir produtos
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <a
          className="ancorThinDark"
          variant="primary"
          onClick={() => {
            const tipo_produto = document.getElementById("tipo_produto");
            const campoValue = tipo_produto.value;

            if ((this.props?.produto?.length === 0 || this.props.tipoProduto) && !this.props.produto?.codigo) {
              this.props.validateFields();
            } else {
              this.setState({
                modalShow: true,
                matrizDioptrias: [],
                inserirProdutos: [],
                valoresInputs: [],
              });
            }
          }}>
          Mapa de Dioptrias
        </a>

        <this.MyVerticallyCenteredModal show={this.state.modalShow} onHide={() => this.setState({ modalShow: false })} />
      </>
    );
  }
}

export default ModalMatrizDioptrias;
