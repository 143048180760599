import React, { Component } from 'react'

//Import CSS

//Import internal components

import Select from '../selects/select'
import SelectAuto from '../selects/select-auto'

//Import external components

//Import images

const select = `<Select
	label='Nome'
	name='name_select'
	id='name_select'
	defaultValue='value1'
	required={false}
	width='300px'
	aria='aria'
	onChange={this.onChange}
	placeholder='placeholder'
	itens={[
		{label: 'label1', value: 'value1'},
		{label: 'label2', value: 'value2'},
		{label: 'label3', value: 'value3'}
	]}
/>`

const select_auto = `<SelectAuto
 	label='Nome'
 	name='name_select_auto'
 	id='name_select_auto'
 	defaultValue='value2'
 	required={false}
 	width='300px'
 	aria='aria'
 	placeholder='Selecione'
 	onChange={this.onChange}
 	onBlur={this.onBlur}
 	options={[
		{label: 'label1', value: 'value1'},
		{label: 'label2', value: 'value2'},
		{label: 'label3', value: 'value3'}
	]}
/>`

class DocDropdown extends Component {

	constructor(props) {

		super(props)
		this.state = {
			select,
			select_auto,
		}

		this.handleChangeShow = this.handleChangeShow.bind(this)
	}

	handleChangeShow(evt) {
		if (!this.state[evt.target.id]) {
			this.setState({ [evt.target.id]: true })
		}
		else {
			this.setState({ [evt.target.id]: false })
		}
	}

	render() {

		return (
			<div>

				<h3 className='conecta-h3'>Select</h3>

				<p className='doc-paragraph'>Generic simple select component, where it can be selected only one option.</p>

				<p className='doc-info-box' id='select_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.select_code &&
					<pre>{this.state.select}</pre>
				}

				<br />

				<p className='doc-info-box' id='select_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.select_props &&
					<div>
						<table>
							<thead>
								<th>Prop</th>
								<th>Type</th>
								<th>Default</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				}

				<br />

				<p className='doc-info-box' id='select_variation' onClick={this.handleChangeShow}>Example</p>
				{this.state.select_variation &&
					<Select
						label='Nome'
						name='name_select'
						id='name_select'
						defaultValue='value1'
						required={false}
						width='300px'
						aria='aria'
						placeholder='Selecione'
						onChange={this.onChange}
						itens={[
							{label: 'label1', value: 'value1'},
							{label: 'label2', value: 'value2'},
							{label: 'label3', value: 'value3'}
					]}
					/>
				}

				<br />
				<br />

				<h3 className='conecta-h3'>Select Autocomplete</h3>

				<p className='doc-paragraph'>Generic auto complete select, where options can be filtered by typing on the field.</p>

				<p className='doc-info-box' id='select_auto_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.select_auto_code &&
					<pre>{this.state.select_auto}</pre>
				}

				<br />

				<p className='doc-info-box' id='select_auto_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.select_auto_props &&
					<div>
						<table>
							<thead>
								<th>Prop</th>
								<th>Type</th>
								<th>Default</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				}

				<br />

				<p className='doc-info-box' id='select_auto_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.select_auto_variations &&
				<SelectAuto
					label='Nome'
					name='name_select_auto'
					id='name_select_auto'
					defaultValue='value2'
					required={false}
					width='300px'
					aria='aria'
					placeholder='Selecione'
					onChange={this.onChange}
					onBlur={this.onBlur}
					options={[
						{label: 'label1', value: 'value1'},
						{label: 'label2', value: 'value2'},
						{label: 'label3', value: 'value3'}
					]}
				/>}

			</div>
		)
	}
}

export default DocDropdown
