import React, { Component } from "react";
import Input from "../../components/inputs/input";

class InputEmail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <Input
          type="text"
          label={this.props.label}
          width={this.props.width || "100%"}
          name={this.props.name}
          id={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          tooltip={this.props.tooltip}
        />
      </div>
    );
  }
}

export default InputEmail;
