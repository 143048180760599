import React, { Component } from "react";
import "./baseColapse.css";
import { FiFilter } from "react-icons/fi";
class ContainerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: this.props.colapse,
    };
  }

  toggleCollapse = () => {
    this.setState(prevState => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  render() {
    const divColapseFiltro = this.props.divColapseFiltro ? this.props.divColapseFiltro : "divColapseFiltro";
    const { isCollapsed } = this.state;
    const { id, name, value, onChange, width, height, display } = this.props;
    const divStyleColBaseColapseButton = {
      justifyContent: this.props.colBaseColapseButtonDisplayJustifyContent,
    };
    const divStyle = {
      width: width,
      height: height,
      display: display,
    };
    return (
      <div className={`container ${isCollapsed ? " collapsed" : " uncollapsed"}`}>
        <div className="row baseColapseButton">
          <div className="col-12 colBaseColapseButton" style={divStyleColBaseColapseButton}>
            <button onClick={this.toggleCollapse} className="filterButton">
              {this.props.buttonName == "Filtros" && <FiFilter size={15} />}
              {isCollapsed ? ` Exibir ${this.props.buttonName}` : ` Ocultar ${this.props.buttonName}`}
            </button>
          </div>
        </div>

        <div className={ `${divColapseFiltro} ${isCollapsed ? " collapsed" : " uncollapsed"}`}>
          <div className="row baseColapse w-100" id="rowFiltroBase">
            <div className="col-12 w-100" id="colBaseCOlapse" style={divStyle}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContainerComponent;
