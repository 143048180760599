import React, { Component } from "react";

//Import CSS
import "./input.css";

//Import internal components

//Import external components
import MaskedInput from "react-text-mask";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Import images

class Input extends Component {
  render() {
    return (
      <div className="crm-input" style={{ width: this.props.width }}>
        <label className="crm-input-label" htmlFor={this.props.name}>
          {this.props.label}
        </label>

        {this.props.mask && (
          <MaskedInput
            type={this.props.type}
            autoComplete={this.props.autocomplete}
            value={this.props.value}
            name={this.props.name}
            id={this.props.id ? this.props.id : this.props.name}
            aria={this.props.aria}
            placeholder={this.props.placeholder}
            className={this.props.className}
            required={this.props.required}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            onInvalid={this.props.onInvalid}
            onInput={this.props.onInput}
            onPaste={this.props.onPaste}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
            onFocus={this.props.onFocus}
            guide={this.props.guide}
            mask={this.props.mask}
          />
        )}

        {!this.props.mask && (
          <input
            className={this.props.className}
            type={this.props.type}
            autocomplete={this.props.autoComplete}
            defaultValue={this.props.value}
            value={this.props.value}
            name={this.props.name}
            id={this.props.id ? this.props.id : this.props.name}
            aria={this.props.aria}
            placeholder={this.props.placeholder}
            required={this.props.required}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
            pattern={this.props.pattern}
            title={this.props.title}
            autoFocus={this.props.autoFocus}
            onInvalid={this.props.onInvalid}
            onInput={this.props.onInput}
            onPaste={this.props.onPaste}
            minlength={this.props.minlength}
            maxlength={this.props.maxlength}
            min={this.props.min}
            max={this.props.max}
            step={this.props.step}
            onKeypress={this.props.onkeypress}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            onClick={this.props.onClick}
            onFocus={this.props.onFocus}
            onMouseLeave={this.props.onMouseLeave}
            onMouseOut={this.props.onMouseOut}
          />
        )}

        {this.props.tooltip && (
          <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{this.props.tooltip}</Tooltip>}>
            <span className="tooltip-shape"></span>
          </OverlayTrigger>
        )}
      </div>
    );
  }
}

export default Input;
