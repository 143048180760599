import React, { Component } from 'react'
import '../index/index.css'
import logobanner from '../index/img/logo-banner-2021.png'

class Banner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgnum: Math.floor(Math.random() * 3) + 1
        };
    }

    componentDidMount() {
        this.setState({ imgnum: Math.floor(Math.random() * 3) + 1 })
    }

    render() {
        const { imgnum } = this.state;
        var imgLink = 'login-banner_' + imgnum;
        var imgLinkTablet = 'login-signin__sign-up--tablet-image_' + imgnum;

        return (
            <div className={imgLink}>
                <div className='login-banner__color'>

                    <div className='login-banner__container'>
                        <div className='login-banner__logo'>
                            <a href='/' className='login-banner__logo-link'>
                                <img alt="" src={logobanner} className='login-banner__logo-image' />
                            </a>
                        </div>
                        <div className="d-none d-md-block">

                            <div className='login-banner__title'>
                                <h1 className='login-banner__title-text'>
                                    Este espaço é para você!
                                </h1>
                            </div>
                            <div className='login-banner__paragraph banner-main'>
                                <p className='login-banner__paragraph-text'>
                                    Bem-vindo ao Essilor Conecta, um
                                    ambiente único de relacionamento entre
                                    a Essilor e seus parceiros. Conecte-se com
                                    a gente e leve soluções inovadoras e
                                    benefícios únicos da Essilor diretamente
                                    para o seu negócio.
                                </p>
                            </div>
                            <div className='login-signin__sign-up--tablet'>
                                <div className={imgLinkTablet}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;