import React, { Component } from 'react'
import Footer from './footer'
import './main.css'
import Back from './back'

class NotFound extends Component {

	render() {
		return (
			<div>
				<div className='notfound'>
					<Back></Back>
					Página não encontrada.
				</div>
			</div>
		);
	}
}

export default NotFound;
