import React, { Component } from "react";

import "./index.css";
import AppContext from "../../../../../../appContext";


class DownloadCSVAncora extends Component {
  constructor(props) {
    super();
    this.downloadCSV = this.downloadCSV.bind(this);
    DownloadCSVAncora.contextType = AppContext;
  }


  convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ";";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = keys.join(columnDelimiter) + lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        if (typeof item[key] === "object" && item[key] !== null) {
          result += "";
        } else {
          result += item[key];
        }
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV= () => {
    const link = document.createElement("a");
    let filename = this.props.fileName;
    let csv = "\uFEFF" + this.convertArrayOfObjectsToCSV(this.props.data);

    const excelSeparator = window.navigator.appVersion.includes("Windows") ? ";" : ",";
    csv = csv.replace(/[,;]/g, excelSeparator);

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }


  render() {
    return (
      <>
        {this.props.download ? (
          <a className="tagADownloadCSV" onClick={this.props.onClick}>
            {this.props.linkName}
          </a>
        ) : (
          <a className="tagADownloadCSV" onClick={this.downloadCSV}>
            {this.props.linkName}
          </a>
        )}
      </>
    );
  }
}

export default DownloadCSVAncora;
