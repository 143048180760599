import React, { Component } from 'react'

//Import CSS

//Import internal components
import AppContext from '../../appContext'

//Import external components

//Import image
import useravatar from './../header/img/user-avatar.png'

class HeaderAvatar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imagePreviewUrl: ''
        }

        HeaderAvatar.contextType = AppContext
    }

    componentDidMount() {
        var token = localStorage.getItem("token");

        if (token === null) {
            window.location.href = "/";
        }
    }

    render() {
        if (this.context.avatar) {
            return (
                <img
                    className={`my-account__avatar ${this.props.className}`}
                    src={this.context.avatar} alt="logo" />
            )
        }
        else {
            return (
                <div
                    className={`my-account__avatar_container ${this.props.className}`}
                ></div>
            );
        }
    }
}

export default HeaderAvatar;