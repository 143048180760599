import React, { Component } from "react";
import Moment from "moment";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class AlertNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFim: "",
      mensagem: "",
      isView: false,
    };
  }

  componentDidMount() {
    const { data, mensagem } = this.props;
    if (data && mensagem) {
      Moment.locale("pt-br");
      let hoje = Moment(new Date());
      let dataLimite = Moment(data).add(3, "hours"); //o timezone do OEC é +00:00 por isso adiciona +3horas.

      if (dataLimite.isValid()) {
        if (hoje.isBefore(dataLimite)) {
          this.setState({
            dataFim: dataLimite,
            mensagem: mensagem,
            isView: true,
          });
        }
      }
    }
  }

  render() {
    return this.state.isView ? (
      <>
        <main className="position-relative">
          <div className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-warning p-2">
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 600 }} overlay={<Tooltip id="button-tooltip">{this.state.mensagem}</Tooltip>}>
              <ExclamationCircleFill size={25} className="float-end" />
            </OverlayTrigger>
          </div>
        </main>
      </>
    ) : (
      <>
        <main className="position-relative"></main>
      </>
    );
  }
}

export default AlertNews;
