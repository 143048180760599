import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderEspecificacao extends Component {

    render() {
        const { especificacao } = this.props;
        return (
            <div className="pdf-order-dados row">
                <div className="col-12">
                    <h1 className="pdf-title">Especificações da lente</h1>
                </div>
                <div className="col-6">
                    <h3 className="pdf-subtitle-bold">Produto</h3>
                    <PDFOrderP label='Tipo de produção' value={especificacao.tipo_producao} />
                    <PDFOrderP label='Tipo de produto' value={especificacao.tipo_produto} />
                    <PDFOrderP label='Produto escolhido' value={especificacao.produto_escolhido} />
                </div>
                <div className='col-6'>
                    <h3 className="pdf-subtitle-bold">Serviços Adicionais</h3>
                    <PDFOrderP label='AR' value={especificacao.servico} />
                    <PDFOrderP label='Coloração' value={especificacao.coloracao} />
                    {especificacao.cor && (
                        <PDFOrderP label='Cor' value={especificacao.cor} />
                    )}
                    <PDFOrderP label='Corte/Montagem' value={especificacao.corte_montagem} />
                </div>
            </div>
        )
    }
}

export default PDFOrderEspecificacao