import React, { Component } from 'react'

//Import CSS
import './signup.css'

//Import internal components
import SignupAccountCheck from './signup-account-check'
import Footer from '../main/footer'
import { useParams } from "react-router-dom";

//Import external components

//Import images

class Signup extends Component {

	constructor(props) {
		super(props)

		this.state = {
			onlyS9: false
		}

	}

	componentDidMount() {
		let onlyS9 = this.props.location.search;

		if (onlyS9) {
			onlyS9 = onlyS9.split('?')[1];
			let name = onlyS9.split('=')[0];
			let value = onlyS9.split('=')[1] == "true";
			if (name === "onlyS9") {
				this.setState({ onlyS9: value })
			}
		}
	}

	render() {
		const { onlyS9 } = this.state;

		return (
			<main className='signup-main' id="main-body">
				<SignupAccountCheck onlyS9={onlyS9}/>

				<Footer></Footer>
				<div className="x-overlay" id="overlayComponent"></div>
			</main>
		);
	}
}

export default Signup;