import React, { Component } from "react";
// import Input from 'react-select/src/components/Input';
import XMLParser from "react-xml-parser";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExclamationCircleFill, XCircleFill } from "react-bootstrap-icons";
import ImageUploader from "react-images-upload";
import AppContext from "../appContext";
import "./remote-edge.css";

class RemoteEdge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      text: null,
      json: null,
      preview: "",
      buttonText: "Clique aqui para adicionar um arquivo de leitura da armação.",
    };

    RemoteEdge.contextType = AppContext;
    this.onDrop = this.onDrop.bind(this);
    // this.handleChange = this.handleChange.bind(this)
    this.placeFileContent = this.placeFileContent.bind(this);
    this.readFileContent = this.readFileContent.bind(this);
    this.remove = this.remove.bind(this);

    this.removeRef = React.createRef();
  }

  componentDidMount() {
    const { preview, buttonText } = this.props;
    if (preview) {
      this.setState({ preview: preview, buttonText: buttonText });
    }
  }

  onDrop(pictureFiles, pictureDataURLs) {
    const { validfile } = this.props;
    if (pictureFiles && pictureFiles[0]) {
      this.setState(
        {
          file: pictureFiles[0],
          preview: pictureDataURLs,
          buttonText: "Arquivo " + pictureFiles[0].name + " adicionado.",
        },
        () => {
          if (validfile) {
            Swal.fire({
              html: "<p>Ao carregar um arquivo de leitura da armação, as medidas da armação informadas no formulário serão desconsideradas. <br> Deseja continuar?<p> <br><br>",
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, adicionar!",
              cancelButtonText: "Não",
            }).then(result => {
              if (result.value) {
                this.placeFileContent();
              } else {
                this.setState({ file: null, preview: "", text: "", json: null, buttonText: "Clique aqui para adicionar um arquivo de leitura da armação." });
              }
            });
          } else {
            this.placeFileContent();
          }
        },
      );
    }
  }

  remove() {
    const { removeRemoteEdge } = this.props;
    this.setState({ file: null, preview: "", text: "", json: null, buttonText: "Clique aqui para adicionar um arquivo de leitura da armação." }, () => {
      removeRemoteEdge("");
    });
  }

  placeFileContent() {
    const { setRemoteEdge } = this.props;
    var ext = this.state.file.name.split(".").pop();

    this.readFileContent(this.state.file)
      .then(content => {
        setRemoteEdge(content, this.state.preview, this.state.buttonText);
        this.setState({ text: content });
        var json = null;
        if (ext === "lab") {
          json = this.convertLAB(content);
          this.setState({ json: JSON.stringify(json) });
        } else if (ext === "oma") {
          json = this.convertOMA(content);
          this.setState({ json: JSON.stringify(json) });
        }
      })
      .catch(error => {
        // console.log(error)
      });
  }

  readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target.result);
      reader.onerror = error => reject(error);
      reader.readAsText(file);
    });
  }

  convertLAB(content) {
    var xml = new XMLParser().parseFromString(content); // Assume xmlText contains the example XML
    var json = JSON.parse(JSON.stringify(xml));

    return json;
  }

  convertOMA(content) {
    var cells = content.split("\n").map(function (el) {
      return el.split(/\s+/);
    });

    var obj = [];
    var objAux = {};

    cells.map(function (el) {
      var key = el[0].split("=")[0];
      var val = el[0].split("=")[1];

      if (objAux.hasOwnProperty(key)) {
        objAux[key] = objAux[key] + val;
      } else {
        objAux[key] = val;
      }
    });

    obj.push(objAux);

    var json = JSON.parse(JSON.stringify(obj));

    return obj;
  }

  render() {
    return (
      <div className="mt-4 pt-xs-0 mb-5 pb-5 text-center edge">
        <div className="image-upload" onDrop={this.drop}>
          <p className="upload-content-paragraph">{this.props.label}</p>

          {!this.state.preview && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">São permitidos apenas arquivos .lab, .oma, .vca, .xml</Tooltip>}>
              <ExclamationCircleFill size={30} className="tolltip float-end" />
            </OverlayTrigger>
          )}
          {this.state.preview && (
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover</Tooltip>}>
              <XCircleFill color="red" size={30} className="tolltip float-end" onClick={this.remove} ref={this.removeRef} id="removeFile" />
            </OverlayTrigger>
          )}

          <ImageUploader
            withIcon={false}
            buttonText={this.state.buttonText}
            onChange={this.onDrop}
            imgExtension={[".oma", ".lab", ".vca", ".xml"]}
            maxFileSize={8242880}
            accept="*"
            withPreview={false}
            withLabel={false}
            className="upload-content-remote"
            buttonClassName="btn-upload"
            singleImage={true}
          />
        </div>
      </div>
    );
  }
}
export default RemoteEdge;
