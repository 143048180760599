import React, { Component } from 'react'

class PDFOrderP extends Component {

    render() {
        const { label, value } = this.props;
        return (
            <div className="row pdf-span">
                <div className="col-3 text-left p-0">
                    <p className="float-left">{label}</p>
                </div>
                <div className="col-8 text-end p-0">
                    <p className="float-end">{value ? value : '-'}</p>
                </div>
            </div>
        )
    }
}

export default PDFOrderP