import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Swal from 'sweetalert2';
import CnpjUtils from "../../shered/utils/CnpjUtils";
import CpfUtils from "../../shered/utils/CpfUtils";
import RequestiaLink from "../utils/RequestiaLink";

const formatDocument = (doc) => {
	const digits = doc.replace(/\D/g, '');

	if (digits.length === 11) {
		return CpfUtils.formatCpf(doc);
	} else if (digits.length === 14) {
		return CnpjUtils.formatCnpj(doc);
	}
	return doc;
};

const SupportAlertMessage = ({ message, document }) => (
	<div>
		{ message }
		{ document ? (
			<> <b>{ formatDocument(document) }</b>.</>
		) : null }
		{ ' ' }Para verificar seu cadastro na base da Essilor, favor entrar em contato com o suporte através da
		Ferramenta{ ' ' }
		<RequestiaLink/>.
	</div>
);

const showSupportAlertMessage = (message, document) => {
	const html = ReactDOMServer.renderToStaticMarkup(<SupportAlertMessage document={ document } message={ message }/>);

	return Swal.fire({
		html,
		icon: 'warning',
		title: 'Aviso',
		confirmButtonColor: '#00518b',
		customClass: {
			confirmButton: 'x-follow-order__filter-btn',
		},
	});
};

export default showSupportAlertMessage;
