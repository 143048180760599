import React, { Component } from "react";
import "./table.css";
import Swal from "sweetalert2";
import TrackingTableLine from "./tracking-table-line";
import DownloadCSVAncora from "../../components/utils/download-file/tag-a/automatic-download-user-machine/CSV";
import AppContext from "../../appContext";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import Table from "react-bootstrap/Table";

import ReactPaginate from "react-paginate";

export class TrackingTableHead extends Component {
	constructor(props) {
		super(undefined);

		this.state = {
			table_export: [],
			data_tabela: [],
			pageCount: Math.ceil(props.data_tabela.length / 10),
			offset: 0,
			orderedColumn: null,
			ascOrder: true,
			tableAux: props.data_tabela,
			key: Date.now(),
		};
		TrackingTableHead.contextType = AppContext;
		this.paginateData = this.paginateData.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
	}

	componentDidMount() {
		this.paginateData();
	}

	paginateData() {
		const dataPagination = this.state.tableAux.slice(this.state.offset, this.state.offset + 10);
		this.setState({ data_tabela: dataPagination });
	}

	handlePageClick = e => {
		const selectedPage = e.selected;
		const offset = selectedPage * 10;
		this.setState({ offset: offset, data_tabela: [] }, this.paginateData);
	};

	orderByColumn = column => {
		const { orderedColumn, ascOrder, tableAux } = this.state;

		const ordemNova = orderedColumn === column ? !ascOrder : true;

		const dadosOrdenados = [ ...tableAux ].sort((a, b) => {
			if (a[column] < b[column]) {
				return ordemNova ? -1 : 1;
			}
			if (a[column] > b[column]) {
				return ordemNova ? 1 : -1;
			}
			return 0;
		});

		this.setState(
			{
				offset: 0,
			},
			() => {
				const dataPagination = dadosOrdenados.slice(this.state.offset, this.state.offset + 10);
				this.setState(prevState => ({
					data_tabela: dataPagination,
					tableAux: dadosOrdenados,
					orderedColumn: column,
					ascOrder: ordemNova,
					key: prevState.key + 1,
				}));
			},
		);
	};

	organizeData = async listaCompleta => {
		if (listaCompleta) {
			this.context.showLoading("Gerando Ficha Financeira...");
		} else {
			this.context.showLoading("Gerando Lista...");
		}
		var table = [];
		const requestOptions = {
			method: "GET",
			headers: {
				access_token: localStorage.getItem("token"),
				client_id: localStorage.getItem("clientid"),
				laboratorio: this.props.laboratorio_cnpj,
				otica: this.props.otica_cnpj.replace(/[^0-9]+/g, ""),
			},
		};
		await fetch(
			process.env.REACT_APP_ESSILOR_API +
			`gestao-financeira-cliente-laboratorio/v1/faturas?status=${
				this.props.status === "BAIXADO" && this.props.status !== "AVENCER" ? "PENDENTE" : "BAIXADO"
			}`,
			requestOptions,
		)
			.then(response => response.json())
			.then(response => {
				let data = [];
				if (response.length >= 1) {
					response.forEach(fatura => {
						let dataVencimento = new Date(fatura.dataVencimento);
						let hoje = new Date();
						let status;

						if (hoje.getTime() > dataVencimento.getTime()) {
							status = "Vencido";
						} else if (dataVencimento.getTime() - hoje.getTime() <= 30 * 24 * 60 * 60 * 1000) {
							status = "AVENCER";
						} else {
							status = "PENDENTE";
						}

						if (fatura.status === "BAIXADO") {
							status = "PAGO";
						}

						data.push({
							codigo: fatura.codigo,
							parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
							nDocumento: fatura.numeroDocumento,
							dataEmissao: fatura.dataEmissao,
							dataVencimento: fatura.dataVencimento,
							valor: fatura.valor,
							valorAberto: fatura.valorAberto,
							status: status,
						});
					});
				}

				if (this.props?.table.length === 0) {
					table = [ ...this.state.tableAux, ...data ];
				} else {
					table = [ ...this.props?.table, ...data ];
				}

			})
			.catch(() => {
				this.context.hideLoading();
				Swal.fire({
					text: "Erro interno, favor tentar novamente mais tarde.",
					title: "Aviso",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Ok",
				});
			});

		this.setState({ table_export: table });
		let newTableExport = table.map(obj => {
			const { ...rest } = obj;
			const { parcela, codigo, nDocumento, ...restWithoutParcelaDataEmissao } = rest;
			const newObj = {
				codigo: codigo,
				numeroDocumento: nDocumento,
				parcela: parcela.split("/")[0] + " de " + parcela.split("/")[1],
				...restWithoutParcelaDataEmissao,
				status: obj.status,
			};
			const hoje = new Date();
			const today = new Date();
			const next15Days = new Date(today);
			next15Days.setDate(today.getDate() + 15);
			let status = '';
			const dataVencimento = new Date(newObj.dataVencimento);
			const parcelaFirstPart = newObj.parcela.replace(/\D/g, "").substring(0, 2);
			const parcelaSecondPart = newObj.parcela.replace(/\D/g, "").substring(2);
			const parcelas = `${ parcelaFirstPart.substring(0, 1) }-${ parcelaFirstPart.substring(1) }/${ parcelaSecondPart }`;
			const isBaixado = newObj.status === "BAIXADO";
			const valorNumerico = Math.round(newObj.valor * 100) / 100;
			const valorAbertoNumerico = isBaixado ? 0 : Math.round(newObj.valorAberto * 100) / 100;

			if (dataVencimento < today && valorAbertoNumerico > 0) {
				newObj.status = "Vencido";
			} else if (dataVencimento <= next15Days && valorAbertoNumerico > 0) {
				newObj.status = "A Vencer";
			} else if (valorAbertoNumerico > 0) {
				newObj.status = "Em Aberto";
			} else {
				newObj.status = "Pago";
			}
			newObj.parcela = parcelas;


			return newObj;
		});
		newTableExport.sort((a, b) => {
			const statusOrder = { AVENCER: 0, PENDENTE: 1, PAGO: 2 };
			return statusOrder[a.status] - statusOrder[b.status];
		});

		if (listaCompleta == true) {
			newTableExport = await this.getFaturaComDetalhesBoleto(newTableExport);
			if (!newTableExport) return;
		}

		this.setState({ table_export: newTableExport }, () => {
			this.childRef.downloadCSV();
		});
		this.context.hideLoading();
	};

	async getFaturaComDetalhesBoleto(faturas) {
		const resultado = [];

		for (const item of faturas) {
			try {
				const detalhesBoleto = await this.fetchDetalhesBoleto(item.codigo, item.numeroDocumento, item.dataEmissao);

				detalhesBoleto.forEach(detalhe => {
					let linha = [];
					linha["Número Documento"] = item.numeroDocumento;
					linha["Número Nota Fiscal"] = detalhe.numeroNotaFiscal;
					linha["Número Pedido"] = detalhe.idPedido;
					linha["Data Pedido"] = new Date(detalhe.dataPedido).toLocaleDateString("pt-BR");
					linha["Valor Total"] = detalhe.valorTotal;
					linha["Valor Mercadoria"] = detalhe.valorMercadoria;
					linha["Valor Serviço"] = detalhe.valorServico;
					linha["Valor Desconto"] = detalhe.valorDesconto;
					linha["Valor Desconto Serviço"] = detalhe.valorDescontoServico;
					linha["Valor Frete"] = detalhe.valorFrete;
					linha["Valor Financeiro"] = detalhe.valorFinanceiro;
					linha["Data Fechamento"] = new Date(detalhe.dataFechamento).toLocaleDateString("pt-BR");
					linha["Número Parcela"] = item.parcela;
					linha["Data Vencimento"] = new Date(item.dataVencimento).toLocaleDateString("pt-BR");
					linha["Status"] = item.status;
					resultado.push(linha);
				});
			} catch (error) {
				this.context.hideLoading();
				Swal.fire({
					text: "Não foi possível recuperar os detalhes de todas as faturas. Tente novamente ou entre em contato com o Laboratório.",
					title: "Aviso",
					icon: "error",
					showCancelButton: false,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Ok",
				});
				return;
			}
		}

		return resultado;
	}

	async fetchDetalhesBoleto(codigoDocumento, numeroDocumento, dataEmissao) {
		const header = {
			client_id: localStorage.getItem("clientid"),
			access_token: localStorage.getItem("token"),
			laboratorio: this.props.laboratorio_cnpj,
			otica: this.props.otica_cnpj.replace(/[^0-9]+/g, ""),
		};
		const requestOptions = {
			method: "GET",
			headers: header,
		};

		var status = null;

		const response = await fetch(
			process.env.REACT_APP_ESSILOR_API +
			`gestao-financeira-cliente-laboratorio/v1/faturas/${ codigoDocumento }/boleto/detalhe?dataEmissao=${ dataEmissao }&numeroDocumento=${ numeroDocumento }`,
			requestOptions,
		);
		if (!response.ok) {
			throw new Error("Erro ao buscar detalhes do boleto");
		}
		return response.json();
	}

	handleClick = (listaCompleta, event) => {
		event.preventDefault();
		const nameFileCNPJ = this.props.otica_cnpj ? this.props.otica_cnpj : "";

		let prefixo = listaCompleta ? "FichaFinanceira_" : "ConectaFinanceiro_";
		let nameFile = prefixo + nameFileCNPJ + "_" + this.props.laboratorio_cnpj;
		this.setState({ nameFile: nameFile }, () => {
			this.organizeData(listaCompleta);
		});
	};

	render() {
		const { orderedColumn, ascOrder } = this.state;
		return (
			<div id="table-head-financeiro" ref={ this.integraPedido }>
				{ this.props.submitted }

				<iframe id="boletoPDFComponent" style={ { display: "none" } }></iframe>

				<div className="pagination-container">
					<ReactPaginate
						previousLabel={ "Anterior" }
						nextLabel={ "Próximo" }
						breakLabel={ "..." }
						breakClassName={ "break-me" }
						pageCount={ this.state.pageCount }
						marginPagesDisplayed={ 2 }
						pageRangeDisplayed={ 2 }
						onPageChange={ this.handlePageClick }
						containerClassName={ "pagination" }
						subContainerClassName={ "pages pagination" }
						activeClassName={ "active" }
						key={ this.state.key }
					/>
				</div>
				<div className="divTagA">
					<DownloadCSVAncora
						download
						onClick={ event => this.handleClick(false, event) }
						data={ this.state.table_export }
						linkName={ "Exportar Lista" }
						fileName={ this.state.nameFile }
						ref={ ref => (this.childRef = ref) }
					/>
					<DownloadCSVAncora
						download
						onClick={ event => this.handleClick(true, event) }
						data={ this.state.table_export }
						linkName={ "Exportar Lista Completa (Ficha Financeira)" }
						fileName={ this.state.nameFile }
						ref={ ref => (this.childRef = ref) }
					/>
				</div>
				<Table className="pedidos table text-center" id="pedido-table">
					<thead className="thead-dark" id="thead-table">
					<tr>
						<th className="financeiro-pedidos-tr-th"></th>
						<th className="financeiro-pedidos-tr-th"></th>
						<th className="financeiro-pedidos-tr-th"></th>
						<th className="financeiro-pedidos-tr-th" onClick={ () => this.orderByColumn("nDocumento") }>
							Nº Documento { ascOrder && orderedColumn === "nDocumento" ? <BsArrowUpShort size={ 20 }/> :
							<BsArrowDownShort size={ 20 }/> }
						</th>
						<th className="financeiro-pedidos-tr-th" onClick={ () => this.orderByColumn("parcela") }>
							Parcela { ascOrder && orderedColumn === "parcela" ? <BsArrowUpShort size={ 20 }/> :
							<BsArrowDownShort size={ 20 }/> }
						</th>
						<th className="financeiro-pedidos-tr-th" onClick={ () => this.orderByColumn("dataEmissao") }>
							Data Emissão { ascOrder && orderedColumn === "dataEmissao" ? <BsArrowUpShort size={ 20 }/> :
							<BsArrowDownShort size={ 20 }/> }
						</th>
						<th className="financeiro-pedidos-tr-th" onClick={ () => this.orderByColumn("dataVencimento") }>
							Data Vencimento{ " " }
							{ (ascOrder && orderedColumn === "dataVencimento") || (orderedColumn !== "dataVencimento" && orderedColumn !== null) ? (
								<BsArrowDownShort size={ 20 }/>
							) : (
								<BsArrowUpShort size={ 20 }/>
							) }
						</th>
						<th className="financeiro-pedidos-tr-th" onClick={ () => this.orderByColumn("valor") }>
							Valor { ascOrder && orderedColumn === "valor" ? <BsArrowUpShort size={ 20 }/> :
							<BsArrowDownShort size={ 20 }/> }
						</th>
					</tr>
					</thead>

					{ this.state.data_tabela &&
						this.state.data_tabela.map((item, index) => {
							return (
								<TrackingTableLine
									key={ index }
									laboratorio_nome={ this.props.laboratorio_nome }
									laboratorio_cnpj={ this.props.laboratorio_cnpj }
									detailOrder={ this.props.detailOrder }
									otica_cnpj={ this.props.otica_cnpj }
									item={ item }
									index={ index }
									status={ item.status }
								/>
							);
						}) }
				</Table>
			</div>
		);
	}
}

export default TrackingTableHead;
