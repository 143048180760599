import React, { Component, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { FileEarmarkTextFill } from "react-bootstrap-icons";
import Swal from "sweetalert2";

import PDFOrderHeader from "./pdf-order-header";
import PDFOrderDados from "./pdf-order-dados";
import PDFOrderEspecificacao from "./pdf-order-especificacao";
import PDFOrderPrescricao from "./pdf-order-prescricao";
import PDFOrderArmacao from "./pdf-order-armacao";
import PDFOrderFooter from "./pdf-order-footer";

//Import CSS
import "./pdf-order.css";
import AppContext from "../../appContext";

class PDFOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      order: null,
      header: null,
      dados: null,
      especificacao: null,
      prescricao: null,
      armacao: null,
      footer: null,
      hasDireito: false,
      hasEsquerdo: false,
      empresa_faturamento: "",
      empresa_faturamento_cnpj: "",
    };

    PDFOrder.contextType = AppContext;
  }

  reactToPrintTrigger = () => {
    const { label, btn_class, icon } = this.props;

    return (
      <Button className={btn_class ? btn_class : "pdf-button"} variant="link">
        {icon && <FileEarmarkTextFill size={22} className="me-2" />}
        {label ? label : "Visualizar Pedido"}
      </Button>
    );
  };

  handleOnBeforeGetContent = () => {
    const { laboratorio, otica, pedido_laboratotio, redeOticas, local } = this.props;
    this.context.showLoading("");

    var header = {};
    if (redeOticas || local === "tracking") {
      header = {
        client_id: localStorage.getItem("clientid"),
        access_token: localStorage.getItem("token"),
        laboratorio: laboratorio,
        otica: otica,
      };
    } else {
      header = {
        client_id: localStorage.getItem("clientid"),
        access_token: localStorage.getItem("token"),
        laboratorio: laboratorio,
      };
    }

    return new Promise((resolve: any) => {
      const requestOptions = {
        method: "GET",
        headers: header,
      };

      fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pedidos/" + pedido_laboratotio, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            this.context.hideLoading();
            Swal.fire({
              html: "Erro ao tentar recuperar as informações do pedido. Tente novamente mais tarde.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }
        })
        .then(async json => {
          let armacao = await this.setArmacao(json);
          if (json) {
            if (json.hasOwnProperty("codigo")) {
              this.context.hideLoading();
              Swal.fire({
                html: json.mensagem + 
                '<br><br>Em caso de dúvidas, entre em contato com o laboratório.',
                icon: "error",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              });
            } else if (!armacao) {
              this.context.hideLoading();
              Swal.fire({
                html: "Erro ao tentar recuperar todas as informações do pedido.<br><br>Em caso de dúvidas, entre em contato com o laboratório.",
                icon: "error",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              });
            } else {
              this.getEmpresaFaturamento(async () => {
                this.setState(
                  {
                    header: this.setHeader(json),
                    dados: this.setDados(json),
                    especificacao: this.setEspecificacao(json),
                    prescricao: this.setPrescricao(json),
                    armacao: armacao,
                    footer: this.setFooter(json),
                    order: json,
                  },
                  resolve,
                );
                this.context.hideLoading();
              });
            }
          }
        });
    });
  };

  getEmpresaFaturamento = callback => {
    const { otica } = this.props;
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: otica ? otica : this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState(
          {
            empresa_faturamento: json.nome,
            empresa_faturamento_cnpj: json.cnpj,
          },
          callback,
        );
      });
  };

  setValueOrder(json) {
    var order = {};
    order.valorLiquido = "";

    return order;
  }

  setHeader(json) {
    return {
      pedido: json.id,
      laboratorio: this.props.laboratorio_nome,
      valor_pedido: json.orcamento?.valorLiquido || false,
    };
  }

  setDados(json) {
    return {
      numero_pedido: json.idPedidoOtica,
      nome_cliente: json.hasOwnProperty("paciente") && json.paciente ? json.paciente.nome : "",
      nome_medico: json.hasOwnProperty("medico") && json.medico ? json.medico.nome : "",
      cpf_cliente: json.hasOwnProperty("paciente") && json.paciente ? json.paciente.cpf : "",
      crm_medico: json.hasOwnProperty("medico") && json.medico ? json.medico.crm : "",
      empresa_faturamento: this.state.empresa_faturamento,
      promo_code: json.promocao && json.promocao.hasOwnProperty("voucher") ? json.promocao.voucher : "",
      dados_promo_nome: json.promocao && json.promocao.hasOwnProperty("campanha") ? json.promocao.campanha.nome : "",
    };
  }

  setEspecificacao(json) {
    var servico, corte, coloracao;

    if (json.hasOwnProperty("servicos")) {
      json.servicos.map((item, index) => {
        if (item.tipo === "MONTAGEM") {
          corte = item.descricao;
        }

        if (item.tipo === "COLORACAO") {
          coloracao = item.descricao;
        }

        if (item.tipo === "ANTIRREFLEXO") {
          servico = item.descricao;
        }
      });
    }

    var tipo_producao = "";
    var tipo_produto = "";
    var tipo_producaoAux = "";
    var tipo_produtoAux = "";

    this.setState({ id: json.id });
    if (json.receita) {
      if (json.receita.hasOwnProperty("olhoDireito")) {
        tipo_producaoAux = json.receita.olhoDireito.tipoProducao;
        tipo_produtoAux = json.receita.olhoDireito.tipoProduto;
      } else if (json.receita.hasOwnProperty("olhoDireito")) {
        tipo_producaoAux = json.receita.olhoEsquerdo.tipoProducao;
        tipo_produtoAux = json.receita.olhoEsquerdo.tipoProduto;
      }
    }

    switch (tipo_producaoAux) {
      case "SURFACAGEM_DIGITAL":
        tipo_producao = "Surfaçagem Digital";
        break;
      case "SURFACAGEM_TRADICIONAL":
        tipo_producao = "Surfaçagem Tradicional";
        break;
      case "LENTES_PRONTA":
        tipo_producao = "Lente Pronta";
        break;
      default:
        break;
    }

    switch (tipo_produtoAux) {
      case "BIFOCAL":
        tipo_produto = "Bifocal";
        break;
      case "MULTIFOCAL":
        tipo_produto = "Multifocal";
        break;
      case "VISAO_SIMPLES":
        tipo_produto = "Visão Simples";
        break;
      case "VISAO_INTERMEDIARIA":
        tipo_produto = "Visão Intermediária";
        break;
      default:
        break;
    }

    return {
      tipo_producao: tipo_producao,
      tipo_produto: tipo_produto,
      produto_escolhido: json.receita ? (json.receita.olhoDireito?.produto ? json.receita.olhoDireito.produto : json.receita.olhoEsquerdo.produto) : "",
      servico: servico,
      coloracao: coloracao,
      corte_montagem: corte,
      cor: json.corColoracao ? (json.corColoracao.descricao ? json.corColoracao.descricao : "") : "",
    };
  }

  setPrescricao(json) {
    var response = {
      receita: {
        direito: null,
        esquerdo: null,
      },
      prisma: {
        direito: null,
        esquerdo: null,
      },
      medidas: {
        direito: null,
        esquerdo: null,
      },
      personalizadas: this.setPersonalizados(json),
    };

    if (json.receita && json.receita.hasOwnProperty("olhoDireito")) {
      if (json.receita.olhoDireito.codigoLinhaProduto || json.receita.olhoDireito.grauEsferico || json.receita.olhoDireito.rpl) {
        this.setState({
          hasDireito: true,
        });
      }

      var receitaDireito = {
        esferico: json.receita.olhoDireito.grauEsferico,
        cilindrico: json.receita.olhoDireito.grauCilindro === "0.00" ? "" : json.receita.olhoDireito.grauCilindro,
        eixo: json.receita.olhoDireito.eixo,
        adicao: json.receita.olhoDireito.adicao,
      };

      var prismaDireito = {
        horizontal: json.receita.olhoDireito.valorPrismaHorizontal,
        base_horizontal: json.receita.olhoDireito.eixoPrismaHorizontal,
        vertical: json.receita.olhoDireito.valorPrismaVertical,
        base_vertical: json.receita.olhoDireito.eixoPrismaVertical,
      };

      var medidaDireito = {
        dnp: json.receita.olhoDireito.dnp,
        altura: json.receita.olhoDireito.altura,
        dnp_perto: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoDireito.dnpPerto : "",
        cro: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoDireito.cro : "",
        vertice: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoDireito.distanciaVertice : "",
        et: json.receita.olhoDireito.et,
        ct: json.receita.olhoDireito.ct,
        base: json.receita.olhoDireito.base,
      };

      response.receita.direito = receitaDireito;
      response.prisma.direito = prismaDireito;
      response.medidas.direito = medidaDireito;
    }

    if (json.receita && json.receita.hasOwnProperty("olhoEsquerdo")) {
      if (json.receita.olhoEsquerdo.codigoLinhaProduto || json.receita.olhoEsquerdo.grauEsferico || json.receita.olhoEsquerdo.rpl) {
        this.setState({
          hasEsquerdo: true,
        });
      }

      var receitaEsquerdo = {
        esferico: json.receita.olhoEsquerdo.grauEsferico,
        cilindrico: json.receita.olhoEsquerdo.grauCilindro === "0.00" ? "" : json.receita.olhoEsquerdo.grauCilindro,
        eixo: json.receita.olhoEsquerdo.eixo,
        adicao: json.receita.olhoEsquerdo.adicao,
      };

      var prismaEsquerdo = {
        horizontal: json.receita.olhoEsquerdo.valorPrismaHorizontal,
        base_horizontal: json.receita.olhoEsquerdo.eixoPrismaHorizontal,
        vertical: json.receita.olhoEsquerdo.valorPrismaVertical,
        base_vertical: json.receita.olhoEsquerdo.eixoPrismaVertical,
      };

      var medidaEsquerdo = {
        dnp: json.receita.olhoEsquerdo.dnp,
        altura: json.receita.olhoEsquerdo.altura,
        dnp_perto: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoEsquerdo.dnpPerto : "",
        cro: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoEsquerdo.cro : "",
        vertice: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoEsquerdo.distanciaVertice : "",
        et: json.receita.olhoEsquerdo.et,
        ct: json.receita.olhoEsquerdo.ct,
        base: json.receita.olhoEsquerdo.base,
      };

      response.receita.esquerdo = receitaEsquerdo;
      response.prisma.esquerdo = prismaEsquerdo;
      response.medidas.esquerdo = medidaEsquerdo;
    }

    return response;
  }

  setPersonalizados(json) {
    var response = {
      iniciais_cliente: json.hasOwnProperty("paciente") && json.paciente ? json.paciente.iniciaisPaciente : "",
      distancia_leitura: "",
      cvp: "",
      pantoscopico: "",
      curvatura: "",
      coeficiente: "",
      estabilidade: "",
      progressao: "",
      olho_dominante: json.hasOwnProperty("parametros") && json.parametros ? json.parametros.olhoDominante : "",
    };

    if (json.hasOwnProperty("parametros") && json.parametros) {
      if (json.parametros.hasOwnProperty("olhoDireito")) {
        response.distancia_leitura = json.parametros.olhoDireito.distanciaLeitura;
        response.cvp = json.parametros.olhoDireito.cvp;
        response.pantoscopico = json.parametros.olhoDireito.anguloPantoscopico;
        response.curvatura = json.parametros.olhoDireito.anguloCurvatura;
        response.coeficiente = json.parametros.olhoDireito.coeficienteHE;
        response.estabilidade = json.parametros.olhoDireito.coeficienteST;
        response.progressao = json.parametros.olhoDireito.distanciaProgressao;
      } else if (json.parametros.hasOwnProperty("olhoEsquerdo")) {
        response.distancia_leitura = json.parametros.olhoEsquerdo.distanciaLeitura;
        response.cvp = json.parametros.olhoEsquerdo.cvp;
        response.pantoscopico = json.parametros.olhoEsquerdo.anguloPantoscopico;
        response.curvatura = json.parametros.olhoEsquerdo.anguloCurvatura;
        response.coeficiente = json.parametros.olhoEsquerdo.coeficienteHE;
        response.estabilidade = json.parametros.olhoEsquerdo.coeficienteST;
        response.progressao = json.parametros.olhoEsquerdo.distanciaProgressao;
      }
    }

    return response;
  }

  async setArmacao(json) {
    var response = null;

    var tipo_armacao = "";

    if (json.armacao) {
      switch (parseInt(json.armacao.tipo)) {
        case 1:
          tipo_armacao = "PADRAO";
          break;
        case 2:
          tipo_armacao = "METAL";
          break;
        case 3:
          tipo_armacao = "ACETATO";
          break;
        case 4:
          tipo_armacao = "PARAFUSO";
          break;
        case 5:
          tipo_armacao = "NYLON";
          break;
        case 6:
          tipo_armacao = "SEG METAL";
          break;
        case 7:
          tipo_armacao = "SEG ACETATO";
          break;
        case 8:
          tipo_armacao = "FIO DE AÇO";
          break;
        default:
          break;
      }
    }

    if (json.hasOwnProperty("armacao") && json.armacao) {

     
      let  armacao = json.armacao.marca;
      
      response = {
        tipo_armacao: json.armacao.tipo,
        modelo_armacao: json.armacao.modelo,
        referencia_armacao: json.armacao.referencia,
        observacao: json.observacao,
        vertical: json.armacao.vertical,
        horizontal: json.armacao.horizontal,
        diagonal_maior: json.armacao.diametroMaior,
        ponte: json.armacao.ponte,
        cor: json.armacao.cor,
        marca: armacao && armacao != null ? armacao : "",
      };
    }

    return response;
  }

  setFooter(json) {
    const { prazo, laboratorio_nome } = this.props;

    return {
      pedido: json.id,
      laboratorio: laboratorio_nome,
      prazo: prazo,
    };
  }

  render() {
    return (
      <>
        <ReactToPrint
          documentTitle={`Essilor Conecta - Pedido ${this.state.id}`}
          onBeforeGetContent={this.handleOnBeforeGetContent}
          trigger={this.reactToPrintTrigger}
          content={() => this.componentRef}
        />

        <div style={{ display: "none" }}>
          {this.state.order && (
            <div className="pdf-order container" ref={el => (this.componentRef = el)}>
              <PDFOrderHeader dados={this.state.header}></PDFOrderHeader>
              <PDFOrderDados dados={this.state.dados}></PDFOrderDados>
              <PDFOrderEspecificacao especificacao={this.state.especificacao}></PDFOrderEspecificacao>
              <PDFOrderPrescricao
                prescricao={this.state.prescricao}
                hasOlhoDireito={this.state.hasDireito}
                hasOlhoEsquerdo={this.state.hasEsquerdo}></PDFOrderPrescricao>
              <PDFOrderArmacao armacao={this.state.armacao}></PDFOrderArmacao>
              <PDFOrderFooter footer={this.state.footer}></PDFOrderFooter>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default PDFOrder;
