import React, { Component } from 'react'
import logobanner from './img/logo-banner-2021.png'

class SignupLeft extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="signup-left">
				<div className="signup-logo">
					<a href="/" className="">
						<img alt=""	src={logobanner} />
					</a>
				</div>
				<div className="signup-title">
					<h1>{this.props.signup_title}</h1>
				</div>
				<div className="signup-paragraph">
					<p>{this.props.signup_paragraph}</p>
				</div>
			</div>
		)
	}
}

export default SignupLeft
