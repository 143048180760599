import Swal from "sweetalert2";

function validateFields(campo, sessao, mensagenPlus) {
  let mensagens = "Preencha os campos obrigatórios para continuar.<br><br>";
  let validatedFields = [];
  let valido = true;
  let subCampo = null;

  for (const element of campo) {
    var campoRecebido = null;
    campoRecebido = document.getElementById(element.id);

    if (element?.tipo === "autoSelect") {
      subCampo = campoRecebido.querySelector(".select__single-value");
    } else if (element?.tipo === "autoSelectDIV") {
      if (campoRecebido?.tagName === "DIV") {
        subCampo = campoRecebido.querySelector('input[name="' + element.idName + '"]');
      }
    }
    const mensagem = element.mensagem;

    if (
      (Boolean(subCampo?.value) === false && Boolean(campoRecebido) === true && Boolean(subCampo) === true) ||
      (!campoRecebido?.value && campoRecebido !== null && element.tipo !== "autoSelect" && element.tipo !== "autoSelectDIV") ||
      (element.tipo === "autoSelect" &&
        Boolean(subCampo?.value) === false &&
        (campoRecebido?.textContent == undefined ||
          campoRecebido.textContent.includes("resultado encontrado") ||
          campoRecebido.textContent.includes("resultados encontrados")))
    ) {
      applyErrorStyles(campoRecebido);
      valido = false;
      mensagens += "• " + mensagem + "<br>";
      if (sessao) validatedFields.push({ element, resultado: false });
    } else if (
      campoRecebido !== null ||
      (element.tipo === "autoSelect" && campoRecebido?.textContent !== undefined) ||
      (Boolean(subCampo?.value) === true && Boolean(campoRecebido) === true)
    ) {
      validatedFields.push({ element, resultado: true });
      resetStyles(campoRecebido);
    }
  }

  //caso seja um elemento com sessao realiza uma validação especifica
  if (sessao) {
    var resultado = checkSession(validatedFields, campo, mensagenPlus);
    return resultado;
  }
  if (valido) {
    return true;
  } else {
    alert(mensagens);
    return false;
  }
}

function reset(campo) {
  for (const element of campo) {
    var campoRecebido = null;
    let subCampo = null;
    campoRecebido = document.getElementById(element.id);
   
      if (campoRecebido?.tagName === "DIV") {
        subCampo = campoRecebido.querySelector('input[name="' + element.idName + '"]');
      }
     if (
      campoRecebido !== null || (Boolean(subCampo?.value) === true && Boolean(campoRecebido) === true)
    ) {
      resetStyles(campoRecebido);
    }
  }

}

function checkSession(validatedFields, campo, mensagenPlus) {
  const sessoesPreenchidas = new Set();
  for (const element of campo) {
    if (element.session && validatedFields.some(item => item.element.id === element.id && item.resultado)) {
      sessoesPreenchidas.add(element.session);
    }
  }

  if (sessoesPreenchidas.size > 1) {
    alert(mensagenPlus.ChooseMessege);
  }
  const validatedFieldsWithoutSession = validatedFields.filter(element => !element.element?.session);
  const validatedFieldsWithSession = validatedFields.filter(element => element.element.session);
  const primeiraSessao = validatedFieldsWithSession.find(element => element.resultado);
  const sessaoAtual = [];
  const outrasSessoes = [];
  if (primeiraSessao !== undefined) {
    for (const element of validatedFields) {
      if (element?.element.session === primeiraSessao.element.session || !element?.element.session) {
        sessaoAtual.push(element);
      } else {
        outrasSessoes.push(element);
      }
      var campoRecebido = null;
      for (const element of outrasSessoes) {
        campoRecebido = document.getElementById(element.element.id);

        if (Boolean(campoRecebido) === true) resetStyles(campoRecebido);
      }
    }
    let mensagens = "<br><br>Campos obrigatórios pendentes!<br><br>";
    let valido = true;
    var campoRecebido = null;
    for (const element of sessaoAtual) {
      if (element.element.tipo === "autoSelect") {
        const elementoAutocomplete = document.getElementById(element.element.id);

        campoRecebido = elementoAutocomplete.querySelector(".select__single-value");
      } else {
        campoRecebido = document.getElementById(element.element.id);
      }

      const mensagem = element.element.mensagem;
      if (
        (!campoRecebido?.value && campoRecebido !== null && element.element.tipo !== "autoSelect") ||
        (element.element.tipo === "autoSelect" &&
          (campoRecebido?.textContent == undefined ||
            campoRecebido.textContent.includes("resultado encontrado") ||
            campoRecebido.textContent.includes("resultados encontrados")))
      ) {
        mensagens += "• " + mensagem + "<br>";
        valido = false;
      }
    }

    if (valido) {
      return true;
    } else {
      alert(mensagens);
    }
  } else {
    alert(mensagenPlus.ErroMessege);
  }
}

function applyErrorStyles(element) {
  element.style.boxShadow = "0 0 0 1px #f70017";
  element.style.borderRadius = "5px";
}

function resetStyles(element) {
  element.style.boxShadow = "";
  element.style.borderRadius = "";
}

function alert(mensagens) {
  Swal.fire({
    html: mensagens,
    icon: "warning",
    title: "Aviso",
    showCancelButton: false,
    allowOutsideClick: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Ok",
  });
}
export { validateFields, reset  };
