import React, { Component } from "react";
import "./roles-management-form.css";
import Swal from "sweetalert2";
import Input from "../../components/inputs/input";
import Form from "react-bootstrap/Form";
import CustomButton from "../../components/buttons/button";

class RolesManagementForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "Informe o CPF",
      mask: [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/],
      type: "text",
      tipo: "CPF",
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChangeBusca = this.onChangeBusca.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleChange(e) {
    const { rolesManagement } = this.props;

    const { name, value } = e.target;

    if (name === "CPF" || name === "CNPJ") {
      rolesManagement.handleRolesManagementChange({
        cnpj_consulta: value.replace(".", "").replace(".", "").replace(".", "").replace("/", "").replace("-", ""),
      });
    } else {
      rolesManagement.handleRolesManagementChange({ cnpj_consulta: value });
    }
  }

  buscarUsuarios = e => {
    e.preventDefault();
    const { rolesManagement } = this.props;

    if (rolesManagement.cnpj_consulta) {
      switch (this.state.tipo) {
        case "CPF":
          rolesManagement.buscarUsuariosPorCpf(rolesManagement.cnpj_consulta);
          break;
        case "E-mail":
          rolesManagement.buscarUsuariosPorEmail(rolesManagement.cnpj_consulta);
          break;
        case "CNPJ":
          rolesManagement.buscarUsuariosPorCnpj(rolesManagement.cnpj_consulta);
          break;
      }
    } else {
      Swal.fire({
        text: `É necessário informar um ${this.state.tipo}.`,
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
    }
  };

  setPosition(e) {
    let val = e.target.value.replace(/\D/g, "");
    if (e.type == "click") {
      if (val.length == 0) e.target.setSelectionRange(0, 0);
    }
    if (e.type == "paste") {
      let ctrl = e.target;
      if (ctrl.setSelectionRange) {
        ctrl.setSelectionRange(0, 0);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveStart("character", 0);
        range.select();
      }
    }
    if (e.type == "keypress") {
      if (val.length == 0) {
        e.target.setSelectionRange(0, 0);
        e.target.value = val;
      }
    }
  }

  onChangeBusca(evt) {
    const { rolesManagement } = this.props;
    rolesManagement.handleRolesManagementChange({ cnpj_consulta: "" });

    if (evt.target.id === "cpf") {
      this.setState({
        label: "Informe o CPF",
        mask: [/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/],
        type: "text",
        tipo: "CPF",
      });

      rolesManagement.handleRolesManagementChange({ tipo_consulta: "CPF" });
    }

    if (evt.target.id === "email") {
      this.setState({
        label: "Informe o E-mail",
        mask: "",
        type: "email",
        tipo: "E-mail",
      });

      rolesManagement.handleRolesManagementChange({ tipo_consulta: "E-mail" });
    }

    if (evt.target.id === "cnpj") {
      this.setState({
        label: "Informe o CNPJ",
        mask: [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/],
        type: "text",
        tipo: "CNPJ",
      });

      rolesManagement.handleRolesManagementChange({ tipo_consulta: "CNPJ" });
    }
  }

  onKeyDown(event) {
    const url = window.location.href;

    if (url.includes("roles")) {
      // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event

      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        this.buscarUsuarios();
      }
    }
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const url = window.location.href;
      if (url.includes("roles")) {
        this.buscarUsuarios(event);
      }
    }
  };
  render() {
    return (
      <div className="mt-4">
        <div className="row">
          <div className="col-12  d-flex justify-content-center">
            <div className="row">
              <Form>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check inline label="CPF" name="tipoBusca" type="radio" id="cpf" defaultChecked={true} onChange={this.onChangeBusca} />
                  <Form.Check inline label="E-mail" name="tipoBusca" type="radio" id="email" onChange={this.onChangeBusca} />
                  <Form.Check inline label="CNPJ" name="tipoBusca" type="radio" id="cnpj" onChange={this.onChangeBusca} />
                </div>
              </Form>
            </div>
          </div>
          <div className="col-10 mx-auto">
            <Input
              name={this.state.tipo}
              id={this.state.tipo}
              type={this.state.type}
              mask={this.state.mask}
              label={this.state.label}
              width="100%"
              aria={this.state.label}
              onChange={this.handleChange}
              value={this.props.rolesManagement.cnpj_consulta}
              onKeyPress={this.onKeyDown}
              onKeyDown={this.onKeyDown}
            />
          </div>
          <div className="col-10 mx-auto">
            <CustomButton label="Buscar" width="100%" icon_search={true} icon_arrow_left={false} icon_arrow_right={true} onClick={this.buscarUsuarios} />
          </div>
        </div>
      </div>
    );
  }
}

export default RolesManagementForm;
