import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";
import DownloadCSVAncora from "../../../components/utils/download-file/tag-a/automatic-download-user-machine/CSV";
import AppContext from "../../../appContext";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import Swal from "sweetalert2";

//Import CSS
import "./table.css";

//Import internal components
import TableBody from "./table-body";

export class tableHead extends Component {
  constructor(props) {
    super();

    this.state = {
      data_tabela: [],
      table: props.data,
      pageCount: Math.ceil(props.data?.length / 10),
      offset: 0,
      orderedColumn: null,
      ascOrder: true,
      tableAux: props.data,
      key: Date.now(),
      changed: 0,
    };
    tableHead.contextType = AppContext;
    this.paginateData = this.paginateData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.paginateData();
  }

  paginateData() {
    const dataPagination = this.state?.tableAux?.slice(this.state?.offset, this.state.offset + 10);
    this.setState(prevState => ({ data_tabela: dataPagination, changed: prevState.changed + 1 }));
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState({ offset: offset, data_tabela: [] }, this.paginateData);
  };
  orderByColumn = column => {
    const { orderedColumn, ascOrder, tableAux } = this.state;

    const ordemNova = orderedColumn === column ? !ascOrder : true;

    const dadosOrdenados = [...tableAux].sort((a, b) => {
      if (a[column] < b[column]) {
        return ordemNova ? -1 : 1;
      }
      if (a[column] > b[column]) {
        return ordemNova ? 1 : -1;
      }
      return 0;
    });

    this.setState(
      {
        offset: 0,
      },
      () => {
        const dataPagination = dadosOrdenados.slice(this.state.offset, this.state.offset + 10);
        this.setState(prevState => ({
          data_tabela: dataPagination,
          tableAux: dadosOrdenados,
          orderedColumn: column,
          ascOrder: ordemNova,
          key: prevState.key + 1,
          changed: prevState.changed + 1,
        }));
      },
    );
  };
  async getFaturaComDetalhesBoleto(faturas) {
    const resultado = [];
    const hoje = new Date();
    const quinzeDiasDepois = new Date();
    quinzeDiasDepois.setDate(hoje.getDate() + 15);
    for (const item of faturas) {
      try {
        const detalhesBoleto = await this.fetchDetalhesBoleto(item.codigo, item.nDocumento, item.dataEmissao);
        detalhesBoleto.forEach(detalhe => {
          let linha = [];
          let status = '';
          if (item.status === 'baixada') {
            status = 'pago';
          } else if (item.dataVencimento < hoje) {
            status = 'vencida';
          } else if (item.dataVencimento <= quinzeDiasDepois) {
            status = 'a vencer';
          } else {
            status = 'pendente';
          }
          linha["Número Documento"] = item.nDocumento;
          linha["Número Nota Fiscal"] = detalhe.numeroNotaFiscal;
          linha["Número Pedido"] = detalhe.idPedido;
          linha["Data Pedido"] = new Date(detalhe.dataPedido).toLocaleDateString("pt-BR");
          linha["Valor Total"] = detalhe.valorTotal;
          linha["Valor Mercadoria"] = detalhe.valorMercadoria;
          linha["Valor Serviço"] = detalhe.valorServico;
          linha["Valor Desconto"] = detalhe.valorDesconto;
          linha["Valor Desconto Serviço"] = detalhe.valorDescontoServico;
          linha["Valor Frete"] = detalhe.valorFrete;
          linha["Valor Financeiro"] = detalhe.valorFinanceiro;
          linha["Data Fechamento"] = new Date(detalhe.dataFechamento).toLocaleDateString("pt-BR");
          linha["Número Parcela"] = item.parcela;
          linha["Data Vencimento"] = new Date(item.dataVencimento).toLocaleDateString("pt-BR");
          linha["Status"] = item.status;
          resultado.push(linha);
        });
      } catch (error) {
        this.context.hideLoading();
        Swal.fire({
          text: "Não foi possível recuperar os detalhes de todas as faturas. Tente novamente ou entre em contato com o Laboratório.",
          title: "Aviso",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        return false;
      }
    }
    return resultado;
  }
  async fetchDetalhesBoleto(codigoDocumento, numeroDocumento, dataEmissao) {
    const header = {
      client_id: localStorage.getItem("clientid"),
      access_token: localStorage.getItem("token"),
      laboratorio: this.props.info.laboratorio_cnpj ? this.props.info.laboratorio_cnpj : "",
      otica: this.props.cnpj_number ? this.props.cnpj_number.replace(/[^0-9]+/g, "") : "",
    };
    const requestOptions = {
      method: "GET",
      headers: header,
    };

    this.props.FinanceiroDetalhesModalDropdown === true ? this.setState({ loading: true }) : this.context.showLoading("Gerando Ficha Financeira...");
    var status = null;

    const response = await fetch(
      process.env.REACT_APP_ESSILOR_API +
        `gestao-financeira-cliente-laboratorio/v1/faturas/${codigoDocumento}/boleto/detalhe?dataEmissao=${dataEmissao}&numeroDocumento=${numeroDocumento}`,
      requestOptions,
    );
    if (!response.ok) {
      throw new Error("Erro ao buscar detalhes do boleto");
    }
    return response.json();
  }
  handleClick = async event => {
    event.preventDefault();
    let newTableExport = await this.getFaturaComDetalhesBoleto(this.props?.APIData);
    this.setState({ table_export: newTableExport }, () => {
      if (newTableExport.length === 0 || !newTableExport) {
        return;
      }
      this.childRef.downloadCSV();
    });
    this.context.hideLoading();
  };
  render() {
    let nameFile = (this.props.cnpj_number ? this.props.cnpj_number.replace(/[^0-9]+/g, "") : "") + "_" + (this.props.info.laboratorio_cnpj ? this.props.info.laboratorio_cnpj.replace(/[^0-9]+/g, "") : "");
    const { orderedColumn, ascOrder } = this.state;

    return (
      <>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            key={this.state.key}
          />
        </div>
        <div className="divTagA">
          <DownloadCSVAncora
            download
            onClick={event => this.handleClick(event)}
            data={this.state.table_export}
            linkName={"Exportar Lista Completa (Ficha Financeira)"}
            fileName={"FichaFinanceira_" + nameFile}
            ref={ref => (this.childRef = ref)}
          />
        </div>
        <div className="continer-financeiro-table">
          {this.state.data_tabela && (
            <Table className="financeiro-faturas-table table text-center">
              <thead className="financeiro-faturas-table-head">
                <tr>
									<th className="financeiro-faturas-table-head-th"></th>
                  {this.props.headers.map((header, index) => (
                    <th className="financeiro-faturas-table-head-th" key={index} onClick={() => this.orderByColumn(header)}>
                      <div className="div-th-header">
                        <div className="table-line-pointer div-th-header">
													<div className="financeiro-faturas-head-th-text">{header}</div>
													<div>{ascOrder && orderedColumn === header ? <BsArrowUpShort size={20} /> : <BsArrowDownShort size={20} />}</div>
												</div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              {this.props.data && (
                <TableBody
                  tablePaginate={this.state.data_tabela}
                  table={this.props.data}
                  info={this.props.info}
                  cnpj_number={this.props.cnpj_number}
                  paginate={this.state.offset}
                  changed={this.state.changed}
                />
              )}
            </Table>
          )}
        </div>
      </>
    );
  }
}

export default tableHead;
