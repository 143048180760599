import React, { Component } from 'react'

//Import CSS
import '../../../signup/signup.css'

//Import internal components

//Import external components

//Import images


class TermosCompra extends Component {

  	render(){
    	return (
            <div>
                <h3 className='signup-popup__title'>Termos e Condições para Compra de Produtos Essilor</h3>
                <div className='signup-popup__text'>
                    <div className='signup-popup__paragraph'>
                        <h3>1. Preâmbulo e Pedidos de Compra</h3>
                        <p>
                            1.1 Todos os pedidos de compra de Produtos formalizados pelo Comprador junto ao Vendedor serão regidos por estes Termos e Condições de Venda (“Acordo”). Ao formalizar qualquer pedido de compra de Produtos junto ao Vendedor, o Comprador confirma que leu, compreendeu e aceitou todos os termos e condições deste Acordo, que concedem o direito de comprar e revender, de forma não exclusiva, os Produtos descritos na NF-e.
                        </p>
                        <p>
                            1.2 A compra pelo Comprador do Vendedor de Produtos no âmbito deste Acordo estará sujeita à colocação pelo Comprador de um pedido de compra ("Pedido") que deverá conter, no mínimo, as seguintes informações: (i) dados cadastrais do Comprador; (ii) a quantidade de Produtos que deseja comprar; (iii) o local de entrega dos Produtos (estabelecimento do Comprador ou Vendedor, conforme o caso); (iv) a data do Pedido; e (v) quando aplicável, as medidas da armação de óculos do usuário necessárias para eventual montagem e, ainda, informações relacionadas às prescrições médicas para eventual surfaçagem dos Produtos.
                        </p>
                        <p>
                            1.3 Os Pedidos formalizados pelo Comprador estarão sujeitos à prévia apreciação pelo Vendedor, que poderá a seu exclusivo critério, recusá-los, incluindo, sem limitação, nas seguintes hipóteses: (i) se não estiverem atualizados, corretos e aprovados os dados cadastrais do Comprador, bem como, se a sua inscrição estadual ou inscrição junto à Receita Federal do Brasil não estiver regular; (ii) se o Comprador possuir débitos vencidos em aberto em face do Vendedor ou de qualquer sociedade do seu grupo econômico; (iii) se for verificado a existência de erros ou outras omissões nas informações constantes do Pedido formalizado pelo Comprador; (iv) se não houver disponibilidade de estoque, e/ou (v) se o Comprador superar o limite de crédito eventualmente concedido pelo Vendedor, considerando sempre a somatória de eventuais débitos em aberto mais a somatória dos pedidos.
                        </p>
                        <p>
                            1.4 O Comprador declara saber que não terá direito de utilizar quaisquer marcas de titularidade do Vendedor ou seu grupo econômico em seu nome empresarial, comercial ou em qualquer nome de domínio (URL).
                        </p>
                        <p>
                            1.5 Sem prejuízo das demais obrigações e responsabilidades previstas neste Acordo, o Comprador deverá: (i) manter número adequado de pessoas que detenham experiência e sejam qualificadas para vender, distribuir e promover os Produtos; (ii) observar e cumprir todas as exigências legais e regulamentares relacionadas com a aquisição, promoção, venda e estocagem dos Produtos; e (iii) repassar aos seus clientes todas as recomendações do Vendedor quanto aos cuidados de uso, manipulação e conservação inerentes aos Produtos.
                        </p>
                        <p>
                            1.6 O Vendedor oferecerá garantia aos Produtos comprados de acordo com a declaração de garantia vinculada a cada Produto (vide regras constantes do manual do SAC do Vendedor disponível no site <a href="www.proessilor.com.br">www.proessilor.com.br</a>), sendo que os prazos das garantias começarão a vigorar a partir da data da emissão da respectiva NF-e.
                        </p>

                        <h3>2. Entrega</h3>

                        <p>
                            2.1 O local de entrega dos Produtos Essilor deve ser convencionado pelas Partes no Pedido e respectiva NF-e, respeitadas eventuais exigências dos locais de entrega, bem como da legislação brasileira. Uma vez que os Produtos tenham sido entregues no local de entrega, o Comprador, a partir deste momento, será única e exclusivamente responsável por todos os riscos e custos associados aos Produtos, isentando o Vendedor de qualquer responsabilidade.
                        </p>
                        <p>
                            2.2 O Vendedor irá programar a entregas dos Pedidos ao Comprador com base na disponibilidade em estoque.
                        </p>
                        <p>
                            2.3 É de responsabilidade exclusiva do Comprador: (i) inspecionar todos os Produtos a fim de verificar a existência de eventual defeito no momento da entrega; e (ii) conferir os Produtos por contagem física com base na nota fiscal que os acompanha quando da entrega.
                        </p>
                        <p>
                            2.4 Não serão admitidas pelo Vendedor reclamações do Comprador que não tenham sido consignadas expressamente no recibo de entrega. Percebendo algum defeito, o Comprador poderá rejeitar quaisquer dos Produtos defeituosos, devendo, para tanto, enviar uma notificação ao Vendedor no prazo de até 24 (vinte e quatro) horas úteis, contadas da data da entrega, detalhando os fundamentos dessa rejeição. O não envio da notificação no prazo aqui previsto implicará na aceitação dos Produtos, ficando o Comprador impedido de reclamar de eventuais defeitos em momento posterior. O Vendedor terá 30 (trinta) dias contados a partir do recebimento de qualquer notificação enviada nos termos deste item para apresentar sua reposta à notificação apresentada pelo Comprador.
                        </p>
                        <p>
                            2.5 No caso de reclamações realizadas por clientes do Comprador, este último se obriga a encaminhar ao Vendedor o Produto objeto da reclamação para verificação e comprovação do alegado defeito. Apenas se for comprovado que (i) o defeito decorreu de ação ou omissão comprovadamente atribuível ao Vendedor e que (ii) não era possível ao Comprador identificar o defeito quando da inspeção mencionada no item acima, o Vendedor realizará a troca do Produto junto ao Comprador. Caso não sejam comprovados os pontos acima listados, o Comprador assumirá todos os ônus decorrentes da troca aos seus clientes, isentando o Vendedor de toda e qualquer responsabilidade.
                        </p>
                        <p>
                            2.6 O atraso na entrega ou entregas parciais de Produtos pelo Vendedor não será motivo para o Comprador recusar a entrega, tampouco para gerar cancelamento da NF-e.
                        </p>
                        <p>
                            2.7 O Comprador será responsável por defeitos ou danos resultantes ou ocorridos nos Produtos a partir do local de entrega, durante operação de transporte que esteja sob a sua responsabilidade e ocorridos durante estocagem ou uso inadequado dos Produtos enquanto os mesmos estiverem na posse do Comprador, de seus contratados. O Comprador não poderá comercializar Produtos defeituosos ou danificados em razão de operação de transporte sob sua responsabilidade e ocorridos durante estocagem ou uso inadequado dos Produtos enquanto os mesmos estiverem em sua posse, de seus contratados, sob pena de responsabilizar-se integralmente por eventuais danos causados ao Vendedor, a terceiros, ou por eventuais penalidades aplicadas pelos órgãos competentes.
                        </p>
                        <p>
                            2.8 O Comprador armazenará todos os Produtos recebidos do Vendedor em condições adequadas que evitem sua deterioração, perecimento, desgaste ou contaminação.
                        </p>
                        <p>
                            2.9 Caso o Comprador decida cancelar uma determinada NF-e antes da efetiva entrega ou retirada dos Produtos, o Vendedor cancelará o Pedido e cobrará do Comprador o valor total desses Produtos fabricados e não fornecidos. O Vendedor só não cobrará o valor da NF-e do Comprador caso os Produtos demandados pelo Comprador ainda não tenham sido efetivamente submetidos á qualquer etapa de fabricação no estabelecimento do Vendedor.
                        </p>
                        <p>
                            2.10 O Vendedor poderá decidir livremente acerca da descontinuação e/ou alteração de qualquer Produto oferecido no âmbito deste Acordo, independentemente do envio de qualquer notificação ao Comprador.
                        </p>

                        <h3>3. Preços e Condições de Pagamento</h3>

                        <p>
                            3.1 Os preços e condições de pagamento serão aqueles indicados na respectiva NF-e. Todos os pagamentos deverão ser feitos pelo Comprador ao Vendedor em Reais. Sobre os pagamentos atrasados incidirá multa de 2% (dois por cento), juros de 1,5% ao mês e atualização monetária pelo IGPM/FGV. Se o Comprador atrasar o pagamento de qualquer fatura o Vendedor poderá, a seu exclusivo critério, suspender o fornecimento dos Produtos ou exigir do Comprador a apresentação de garantias, pagamento antecipado para Pedidos futuros e/ou, ainda, compensar os valores devidos pelo Comprador contra eventuais créditos detidos pelo Comprador em face do Vendedor, sendo que o Comprador desde já autoriza a compensação dos referidos valores.
                        </p>
                        <p>
                            3.2 Eventual tolerância do Vendedor no recebimento de qualquer prestação após o seu vencimento não importará, jamais, em renúncia ou desistência às sanções asseguradas neste Acordo ou marcadas na legislação, tampouco em novação de débito.
                        </p>
                        <p>
                            3.3 Se o Vendedor, para haver seu crédito, for obrigado a recorrer aos meios judiciais ou extrajudiciais, obriga-se, ainda, o Comprador a pagar àquele todas as despesas decorrentes da cobrança, sejam elas administrativas ou judiciais e, ainda, à custa e despesas judiciais que venham a ser despendidas, bem como os honorários advocatícios de 20% (vinte por cento) sobre o valor total do débito pendente de pagamento e os honorários advocatícios contratuais
                        </p>
                        <p>
                            3.4 Cada Parte deverá arcar com o pagamento dos tributos de sua responsabilidade, nos termos da legislação aplicável.
                        </p>

                        <h3>4. Força Maior</h3>

                        <p>
                            4.1 O Vendedor tem o direito de cancelar Pedidos ou suspender a entrega de produtos, e não deverá ser responsabilizado por qualquer não entrega, entrega com defeito ou atraso que, parcialmente ou totalmente, for causada por circunstâncias alheias ao seu controle, incluindo, entre outros, motins, agitação civil, guerra, terrorismo, fogo, insurreição, requisição, apreensão, embargo ou defeitos ou atrasos nas entregas por subempreiteiros, greves, bloqueios, lentidões, falta de transporte, escassez de materiais, doenças causadas por acidentes nos testes dos produtos e suprimentos insuficientes de energia. Quaisquer dos direitos do Comprador serão suspensos ou anulados em tais circunstâncias previstas nesta cláusula. O Comprador não tem direito a qualquer tipo de indenização ou de fazer um pedido de indenização qualquer, em caso de cancelamento ou atraso na entrega devido a tais circunstâncias.
                        </p>

                        <h3>5. Confidencialidade</h3>

                        <p>
                            5.1 As Partes deverão manter estrita confidencialidade e não divulgar qualquer informação relacionada à outra Parte, este Acordo, Pedidos formalizados, preços praticados, descontos concedidos, métodos de fabricação e fornecimento e comercialização, níveis de serviço ou qualquer outra informação, documento, instrumento jurídico, modelo econômico, planilha etc, sem o prévio e expresso consentimento da outra Parte.
                        </p>

                        <h3>6. Compliance</h3>

                        <p>
                            6.1 Para fins da execução das obrigações estabelecidas neste Acordo, cada Parte obriga-se, por si e por seus representantes legais, empregados, colaboradores e eventuais subcontratados contratados para fins deste instrumento, a cumprir integralmente: (i) todas as leis e regulamentações aplicáveis ao desenvolvimento das suas atividades sociais, incluindo, sem limitação, a legislação de defesa da concorrência; (ii) todas as demais autorizações, permissões e licenças exigidas para a operação regular de sua sociedade/filiais e o desempenho de seus negócios, bem como mantê-las válidas durante a vigência deste instrumento; e (iii) todas as leis e regulamentos anticorrupção, anti-lavagem de dinheiro, restrições de comércio internacional (economic sanctions) aplicáveis ao Grupo Essilor.
                        </p>

                        <h3>7. Independência entre as Partes</h3>

                        <p>
                            7.1 As Partes reconhecem que este Acordo não constitui nem poderá ser interpretado como se constituísse relação de mandato, sociedade, joint venture, associação, agência, representação comercial, vínculo de emprego ou qualquer outra relação de dependência entre as Partes, seus sócios, administradores, empregados, prepostos e contratados. As Partes reconhecem que este Acordo não cria relacionamento ou vínculo societário algum entre elas que não o simples fornecimento dos Produtos aqui previstos.
                        </p>

                        <h3>8. Disposições Gerais</h3>

                        <p>
                            8.1 O Acordo obriga as Partes e seus sucessores e cessionários a qualquer título.
                        </p>
                        <p>
                            8.2 O não exercício de seus direitos e obrigações contratuais ou a tolerância do não cumprimento da outra Parte de qualquer Cláusula, obrigação deste Acordo não poderá ser interpretado como novação ou renúncia não prejudicando a sua invocação futura.
                        </p>
                        <p>
                            8.3 O Comprador será responsável por eventuais violações às leis brasileiras e danos eventualmente causados à terceiros em razão, incluindo, mas sem limitação: (i) de descumprimento das normas de proteção e defesa do consumidor; (ii) descumprimento de campanhas promocionais; (iii) utilização de material promocional do Vendedor, de forma não autorizada pelo Vendedor; e (iv) violação referente a condutas de venda, assistência, garantia e obrigação de prestar informações referentes aos Produtos.
                        </p>

                        <h3>9. Jurisdição e Foro</h3>

                        <p>
                            9.1 Este Acordo será regido e interpretado de acordo com as leis do Brasil. Todas as disputas decorrentes deste Acordo serão resolvidas na comarca da Capital do Estado do Rio de Janeiro.
                        </p>
                    </div>
                </div>
            </div>

    	);
  	}
}

export default TermosCompra;