import Swal from "sweetalert2";

export const getMultiContas = cnpj => {
	const myHeaders = new Headers({
		Authorization: "Bearer " + localStorage.getItem("session_token"),
	});

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			cnpjPrincipal: cnpj,
		}),
	};

	return fetch(process.env.REACT_APP_LABS_API + "get_multi_conta_user.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		});
};

export const createMultiContas = body => {
	const myHeaders = new Headers({
		Authorization: "Bearer " + localStorage.getItem("session_token"),
	});

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			email: body.email,
			cnpj: body.cnpj,
			papel: body.papel,
			idUsuario: body.idUsuario,
		}),
	};

	return fetch(process.env.REACT_APP_LABS_API + "create_multi_conta_user.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		});
};

export const disableMultiContas = id => {
	const myHeaders = new Headers({
		Authorization: "Bearer " + localStorage.getItem("session_token"),
	});

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify({
			idMultiContas: id,
		}),
	};

	return fetch(process.env.REACT_APP_LABS_API + "delete_multi_conta_user.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		});
};

export const updateRole = (id, removerCnpj, tipo_cnpj, cnpj, callback, callbackError) => {
	const requestOptions = {
		method: "POST",
		headers: new Headers({
			Authorization: "Bearer " + localStorage.getItem("session_token"),
		}),
		body: JSON.stringify({
			idOEC: id,
			removerCnpj: removerCnpj,
			tipoCnpj: tipo_cnpj,
			cnpj: cnpj ? cnpj : this.context.userSession.conta,
		}),
	};

	fetch(process.env.REACT_APP_LABS_API + "update_user_role.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			if (json.status === 200) {
				callback();
			} else {
				callbackError();
				Swal.fire({
					text: json.message,
					icon: "error",
					title: "Tivemos um problema!",
					allowOutsideClick: false,
					showCancelButton: false,
					confirmButtonColor: "#00518b",
					confirmButtonText: "Ok",
				}).then();
			}
		});
};

export const convertJWT = token => {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
};

export const updateToken = (conta, session_token) => {
	const requestOptions = {
		method: "POST",
		body: JSON.stringify({ conta: conta }),
		headers: new Headers({
			Authorization: "Bearer " + session_token,
		}),
	};

	return fetch(process.env.REACT_APP_LABS_API + "user_auth_update.php", requestOptions)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		});
};
