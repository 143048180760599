import React, { Component } from "react";
import "./reset.css";
import Alert from "sweetalert2";
import AppContext from "../appContext";
import Password from "../components/inputs/password";
import { withRouter, Redirect   } from "react-router-dom";
import Banner from "./banner";
import Swal from "sweetalert2";

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: "reset-top",
      loading: false,
      redirectH: false,
      newPassword: "",
      newPassword2: "",
      showError: false,
      errorHeight: "0px",
      passwordIsConfirm: true,
      passwordRoles: false,
      token: "",
      updatedPassword: false,
    };

    NewPassword.contextType = AppContext;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
    this.setBorder = this.setBorder.bind(this);
  }
  setBorder(e) {
    const { id } = e.target;
    document.getElementById(id).style.borderColor = "red";
  }
  handleChangeEmailStorage(newPassword) {
    this.setState({ newPassword: newPassword });
  }

  componentDidMount() {
    this.context.showLoading("Validando dados...");
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    let token = queryParams.get("token");
    this.setState({ token });
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        token: token,
        return:false
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "token/userTokens.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.context.hideLoading();
        if (json.status != 200) {
          // se exipirado
          Swal.fire({
            html: "Seu token de redefinição de senha expirou. Para continuar, por favor, preencha o formulário de redefinição de senha novamente e reinicie o processo.",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(result => {
            this.props.history.push("/resetpassword");
          });
        }
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    let result =  this.validatePassword(this.state.newPassword)
    if(!result)return
    this.context.showLoading();
    const requestBody = {
      return: true,
      tokenPassword: this.state.token,
      password: this.state.newPassword,
    };
   

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    fetch(process.env.REACT_APP_LABS_API + "resetPassword/mePasswordResetter.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.context.hideLoading();
        if (json.status == 200) {
          this.setState({ updatedPassword: true });
        } else if (json.status == 403) {
          Swal.fire({
            html: "Seu token de redefinição de senha expirou. Para continuar, por favor, preencha o formulário de redefinição de senha novamente e reinicie o processo.",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(result => {
            this.props.history.push("/resetpassword");
          });
        } else {
          Swal.fire({
            html: "Erro interno.",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          }).then(result => {
            this.props.history.push("/");
          });
        }
      });
  }
  validatePassword(password) {
    const minLength = 5;
    const hasNumber = /\d/;
    const noWhitespace = /^\S*$/;

    return (password?.length >= minLength && !password.includes("%") && hasNumber.test(password) && noWhitespace.test(password));
  }

   handleChangeConfirmPassword(e) {
    const { name, value, id } = e.target;
    this.setState({ [name]: value }, () => {
      if (this.validatePassword(this.state.newPassword) && this.state.newPassword === this.state.newPassword2) {
        this.setState({ showError: false, errorHeight: "0px" });
      } else {
        this.setState({ showError: true, errorHeight: "50px" });
      }

      if ((this.state.newPassword !== this.state.newPassword2) || !this.validatePassword(this.state.newPassword)) {
        this.setState({ passwordIsConfirm: false });
        document.getElementById("newPassword").style.borderColor = "red";
        document.getElementById("newPassword").style.marginBottom = "0px";
        document.getElementById("newPassword2").style.borderColor = "red";
        document.getElementById("newPassword2").style.marginBottom = "0px";
      } else {
        this.setState({ passwordIsConfirm: true });
        document.getElementById("newPassword").style.borderColor = "#B5B5B5";
        document.getElementById("newPassword").style.marginBottom = "10px";
        document.getElementById("newPassword2").style.borderColor = "#B5B5B5";
        document.getElementById("newPassword2").style.marginBottom = "10px";
      }
    });
  }
  goHome(){
    this.props.history.push("/");
  }
  render() {
    return (
      <main class="container login-main">
      <section>
        <div className={this.state.opacity}>
          <Banner></Banner>
          {!this.state.updatedPassword && (
            <div className="reset-signin js--reset-mobile">
              <div className="reset-signin__container js--padlock" id="reset-padlock">
                <div className="reset-signin__padlock"></div>

                <p className="newPassword__title">Redefina a senha</p>
                <p className="newPassword-signin__text">Informe a nova senha de acesso.</p>
                <form className="reset-signin__form js--reset-form" onSubmit={this.handleSubmit}>
                  <div className="reset-signin__form-row">
                    <Password
                      label="Senha*"
                      width="100%"
                      name="newPassword"
                      id="newPassword"
                      required={true}
                      type="password"
                      value={this.state.newPassword}
                      onInvalid={this.setBorder}
                      onChange={this.handleChangeConfirmPassword}
                      onInput={this.handleChangeConfirmPassword}
                    />
                  </div>
                  <div className="reset-signin__form-row">
                    <Password
                      label="Confirmação de Senha*"
                      width="100%"
                      name="newPassword2"
                      id="newPassword2"
                      required={true}
                      type="password"
                      value={this.state.newPassword2}
                      onInvalid={this.setBorder}
                      onChange={this.handleChangeConfirmPassword}
                      onInput={this.handleChangeConfirmPassword}
                    />
                    {!this.state.passwordIsConfirm && <small style={{ color: "red", fontSize: 12 }}>As senhas não conferem.</small>}

                    {this.state.showError && (
                        <p className="newPassword-text">
                          <ul className="ul-dot">
                            <li className="p-check-new-password">A senha não pode conter o caractere especial %</li>
                            <li className="p-check-new-password">A senha deve ter um mínimo de 5 caracteres</li>
                            <li className="p-check-new-password">A senha deve ter no mínimo 1 caractere numérico</li>
                            <li className="p-check-new-password">A senha não pode conter espaços em branco</li>
                          </ul>
                        </p>
                    )} <button
                    className="reset-signin__form-button js--enviarReset"
                    type="submit"
                    title="Enviar"
                    id="btn_enviarNewPassaword"
                    onClick={this.handleSubmit}>
                    Redefinir Senha
                  </button>
                  </div>
                 
                </form>
              </div>
              <div className="reset-signin__container js--email is--disable" id="reset-confirmation">
                <div className="reset-signin__email"></div>
                <button className="reset-signin__form-button" onClick={() => this.goHome()}>
                  Voltar
                </button>
              </div>
            </div>
          )}
          {this.state.updatedPassword && (
            <div className="reset-signin js--reset-mobile">
              <div className="reset-signin__container js--padlock" id="reset-padlock-success">
                <div className="reset-signin__padlock"></div>
                <p className="newPassword-signin__text">Sua senha foi redefinida.</p>
              </div>
              <button className="reset-signin__form-button" onClick={() => this.goHome()}>
                Continue para acessar
              </button>
            </div>
          )}
        </div>
      </section>
      </main>
    );
  }
}

export default NewPassword;
