import React, { Component } from "react";

//Import CSS
import "./tracking.css";

//Import internal components
import AppContext from "../appContext";
import Back from "../main/back";
import TrackingLabChoose from "./tracking-inputs/tracking-labchoose";
import TrackingInputDate from "./tracking-inputs/tracking-input-date";
import TrackingInputCNPJ from "./tracking-inputs/tracking-input-cnpj";
import TrackingSearchButton from "./tracking-inputs/tracking-search-button";
import TrackingTableHead from "./tracking-table/tracking-table-head";
import Input from "../components/inputs/input";
import ResumoLateral from "../components/resumo-lateral";
//Import external components
import { Redirect } from "react-router-dom";
import Moment from "moment";
import Swal from "sweetalert2";
import { redirectPage } from "../components/utils/redirectpage";
import pedidoslogo from "../web-ordering/img/pedidos-logo.jpg";
import { getRedes } from "../Functions/redes/getRedes";

//Import images

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laboratorio_nome: "",
      laboratorio_cnpj: "",
      pedido_laboratorio: "",
      pedido_otica: "",
      cnpj_number: "",
      //seta data inicio que mostra no input date para 30 dias atrás
      dia_inicio: new Date().setDate(new Date().getDate() - 30),
      //seta data fim que mostra no input date para hoje
      dia_fim: new Date(),
      //seta data inicio da integração para 30 dias atrás no formato correto
      data_inicio: Moment(new Date().setDate(new Date().getDate() - 30)).format("YYYY-MM-DD"),
      //seta data fim da integração para hoje no formato correto
      data_fim: Moment(new Date()).format("YYYY-MM-DD"),
      resposta_tabela: false,
      loading: false,
      classOpacity: "Nopacity",
      redirectL: false,
      result100: false,
      result404: false,
      result504: false,
      campoCnpjLiberado: false,
      redes: [],
      redeOticaAux: true,
      detailOrder: false,
      open: "",
      arrow: "",
      lab: null,
    };

    if (localStorage.getItem("token") !== null) {
      Tracking.contextType = AppContext;
      this.setLabData = this.setLabData.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChange_data_inicio = this.handleChange_data_inicio.bind(this);
      this.handleChange_data_fim = this.handleChange_data_fim.bind(this);
      this.changeState = this.changeState.bind(this);
      this.integraPedido = this.integraPedido.bind(this);
      this.openSummary = this.openSummary.bind(this);

      const role = Tracking.contextType._currentValue.userSession.modulos.find(element => element === "Tracking");
      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }

  componentDidMount() {
    redirectPage(this.context.userSession, "/tracking");
    if (localStorage.getItem("token")) {
      if (this.context.userSession.redeOticas) {
        this.setState({ redeOticaAux: false });
      }

      // integração no OEC para ver todos os papéis que tem marcação de multiplas óticas
      const papeis = [];
      const requestOptions = {
        method: "GET",
      };

      fetch(process.env.REACT_APP_LABS_API + "get_multioptics_roles.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.status === "sucesso") {
            if (json.items) {
              for (var i = 0; i < json.items.length; i++) {
                var papel = {
                  nome: json.items[i].recordName,
                };
                papeis.push(papel);
              }
            }
            this.setState({ listaPapeis: papeis });

            var user_role = this.context.userSession.papel;
            var roles = JSON.stringify(this.state.listaPapeis);

            // compara os papéis de multiplas oticas do OEC com o papel do usuário logado, se ele estiver na lista, o campo CNPJ aparece na tela
            if (roles && roles.match(user_role)) {
              this.setState({
                input_cnpj: true,
              });
            }
          }
        });
    } else {
      this.setState({ redirectL: true });
    }
  }

  borderColor(idElement, color = "#f70017") {
    //#f70017  #B5B5B5
    if (idElement) {
      if (typeof idElement === "object") {
        idElement.map(el => {
          if (document.getElementById(el)) {
            return (document.getElementById(el).style.boxShadow = `0 0 0 1px ${color}`);
          }
          return null;
        });
      } else {
        document.getElementById(idElement).style.boxShadow = `0 0 0 1px ${color}`;
      }
    }
  }

  setLabData(e, label, detailOrder, params) {
    if (e.value) {
      if (this.context.userSession.papelCategoria === "LABORATORIO") {
        if (e.value === this.context.userSession.conta) {
          this.setState({ campoCnpjLiberado: true, cnpj_number: this.state.cnpj_number_aux });
        } else {
          this.setState({ campoCnpjLiberado: false, cnpj_number_aux: this.state.cnpj_number, cnpj_number: "" });
        }
      } else {
        this.setState({ campoCnpjLiberado: true });
      }

      if (this.context.userSession.redeOticas) {
        this.setState({ redeOticaAux: false });
        this.listarRedesOticas(e.value);
      }

      this.setState({
        lab: params,
      });
      this.setState({
        laboratorio_nome: label,
        laboratorio_cnpj: e.value,
        detailOrder: detailOrder,
      });
      this.borderColor("lab", "#B5B5B5");
    } else {
      this.setState({
        laboratorio_nome: "",
        laboratorio_cnpj: "",
        detailOrder: false,
        lab: null,
      });
    }
  }

  obterOtica(laboratorio) {
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions1)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (this.context.userSession.redeOticas) {
          this.setState({ oticaPrincipal: json.nome, cnpjPrincipal: json.cnpj }, this.listarRedesOticas(laboratorio));
        } else {
          const redes = [];
          var rede = {
            label: json.nome,
            value: json.cnpj,
          };
          redes.push(rede);
          this.setState({ redes: redes, cnpjPrincipal: json.cnpj });
        }
      });
  }

  handleChange(e) {
    let state = {};
    const name = e.target.getAttribute("name");
    const { value } = e.target;

    //valida o formato do numero do pedido laboratorio
    //pedido otica pode ser qualquer coisa, então não tem validação
    if (e.target.getAttribute("name") === "pedido_laboratorio") {
      if (!Number(value) && value !== "") {
        return;
      }
    }
    //seta o numero do pedido no state
    state[name] = value ? value : "";
    this.setState(state);
    this.borderColor(name, "#B5B5B5");
  }

  handleChange_data_inicio = date => {
    //trata o formato da data
    const diaInicio = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_inicio: diaInicio,
      //seta data para exibir no input date
      dia_inicio: date,
    });
  };

  handleChange_data_fim = date => {
    //trata o formato da data
    const diaFim = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_fim: diaFim,
      //seta data para exibir no input date
      dia_fim: date,
    });
  };

  changeState() {
    this.borderColor(["lab", "cnpj_number"], "#B5B5B5");
    this.borderColor(["data_inicio", "data_fim", "pedido_otica"], "#FFF");
    this.setState({
      loading: true,
      data_tabela: [],
      resposta_tabela: false,
      classOpacity: "Opacity",
    });
    this.context.showLoading("Buscando pedidos...");
    this.integraPedido();
  }

  openSummary(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.open) {
        this.setState({ open: "", arrow: "" });
      } else {
        this.setState({ open: "show-summary", arrow: "active-arrow" });
      }
    });
  }

  checkHealth() {
    const requestOptions = {
      method: "GET",
      headers: {
        laboratorio: this.state.laboratorio_cnpj,
      },
    };

    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/health", requestOptions).then(response => {
      this.setState({ statusProduct: response.status }, () => {
        fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/health", requestOptions).then(response => {
          this.setState({ statusOrder: response.status }, async () => {
            if (this.state.statusProduct === 200 || this.state.statusOrder === 200) {
              return true;
            } else {
              this.context.hideLoading();
              Swal.fire({
                html: "O serviço está temporariamente indisponível para este laboratório.<br /> O time do Essilor Conecta já está atuando para resolver o problema.",
                width: "auto",
                icon: "warning",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              }).then(result => {
                if (result.value) {
                  this.setState({
                    redirect: false,
                  });
                }
              });

              return false;
            }
          });
        });
      });
    });
  }

  integraPedido() {
    var requestOptions = {};

    if (this.state.campoCnpjLiberado && this.state.cnpj_number) {
      requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          access_token: localStorage.getItem("token"),
          laboratorio: this.state.laboratorio_cnpj,
          otica: this.state.cnpj_number ? this.state.cnpj_number.replace(".", "").replace(".", "").replace("-", "").replace("/", "") : "",
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          access_token: localStorage.getItem("token"),
          laboratorio: this.state.laboratorio_cnpj,
        },
      };
    }

    //Puxando a autenticação direto da API Sensedia
    let urlParams = "";

    if (this.state.pedido_laboratorio) {
      urlParams = "id=" + this.state.pedido_laboratorio;
    }

    if (this.state.pedido_otica !== "" && urlParams === "") {
      urlParams = "idPedidoOtica=" + this.state.pedido_otica;
    } else if (this.state.pedido_otica !== "" && urlParams !== "") {
      urlParams = urlParams + "&idPedidoOtica=" + this.state.pedido_otica;
    }

    if ((this.state.data_inicio !== "" || this.state.data_fim !== "") && urlParams === "") {
      urlParams = "dataInicio=" + this.state.data_inicio + "&dataFim=" + this.state.data_fim;
    } else if ((this.state.data_inicio !== "" || this.state.data_fim !== "") && urlParams !== "") {
      urlParams = urlParams + "&dataInicio=" + this.state.data_inicio + "&dataFim=" + this.state.data_fim;
    }

    if (!this.state.laboratorio_nome) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione um laboratório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("lab");
      return;
    } else if (this.context.userSession.redeOticas && this.state.redeOticaAux && !this.state.cnpj_number) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione uma ótica.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.state.input_cnpj && this.state.redeOticaAux && !this.state.cnpj_number && !this.context.userSession.redeOticas) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "O campo CNPJ é obrigatório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (
      this.state.laboratorio_nome !== "" &&
      this.state.pedido_otica === "" &&
      this.state.pedido_laboratorio === "" &&
      this.state.data_inicio === "" &&
      this.state.data_fim === ""
    ) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Preencha um campo de filtragem.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["cnpj_number", "lab"]);
      return;
    } else if (this.state.pedido_otica !== "" && this.state.pedido_laboratorio !== "") {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Redefina a busca preenchendo o Pedido Laboratório OU Pedido Ótica",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["pedido_laboratorio", "pedido_otica"]);
      return;
    }

    if (this.state.data_inicio === "" || this.state.data_fim === "") {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: " Por favor, informe um período válido para o seu pedido.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else if (this.state.data_inicio > this.state.data_fim) {
      this.setState({
        loading: false,
        classOpacity: "Nopacity",
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Período da busca inválido: Data inicial maior que data final.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else {
      let ini = new Date(this.state.data_inicio);
      let fim = new Date(this.state.data_fim);
      let diff = (fim.getTime() - ini.getTime()) / 1000 / 3600 / 24;
      if (diff > 60) {
        this.setState({
          loading: false,
          classOpacity: "Nopacity",
        });
        this.context.hideLoading();
        Swal.fire({
          text: "O período de busca não pode ser superior a 60 dias.",
          title: "Aviso",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        this.borderColor(["data_inicio", "data_fim"]);
        return;
      }
    }

    // fetch(
    // 	'https://api.essilor.com.br/sandbox/gestao-pedidos-laboratorio/v1/pedidos?' +
    // 	urlParams,
    // 	requestOptions,
    // )
    var resp = "";
    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pedidos?" + urlParams, requestOptions)
      .then(response => {
        resp = response;
        switch (response.status) {
          case 206:
            this.setState({ result100: true });
            break;
          case 404:
            this.setState({ result404: true });
            break;
          case 504:
            this.setState({ result504: true });
            break;
          default:
        }
        return response.json();
      })
      .then(json => {
        var mensagem;
        if ((json.length > 0 && json[0].codigo) || resp.status >= 400) {
          if (this.state.result404) {
            this.context.hideLoading();
            Swal.fire({
              text: "O CNPJ da ótica não foi encontrado.",
              icon: "warning",
              title: "Aviso",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok!",
            }).then(result => {
              if (result.value) {
                this.setState({
                  redirect: false,
                });
                this.borderColor("cnpj_number");
              }
            });
          } else if (this.state.result504) {
            if (this.checkHealth()) {
              Swal.fire({
                text: "Esta requisição está demorando mais do que gostaríamos. Tente refinar sua busca, informando o número do pedido ou um intervalo menor de tempo.",
                icon: "warning",
                title: "Aviso",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok!",
              }).then(result => {
                if (result.value) {
                  this.setState({
                    redirect: false,
                  });
                }
              });
            }
          } else {
            mensagem = json[0] ? json[0].mensagem : json.mensagem;
            var codAux = json[0] ? json[0].codigo : json.codigo;
            switch (codAux) {
              case "401":
                mensagem = "Sessão expirada.";
                Swal.fire({
                  text: mensagem,
                  icon: "warning",
                  title: "Aviso",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Ok!",
                }).then(result => {
                  if (result.value) {
                    this.setState({
                      redirectL: true,
                    });
                  }
                });
                break;
              case "500":
                Swal.fire({
                  text: "Tivemos uma falha momentânea de comunicação com o laboratório.",
                  icon: "warning",
                  title: "Aviso",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Ok!",
                }).then(result => {
                  if (result.value) {
                    this.setState({
                      redirect: false,
                    });
                  }
                });
                break;
              case "504":
                if (this.checkHealth()) {
                  Swal.fire({
                    text: "Esta requisição está demorando mais do que gostaríamos. Tente refinar sua busca, informando o número do pedido ou um intervalo menor de tempo.",
                    icon: "warning",
                    title: "Aviso",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Ok!",
                  }).then(result => {
                    if (result.value) {
                      this.setState({
                        redirect: false,
                      });
                      this.borderColor(["pedido_laboratorio", "data_inicio", "data_fim"]);
                    }
                  });
                }
                break;
              default:
                Swal.fire({
                  text: "Tivemos uma falha momentânea de comunicação com o laboratório.",
                  title: "Aviso",
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ok",
                });
                break;
            }
          }
          this.setState({
            loading: false,
            classOpacity: "Nopacity",
          });
          this.context.hideLoading();
          return;
        } else if (json.length === 0) {
          this.setState({
            loading: false,
            classOpacity: "Nopacity",
          });
          this.context.hideLoading();
          Swal.fire({
            text: "Nenhum registro encontrado.",
            title: "Aviso",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else if (json.erro) {
          this.setState({
            loading: false,
            classOpacity: "Nopacity",
          });
          this.context.hideLoading();
          Swal.fire({
            text: "Nenhum registro encontrado (Erro 500).",
            title: "Aviso",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
        if (this.state.result100) {
          Swal.fire({
            text: "Estes são os 100 primeiros resultados da sua busca. Para um resultado completo, por favor, especifique melhor os filtros.",
            icon: "warning",
            title: "Aviso",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          }).then(result => {
            if (result.value) {
              this.setState({ result100: false });
              window.scrollTo(0, this.myRef.offsetTop);
            }
          });
        } else {
          window.scrollTo(0, this.myRef.offsetTop);
        }

        var sort = [];
        if (!json.erro && json !== null && !json.error) {
          sort = json.sort(
            (a, b) =>
              new Date(Moment(a.dataEntrada).format("DD/MM/YYYY").split("/").reverse()) -
              new Date(Moment(b.dataEntrada).format("DD/MM/YYYY").split("/").reverse()),
          );
        } else {
          sort = json;
        }

        this.setState({
          data_tabela: sort,
          resposta_tabela: true,
          loading: false,
          classOpacity: "Nopacity",
        });

        this.context.hideLoading();
      });
  }

  listarRedesOticas = async laboratorio => {
    this.context.showLoading();
    if (this.context.userSession.modulos.includes("Consulta Rede")) {
      const body = {
        usuario: this.context,
        laboratorio: laboratorio,
        consultaRede: this.context.userSession.modulos.includes("Consulta Rede"),
      };

      try {
        const result = await getRedes(body);
        if (result) {
          if (result.status === 200) {
            this.setState({ redes: result.redes, redeOticaAux: true, cnpj_number: this.state.cnpj_number_aux });
          }
          if (result.status !== 200) {
            this.setState({ cnpj_number_aux: this.state.cnpj_number, cnpj_number: "" });
          }
        }
      } catch (error) {
        console.error("Erro ao usar a função getRedes:", error.message);
      }
    } else {
      this.setState({ cnpj_number_aux: this.state.cnpj_number, cnpj_number: "" });
    }
    setTimeout(() => {
      this.context.hideLoading();
    }, 1000);
  };

  render() {
    const { redirectL } = this.state;
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }

    if (redirectL) {
      return <Redirect to="/" />;
    }
    return (
      <main className="main-tracking" id="main-body-tracking">
        <div className={this.state.classOpacity} id="main-tabela">
          <Back />
          <div className="title-container">
            <h1 className="main-title">Acompanhe seus pedidos</h1>
            <span className="title-shape"></span>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <TrackingLabChoose onChange={this.setLabData} />
            </div>
            <div className="col-xs-12 col-md-5">
              {this.state.input_cnpj && this.state.redeOticaAux && (
                <TrackingInputCNPJ
                  name="cnpj_number"
                  value={this.state.cnpj_number}
                  onChange={this.handleChange}
                  disabled={!this.state.campoCnpjLiberado}
                  redes={this.state.redes}
                />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xs-12 col-md-3">
              <Input
                name="pedido_laboratorio"
                type="text"
                label="Pedido Laboratório"
                width="100%"
                aria="Número da ordem de serviço no laboratório."
                value={this.state.pedido_laboratorio}
                onChange={this.handleChange}
                tooltip="Número da ordem de serviço no laboratório."
              />
            </div>
            <div className="col-xs-12 col-md-1 text-center pt-3 pl-0">
              <span className="tracking-filters-text">ou</span>
            </div>
            <div className="col-xs-12 col-md-3">
              <Input
                type="text"
                label="Pedido Ótica"
                width="100%"
                name="pedido_otica"
                value={this.state.pedido_otica}
                onChange={this.handleChange}
                tooltip="Seu número interno de pedido para facilitar a localização futura."
              />
            </div>
            <div className="col-xs-12 col-md-5">
              <TrackingInputDate
                dia_inicio={this.state.dia_inicio}
                dia_fim={this.state.dia_fim}
                onChange_inicio={this.handleChange_data_inicio}
                onChange_fim={this.handleChange_data_fim}
              />
            </div>
          </div>

          <div className="row">
            <TrackingSearchButton onClick={this.changeState} />
          </div>

          <section ref={ref => (this.myRef = ref)} className="mt-4">
            {this.state.resposta_tabela &&
              (this.state.data_tabela.length > 0 ? (
                <TrackingTableHead
                  laboratorio_nome={this.state.laboratorio_nome}
                  laboratorio_cnpj={this.state.laboratorio_cnpj}
                  detailOrder={this.state.detailOrder}
                  otica_cnpj={
                    this.state.cnpj_number ? this.state.cnpj_number.replace(".", "").replace(".", "").replace("-", "").replace("/", "") : this.state.cnpj_number
                  }
                  data_tabela={this.state.data_tabela}
                />
              ) : (
                <div className="noReponse">
                  <p className="tracking-filters-label text-center">Nenhum registro encontrado.</p>
                </div>
              ))}
          </section>
        </div>
        <ResumoLateral
          logoTransparente={"https://ec2.beta.essilorconecta.com.br/modulos/tracking.png"}
          lab={this.state.lab}
          lab_logo={this.state.lab?.logo}
          lab_phone={this.state.lab?.phone}
          lab_email={this.state.lab?.email}
          lab_site={this.state.lab?.site}
        />
      </main>
    );
  }
}

export default Tracking;
