import React, { Component } from 'react'

//Import CSS
import './inputs.css'

//Import internal components
import AppContext from '../../appContext';
import Input from '../../components/inputs/input'
import Select from '../../components/selects/select'

//Import external components

//Import images

class TrackingInputCNPJ extends Component {
    constructor(props) {
        super(props);

        TrackingInputCNPJ.contextType = AppContext;
    }

    setPosition(e) {
        let val = e.target.value.replace(/\D/g, '')
        if (e.type == 'click') {
            if (val.length == 0)
                e.target.setSelectionRange(0, 0)
        }
        if (e.type == 'paste') {
            let ctrl = e.target
            if (ctrl.setSelectionRange) {
                ctrl.setSelectionRange(0, 0);

                // IE8 and below
            } else if (ctrl.createTextRange) {
                var range = ctrl.createTextRange();
                range.collapse(true);
                range.moveStart('character', 0);
                range.select();
            }
        }
        if (e.type == 'keypress') {
            if (val.length == 0) {
                e.target.setSelectionRange(0, 0)
                e.target.value = val
            }
        }
    }

    render() {
        let contextAux = this.context;
        const { redes } = this.props;
        return (
            <div>

                {
                    (!contextAux.userSession.redeOticas) && (
                        <Input
                            type='text'
                            label='CNPJ da ótica buscada *'
                            width='100%'
                            mask={[
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '.',
                                /\d/,
                                /\d/,
                                /\d/,
                                '/',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                            ]}
                            name={this.props.name}
                            id={this.props.name}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            disabled={this.props.disabled}
                            onClick={this.setPosition}
                            onPaste={this.setPosition}
							onKeyPress={this.setPosition}
							tooltip="CNPJ da Ótica que deseja buscar."
                        />
                    )
                }
                {
                    (contextAux.userSession.redeOticas) && (
                        <Select
                            label='De qual ótica é seu pedido? *'
                            width='100%'
                            aria='Selecione a ótica*'
                            placeholder='Selecione uma ótica'
                            itens={redes}
                            required
                            name={this.props.name}
                            id={this.props.name}
                            onChange={this.props.onChange}
                            disabled={this.props.disabled}
                            value={this.props.value}
                        />
                    )
                }
            </div>
        );
    }
}

export default TrackingInputCNPJ;
