import React, { Component } from "react";

//Import CSS
import "./confirmed.css";

//Import internal components
import AppContext from "../../appContext";
import CustomButton from "../../components/buttons/button";
import OrderSummaryPDF from "./order-summary-pdf";
import IconConfirmed from "./confirmed-icon";

//Import external components
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
import moment from "moment";
import PDFOrder from "../pdf-order/pdf-order";

//Import images

class Confirmed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectL: false,
      redirectWO: false,
      confirmed: false,
      pedido: "",
      valor: "",
      prazo: "",
      observacao: "",
      newOrder: "",
      newOrderPromo: "",
      redeOticas: "",
    };

    Confirmed.contextType = AppContext;

    if (localStorage.getItem("token") !== null) {
      var token = localStorage.getItem("token");

      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(""),
      );

      var jwt = JSON.parse(jsonPayload);

      const role = jwt.modulos.find(element => element === "Pedidos");

      var papel = jwt.papel;

      this.state = { papel: jwt.papel };

      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
    if (this.props.location.state === undefined && this.state.confirmed === false) {
      this.state = { redirectWO: true };
    }
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      this.setState({
        pedido: this.props.location.state.pedido,
        prazo: this.props.location.state.prazo,
        observacao: this.props.location.state.observacao,
        valor: this.props.location.state.valor,
        newOrder: this.props.location.state.newOrder,
        newOrderPromo: this.props.location.state.newOrderPromo,
        redeOticas: this.props.location.state.redeOticas,
      });
    }
  }

  newOrder = e => {
    e.preventDefault();
    this.setState({ redirectPromo: true });
  };

  render() {
    const parametros = this.props.location.state.parametros;
    const { pedido, prazo, valor, newOrder, newOrderPromo, redeOticas, observacao } = this.props.location.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    if (this.state.redirectL) {
      return <Redirect to="/" />;
    }
    if (this.state.redirectWO) {
      return <Redirect to="/web-ordering" />;
    }
    if (this.state.redirectPromo) {
      return (
        <Redirect
          to={{
            pathname: "/order",
            state: {
              name: this.props.location.state.labName,
              lab: this.props.location.state.lab,
              dados_empresa_faturamento: this.props.location.state.dados_empresa_faturamento,
              dados_empresa_faturamento_cnpj: this.props.location.state.dados_empresa_faturamento_cnpj,
              promo: this.props.location.state.promo_code ? true : false,
              promo_code: this.props.location.state.promo_code,
              promo_proximo_par: parseInt(this.props.location.state.promo_n_par) + 1,
              IsCor_AR: this.props.location.state.IsCor_AR,
              IsGrowmocao: this.props.location.state.IsGrowmocao,
              IsPacto: this.props.location.state.IsPacto,
              corteRemoto: this.props.location.state.hasCorteRemoto,
              detailOrder: this.props.location.state.detailOrder,
              colorirNaOtica: this.props.location.state.colorirNaOtica,
              // dados_nome_cliente: this.props.location.state.costumer.name,
              // dados_cpf_cliente: this.props.location.state.costumer.cpf,
              // dados_crm_medico: this.props.location.state.costumer.doctorCRM,
              logo: this.props.location.state.logo,
            },
          }}
        />
      );
    }

    return (
      <div>
        <main className="x-order-confirmed__main" id="main-body">
          <IconConfirmed />

          <div className="x-order-confirmed__container1">
            <h1 className="x-order-confirmed__title">Seu pedido foi confirmado</h1>

            <p className="x-order-confirmed__paragraph">Parabéns! Você completou seu pedido.</p>
            {/* <p className="x-order-confirmed__paragraph">
							Qualquer dúvida, por favor nos contate pelos nossos canais de
							atendimento.
						</p> */}

            <div className="x-order-confirmed__list">
              <p className="x-order-confirmed__item">
                O número do seu pedido no <br />
                laboratório <small className="x-order-confirmed__item--lab">{this.props.location.state.labName}</small> é{" "}
                <small className="x-order-confirmed__item--number">{pedido}</small>
                <br />
                {observacao?.includes("Código Voucher Pacto") && (
                  <>
                    Voucher Pacto: <br />
                    <small className="x-order-confirmed__item--lab">{observacao?.match(/Código Voucher Pacto:\s*([A-Za-z0-9-\n]+)/s)?.[1] || ""}</small>
                  </>
                )}
              </p>

              <p style={{ textAlign: "center" }} className="x-order-confirmed__item js--paragraph">
                A data de saída estimada é <br />
                <small className="x-order-confirmed__item--number">{prazo}</small>
              </p>
            </div>

            {this.props.location.state.detailOrder ? (
              <PDFOrder
                label="Imprimir Pedido"
                btn_class="pdf-pedido__summary--print"
                icon={false}
                prazo={prazo}
                laboratorio={this.props.location.state.lab}
                otica={this.props.location.state.dados_empresa_faturamento_cnpj}
                pedido_laboratotio={pedido}
                laboratorio_nome={this.props.location.state.labName}
                redeOticas={this.props.location.state.redeOticas}
                local={"order-confirmed"}
              />
            ) : (
              <>
                <ReactToPrint
                  documentTitle={`Essilor Conecta - Pedido ${pedido}`}
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a className="pdf-pedido__summary--print">Imprimir Pedido</a>;
                  }}
                  content={() => this.componentRef}
                />

                <div style={{ display: "none" }}>
                  <OrderSummaryPDF
                    ref={el => (this.componentRef = el)}
                    order={this.props.location.state.orderSummary}
                    pedido={pedido}
                    prazo={prazo}
                    parametros={parametros}
                  />
                </div>
              </>
            )}

            <div className="button-mobile-only">
              {this.props.location.state.promo_n_par < this.props.location.state.promo_total_pares ? (
                <>
                  <CustomButton
                    label={`Inserir ${parseInt(this.props.location.state.promo_n_par) + 1}° par`}
                    width="16rem"
                    icon_arrow_right
                    orange_button
                    onClick={this.newOrder}
                  />
                </>
              ) : (
                <CustomButton
                  label="Fazer novo pedido"
                  width="13.125rem"
                  icon_arrow_right
                  orange_button
                  onClick={() => {
                    window.location = newOrder;
                  }}
                />
              )}
            </div>

            {/* <a className="x-ordr-confirmed__link" target="_blank" href="/">Copiar Pedido</a> */}
          </div>
        </main>
      </div>
    );
  }
}

export default Confirmed;
