import React, { Component } from "react";
import "./otica-menu.css";
import CustomButton from "../../../components/buttons/button";
import { ChevronDown } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AppContext from "../../../appContext";
import { convertJWT, updateToken } from "../../../service/multi-contas/multi-contas-service";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import Offcanvas from "react-bootstrap/Offcanvas";
import oticaDefault from "./../img/otica.png";
import CnpjUtils from "../../../shered/utils/CnpjUtils";

class OticaMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			listaOtica: [],
			labelotica: "",
			redirectHome: false,
			oticaLoad: true,
			oticaLoadMenu: true,
			showMobile: false,
		};

		this.reloadList = this.reloadList.bind(this);
		this.updateToken = this.updateToken.bind(this);
		this.handleSideBar = this.handleSideBar.bind(this);
		OticaMenu.contextType = AppContext;
	}

	componentDidMount() {
		if (this.context.userSession.papelCategoria === "OTICA") {
			this.reloadList();
		} else {
			this.setState({ oticaLoad: false, oticaLoadMenu: false });
		}
	}

	componentDidUpdate(prevProps) {
		const { lista_multi_contas: prevListaConta } = prevProps;
		const { lista_multi_contas: currListaConta } = this.props;

		if (prevListaConta.length !== currListaConta.length) {
			setTimeout(() => {
				this.context.hideLoading();
			}, 1000);
		} else {
			for (let i = 0; i < currListaConta.length; i++) {
				const prevItem = prevListaConta[i];
				const currItem = currListaConta[i];

				if (prevItem.ativo !== currItem.ativo || !prevItem || prevItem.id !== currItem.id) {
					setTimeout(() => {
						this.context.hideLoading();
					}, 1000);
				}
			}
		}
	}

	reloadList() {
		const listaOtica = [];
		let response = this.props.lista_multi_contas;

		if (response) {
			response.map((item) => {
				let papel = item.papel.nome;

				if (response.length === 1) {
					papel = this.context.userSession.papel;
				}

				const otica = {
					nome: item.conta.nome,
					cnpj: item.conta.cnpj,
					perfil: papel,
					url: item.conta.url ?? oticaDefault,
					apelido: item.conta.apelido,
					fantasia: item.fantasia,
					principal: item.principal,
					ativo: item.conta.cnpj === this.context.userSession.conta,
					desativado: !item.ativo,
				};

				if (item.conta.cnpj === this.context.userSession.conta) {
					this.setState({
						labelotica: item.fantasia,
						oticaLoad: false,
					});
				}

				listaOtica.push(otica);
			});
		}

		if (listaOtica.length > 0) {
			const auxlist = listaOtica.filter((item) => {
				return item.desativado === false;
			});

			this.setState({ listaOtica: auxlist, oticaLoad: false, oticaLoadMenu: false });

		} else {
			this.setState({ oticaLoad: false, oticaLoadMenu: false });
		}
	}

	updateToken(conta) {
		if (this.state.listaOtica.length > 1) {
			const session_token = localStorage.getItem("session_token");

			this.context.showLoading("Alternando conta...");
			updateToken(conta, session_token).then(response => {
				if (response.status === 201 || response.status === 200) {
					localStorage.removeItem("token");
					localStorage.removeItem("session_token");
					localStorage.removeItem("grant_token");

          localStorage.setItem("token", response.access_token);
          localStorage.setItem("session_token", response.session_token);
          localStorage.setItem("grant_token", response.grant_token);
          const cnpj = convertJWT(response.access_token).conta;

          window.location.href = "/home";
          this.context.hideLoading();
        } else {
          this.context.hideLoading();
          Swal.fire({
            text: response.message,
            icon: "error",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        }
      });
    }
  }

	handleSideBar(open) {
		this.setState({ showMobile: open });
	}

	render() {
		return (
			<>
				{ (this.context.userSession.papelCategoria === "OTICA" || this.context.userSession.papelCategoria.includes("LAB")) && (
					<div className="d-block">
						{ this.state.oticaLoad ? (
							<ReactLoading type="bubbles" color="#00518b" height={ 65 } width={ 65 }/>
						) : (
							<button
								className="otica-menu-btn"
								id="headerBtn"
								onClick={ e => {
									e.preventDefault();
									this.handleSideBar(true);
								} }>
								<label
									className="otica-menu-text"
									htmlFor="headerBtn"
									onClick={ () => {
										this.setState({ oticaLoad: true, oticaLoadMenu: true });
										this.reloadList();
									} }>
									{ this.context.apelidoConta }
									<span className="pl-1">
                    <ChevronDown size={ 18 }/>
                  </span>
								</label>
							</button>
						) }

						<Offcanvas show={ this.state.showMobile } onHide={ () => this.handleSideBar(false) } placement="end"
											 name="end" backdrop={ true } scroll="true">
							<Offcanvas.Header closeButton>
								<Offcanvas.Title>Empresas</Offcanvas.Title>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<div>
									{ this.state.oticaLoad ? (
										<ReactLoading type="bubbles" color="#00518b" height={ 65 } width={ 65 }/>
									) : (
										<main className="otica-menu-main text-center">
											<div className="otica-menu-content-mobile">
												{ this.state.listaOtica.map((item) => {
													return (
														<ul
															onClick={ () => {
																this.handleSideBar(false);
																this.updateToken(item.cnpj);
															} }
															className={ "list-unstyled " + (item.ativo ? "ativo" : "") }>
															<div className="row">
																<div className="col-3 pl-4 pr-0">
																	<img className="otica-menu-img" src={ item.url } alt="logo"></img>
																</div>
																<div className="col-7 px-0">
																	<p className="otica-ul-text otica-text-height">
																		{ item.fantasia }
																		<br/>
																		{ CnpjUtils.formatCnpj( item.cnpj ) }
																		<br/>
																		{ item.perfil }
																	</p>
																</div>
																<div className="col-2 pl-0 pr-4">{ item.ativo &&
																	<p className="otica-ul-text line-h-50">Ativo</p> }</div>
															</div>
														</ul>
													);
												}) }
												<hr></hr>
												<div className="otica-menu-footer-center">
													<Link
														onClick={ () => {
															this.handleSideBar(false);
														} }
														to="/profile">
														<CustomButton label="Adicionar nova empresa" marginTop="1rem" width="100%"
																					height="2.3125rem"/>
													</Link>
												</div>
											</div>
										</main>
									) }
								</div>
							</Offcanvas.Body>
						</Offcanvas>
					</div>
				) }
			</>
		);
	}
}

export default OticaMenu;
