import React, { Component } from "react";
import PDFOrderP from "./pdf-order-p";

class PDFOrderDados extends Component {
  render() {
    const { dados } = this.props;

    return (
      <div className="pdf-order-dados row">
        <div className="col-12">
          <h1 className="pdf-title">Dados do pedido</h1>
        </div>
        <div className="row">
          <div className="col">
            {dados.promo_code && <PDFOrderP label="Cupom Promocional" value={dados.promo_code} />}
            <PDFOrderP label="Nº do pedido da ótica" value={dados.numero_pedido} />

            {dados.nome_cliente && <PDFOrderP label="Nome do Cliente" value={dados.nome_cliente} />}

            {dados.nome_medico && <PDFOrderP label="Nome do Médico" value={dados.nome_medico} />}
          </div>
          <div className="col">
            {dados.dados_promo_nome && <PDFOrderP label="Nome da promoção" value={dados.dados_promo_nome} />}
            <PDFOrderP label="Empresa de faturamento" value={dados.empresa_faturamento} />

            {dados.cpf_cliente && <PDFOrderP label="CPF do Cliente" value={dados.cpf_cliente} />}

            {dados.crm_medico && <PDFOrderP label="CRM do Médico" value={dados.crm_medico} />}
          </div>
        </div>
      </div>
    );
  }
}

export default PDFOrderDados;
