
async function getVouchers(cnpj, laboratorio, rpl, codServico) {
  const requestOptions = {
    method: "GET",
    headers: {
      access_token: localStorage.getItem("token"),
      client_id: localStorage.getItem("clientid"),
      laboratorio: laboratorio.replaceAll(".", "").replace("/", "").replace("-", ""),
    },
  };
  let status = "";
  return fetch(
    process.env.REACT_APP_ESSILOR_API +
      "gestao-pedidos-laboratorio/v1/pacto/vouchers?idCliente=" +
      cnpj.replaceAll(".", "").replace("/", "").replace("-", "") +
      "&idLaboratorio=" +
      laboratorio.replaceAll(".", "").replace("/", "").replace("-", "") +
      "&rpl=" +
      rpl +
      "&codServico=" +
      codServico,
    requestOptions,
  )
    .then(response => {
      status = response.status;
      return response.json();
    })
    .then(json => {

      const response = {
        status: status,
        mensagem: status == 200 ? filtrarVouchersMaisAntigos(json): "erro",
      };

      return response;
    }).catch(error => {
      return []; 
    });;
}
export { getVouchers };

function filtrarVouchersMaisAntigos(data){
  return data.map(item => {
    const voucherMaisAntigo = item.listaVouchers.reduce((maisAntigo, voucherAtual) => {
      return new Date(voucherAtual.dataCriacao) < new Date(maisAntigo.dataCriacao) ? voucherAtual : maisAntigo;
    });
    return {
      ...item,
      listaVouchers: [voucherMaisAntigo],
    };
  });
};
