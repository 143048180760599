import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

//Import CSS
import "./select.css";

//Import internal components

//Import external components

//Import images

class Select extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.renderSelect = this.renderSelect.bind(this);
  }

  renderSelect() {
    let listItems;

    if (this.props.itens) {
      listItems = this.props.itens.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ));
    }

    // ${this.props.className} classname por props
    return (
      <div className="crm-select" style={{ width: this.props.width }}>
        <label className="crm-select-label" htmlFor={this.props.name} id={this.props.idLabel}>
          {this.props.label}
        </label>
        <select
          ref={this.selectRef}
          className={`crm-select-input ${this.props.className}`}
          name={this.props.name}
          id={this.props.name}
          aria-label={this.props.aria}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          onChange={this.props.onChange}
          required={this.props.required}
          disabled={this.props.disabled}>
          {!this.props.placeholder && (
            <>
              <option></option>
              {listItems}
            </>
          )}

          {this.props.placeholder && !listItems && (
            <>
              <option value="">{this.props.placeholder}</option>
            </>
          )}

          {this.props.placeholder && listItems && (
            <>
              <option value="" style={{ display: this.props.placeholder_dont_display ? "none" : "block" }} disabled={this.props.placeholder_disabled}>
                {this.props.placeholder}
              </option>
              {listItems}
            </>
          )}
        </select>
        <span className="crm-select-arrow"></span>
      </div>
    );
  }

  render() {
    let positionOverlay = window.innerWidth >= 1024 ? "right" : "auto";
    return this.props.showTooltip && this.props.tooltipMsg ? (
      <OverlayTrigger
        placement={positionOverlay}
        delay={{ show: 250, hide: 400 }}
        trigger={["hover", "focus"]}
        overlay={<Tooltip>{this.props.tooltipMsg}</Tooltip>}>
        {this.renderSelect()}
      </OverlayTrigger>
    ) : (
      this.renderSelect()
    );
  }
}

export default Select;
