import React, { Component } from "react";
import { UpcScan } from "react-bootstrap-icons";

import Swal from "sweetalert2";
import "../../../index.css";
import AppContext from "../../../../../../appContext";

class DownloadPDFSimplesAncora extends Component {
	constructor(_props) {
		super(undefined);
		this.handleDownloadBoleto = this.handleDownloadBoleto.bind(this);
		DownloadPDFSimplesAncora.contextType = AppContext;
	}

	handleDownloadBoleto() {
		this.context.showLoading();

		const header = {
			client_id: localStorage.getItem("clientid"),
			access_token: localStorage.getItem("token"),
			laboratorio: this.props.lab,
			otica: this.props.otica,
		};

		const requestOptions = {
			method: "GET",
			headers: header,
		};
		var status = "";
		fetch(process.env.REACT_APP_ESSILOR_API + this.props.url, requestOptions)
			.then(response => {
				status = response["status"];
				return response.json();
			})
			.then(json => {
				if (
					status === 200 &&
					json &&
					(json["documento"] !== "Registro n\u00e3o encontrado no laborat\u00f3rio" || json["boleto"] !== "Registro n\u00e3o encontrado no laborat\u00f3rio")
				) {
					let byteArray = "";
					if (json["documento"]) {
						byteArray = Uint8Array.from(
							atob(json["documento"])
								.split("")
								.map(char => char.charCodeAt(0)),
						);
					} else {
						byteArray = Uint8Array.from(
							atob(json["boleto"])
								.split("")
								.map(char => char.charCodeAt(0)),
						);
					}

					var pdfFile = new Blob([ byteArray ], {
						type: "application/pdf",
					});

					const pdfURL = URL.createObjectURL(pdfFile);

					const downloadLink = document.createElement("a");
					downloadLink.href = pdfURL;
					downloadLink.download = this.props.archiveName;

					document.body.appendChild(downloadLink);
					downloadLink.click();

					document.body.removeChild(downloadLink);
				} else {
					if (status >= 500) {
						Swal.fire({
							html: "Falha de comunicação com o laboratório.",
							width: "600px",
							icon: "warning",
							title: "Aviso",
							confirmButtonColor: "#00518b",
							customClass: {
								confirmButton: "x-follow-order__filter-btn",
							},
						}).then(result => {
							this.context.hideLoading();
						});
					} else if (status === 404) {
						Swal.fire({
							html: this.props.messageErro !== false ? this.props.messageErro : json[0].mensagem,
							width: "600px",
							icon: "warning",
							title: "Aviso",
							confirmButtonColor: "#00518b",
							customClass: {
								confirmButton: "x-follow-order__filter-btn",
							},
						}).then(result => {
							this.context.hideLoading();
						});
					} else {
						Swal.fire({
							html: this.props.messageErro,
							width: "600px",
							icon: "warning",
							title: "Aviso",
							confirmButtonColor: "#00518b",
							customClass: {
								confirmButton: "x-follow-order__filter-btn",
							},
						}).then(result => {
							this.context.hideLoading();
						});
					}
				}
			})
			.then(() => {
				this.context.hideLoading();
			});
	}

	render() {
		return (
			<>
				{ this.props.tagALink && (
					<a
						className="tagADownloadComponent"
						href="#"
						onClick={ () => {
							this.handleDownloadBoleto();
						} }>
						{ this.props.linkName }
					</a>
				) }
				{ this.props.UpcScam && (
					<td className={ "financeiro-table-td" }>
						<a href="#" title="Baixar Boleto" onClick={ this.handleDownloadBoleto }>
              <span>
                <UpcScan color="royalblue" size={ 24 }/>
              </span>
						</a>

					</td>
				) }
			</>
		);
	}
}

export default DownloadPDFSimplesAncora;
