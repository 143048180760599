import React, { Component } from "react";

import "./financeiro-detalhes-modal.css";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import DownloadPDF from "../../components/utils/download-file/tag-a/automatic-download-user-machine/PDF";
import DownloadXML from "../../components/utils/download-file/tag-a/automatic-download-user-machine/XML";
import PDFOrderAtacado from "../../pedido-atacado/pdf-order/pdf-order";
import Moment from "moment";

class FinanceiroDetalhesModal extends Component {
  constructor(props) {
    super();
    FinanceiroDetalhesModal.contextType = AppContext;
  }

  render() {
    return (
      <>
        {this.props.active && !this.props.dropdown && (
          <div className="financeiro-overlay-content">
            <div className="financeiro-wrapper">
              <div className="financeiro-details-content">
                <div className="title">
                  <span>Detalhes do boleto</span>
                </div>

                <div className={`content-center financeiro-modal-table-div`}>
                  <table className="table table-sm text-center table-borderless table-striped" id="financeiro-modal-table">
                    <thead className="finaceiro-modal-thead">
                      <tr className="financeiro-detalhes-modal-tr">
                        <th className="financeiro-detalhes-modal">Número Documento</th>
                        <th className="financeiro-detalhes-modal">Número Nota Fiscal</th>
                        <th className="financeiro-detalhes-modal">Número Pedido</th>
                        <th className="financeiro-detalhes-modal">Data Pedido</th>
                        <th className="financeiro-detalhes-modal">Valor Total</th>
                        <th className="financeiro-detalhes-modal">Valor Mercadoria</th>
                        <th className="financeiro-detalhes-modal">Valor Serviço</th>
                        <th className="financeiro-detalhes-modal">Valor Desconto</th>
                        <th className="financeiro-detalhes-modal">Valor Desconto Serviço</th>
                        <th className="financeiro-detalhes-modal">Valor Frete</th>
                        <th className="financeiro-detalhes-modal">Valor Financeiro</th>
                        <th className="financeiro-detalhes-modal">Data Fechamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.itens.map((order, index) => {
                        return (
                          <tr className="financeiro-detalhes-modal-tr" key={index}>
                            <td className="financeiro-detalhes-modal-td">{order.numeroDocumento}</td>
                            <td className="financeiro-detalhes-modal-td">
                            <div className="financeiro-div-nota-fiscal-download-xml-pdf-modal">
                                <p>{order.numeroNotaFiscal}</p>
                                <DownloadPDF
                                  lab={this.props.laboratorio}
                                  otica={this.props.otica}
                                  messageErro={"Nota Fiscal não encontrada."}
                                  archiveName={`Nota_Fiscal_N°_${order.numeroNotaFiscal}.pdf`}
                                  url={`gestao-financeira-cliente-laboratorio/v1/danfe/?numeroNotaFiscal=${order.numeroNotaFiscal}&tipoDocumento=PDF`}
                                  linkName={"PDF"}
                                  tagALink={true}
                                />
                                <DownloadXML
                                  lab={this.props.laboratorio}
                                  otica={this.props.otica}
                                  messageErro={"Nota Fiscal não encontrada."}
                                  archiveName={`Nota_Fiscal_N°_${order.numeroNotaFiscal}.xml`}
                                  url={`gestao-financeira-cliente-laboratorio/v1/danfe/?numeroNotaFiscal=${order.numeroNotaFiscal}&tipoDocumento=XML`}
                                  linkName={"XML"}
                                  tagALink={true}
                                />
                              </div>
                            </td>
                            <td className="financeiro-detalhes-modal-td">
                              <PDFOrderAtacado
                                icon={false}
                                prazo={order.dataFechamento}
                                laboratorio={this.props.laboratorio}
                                otica={this.props.otica}
                                pedido_laboratotio={order.idPedido}
                                laboratorio_nome={this.props.laboratorio_nome}
                                local={"tracking"}
                                label={order.idPedido}
                                btn_class={"tagADownloadComponent"}
                              />
                            </td>
                            <td className="financeiro-detalhes-modal-td">{order.dataPedido}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorTotal}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorMercadoria}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorServico}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorDesconto}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorDescontoServico}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorFrete}</td>
                            <td className="financeiro-detalhes-modal-td">{order.valorFinanceiro}</td>
                            <td className="financeiro-detalhes-modal-td">{order.dataFechamento}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="button">
                  <button onClick={this.props.handleModal}>OK</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.active && this.props.dropdown && (
          <div className="financeiro-details-content-dropdown">
            <div className={`content-center financeiro-modal-table-div ${this.props.classNameContainerTable}`}>
              <table className="table table-sm text-center table-borderless table-striped" id="financeiro-modal-table">
                <thead className="finaceiro-modal-thead">
                  <tr className="financeiro-detalhes-modal-tr">
                    <th className="financeiro-detalhes-modal">Número Documento</th>
                    <th className="financeiro-detalhes-modal">Número Nota Fiscal</th>
                    <th className="financeiro-detalhes-modal">Número Pedido</th>
                    <th className="financeiro-detalhes-modal">Data Pedido</th>
                    <th className="financeiro-detalhes-modal">Valor Total</th>
                    <th className="financeiro-detalhes-modal">Valor Mercadoria</th>
                    <th className="financeiro-detalhes-modal">Valor Serviço</th>
                    <th className="financeiro-detalhes-modal">Valor Desconto</th>
                    <th className="financeiro-detalhes-modal">Valor Desconto Serviço</th>
                    <th className="financeiro-detalhes-modal">Valor Frete</th>
                    <th className="financeiro-detalhes-modal">Valor Financeiro</th>
                    <th className="financeiro-detalhes-modal">Data Fechamento</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.itens.map((order, index) => {
                    return (
                      <tr className="financeiro-detalhes-modal-tr" key={index}>
                        <td className="financeiro-detalhes-modal-td">{order.numeroDocumento}</td>
                        <td className="financeiro-detalhes-modal-td">
                          <div className="financeiro-div-nota-fiscal-download-xml-pdf-modal">
                            <p>{order.numeroNotaFiscal}</p>
                            <DownloadPDF
                              lab={this.props.laboratorio}
                              otica={this.props.otica}
                              messageErro={"Nota Fiscal não encontrada."}
                              archiveName={`Nota_Fiscal_N°_${order.numeroNotaFiscal}.pdf`}
                              url={`gestao-financeira-cliente-laboratorio/v1/danfe/?numeroNotaFiscal=${order.numeroNotaFiscal}&tipoDocumento=PDF`}
                              linkName={"PDF"}
                              tagALink={true}
                            />
                            <DownloadXML
                              lab={this.props.laboratorio}
                              otica={this.props.otica}
                              messageErro={"Nota Fiscal não encontrada."}
                              archiveName={`Nota_Fiscal_N°_${order.numeroNotaFiscal}.xml`}
                              url={`gestao-financeira-cliente-laboratorio/v1/danfe/?numeroNotaFiscal=${order.numeroNotaFiscal}&tipoDocumento=XML`}
                              linkName={"XML"}
                              tagALink={true}
                            />
                          </div>
                        </td>
                        <td className="financeiro-detalhes-modal-td">
                          <PDFOrderAtacado
                            icon={false}
                            prazo={order.dataFechamento}
                            laboratorio={this.props.laboratorio}
                            otica={this.props.otica}
                            pedido_laboratotio={order.idPedido}
                            laboratorio_nome={this.props.laboratorio_nome}
                            local={"tracking"}
                            label={order.idPedido}
                            btn_class={"tagADownloadComponent"}
                          />
                        </td>
                        <td className="financeiro-detalhes-modal-td">{Moment(order.dataPedido).format("DD/MM/YYYY")}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorTotal}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorMercadoria}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorServico}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorDesconto}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorDescontoServico}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorFrete}</td>
                        <td className="financeiro-detalhes-modal-td">{order.valorFinanceiro}</td>
                        <td className="financeiro-detalhes-modal-td">{Moment(order.dataFechamento).format("DD/MM/YYYY")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default FinanceiroDetalhesModal;
