import React, { Component } from "react";
import AppContext from "../../appContext";
import { Uploader } from "../../components/utils/upload/upload";
import "./index.css";
import Swal from "sweetalert2";
import { FaCirclePlus } from "react-icons/fa6";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaRegCircleCheck } from "react-icons/fa6";

class CreateFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      pgvalue: "",
      fileIndex: 0,
      perf: "-",
      status: "",
      isCancelled: false,
    };
    CreateFile.contextType = AppContext;
    this.handleFileChange = this.handleFileChange.bind(this);

    this.cancelProcess = this.cancelProcess.bind(this);
    this.executeUpload = this.executeUpload.bind(this);
  }
  cancelProcess() {
    Swal.fire({
      icon: "warning",
      text: "Tem certeza que deseja interromper o carregamento dos arquivos?",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonColor: "#d33",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        this.setState({ isCancelled: true }, () => {
          if (this.uploaderInstance) {
            this.uploaderInstance.abort();
          }
        });
      }
    });
  }
  executeUpload() {
    const { file } = this.state;
    this.setState({ pgvalue: 0, isCancelled: false });
    this.uploaderInstance = new Uploader({
      file,
      folder: this.props.folderSelect,
      onProgress: ({ percentage, fileIndex, status }) => {
        this.setState({ pgvalue: percentage, fileIndex: fileIndex });
        if (status === "end") {
          const fileNames = Array.from(this.state.file)
            .map((file, index) => `<div style="margin-left: 10px;">• ${file.name}</div>`)
            .join("");

          Swal.fire({
            icon: "success",
            title: "Pronto!",
            html: `<div style="display: flex; justify-content: center; align-items: center; height: 100%; flex-direction: column;">
                        <p>Os seguintes arquivos foram enviados com sucesso para a pasta <strong>${this.props.folderSelect}</strong>:</p>
                        <div style="width: 80%; margin: 20px auto 0 auto; text-align: left;">
                          ${fileNames}
                        </div>
                      </div>
                    `,
          }).then(() => {
            this.setState({ file: [], pgvalue: "", fileIndex: 0, status: ""});
            this.context.showLoading("Carregando...");
            this.props.getFolders();
          });
        }
        if (status === "abort") {
          Swal.fire({
            html: `<div style="display: flex; justify-content: center; align-items: center; height: 100%; flex-direction: column;">
            <p>O upload dos seguintes arquivos foi <strong>cancelado</strong>:</p>
            <div style="width: 80%; margin: 20px auto 0 auto; text-align: left;">
              ${Array.from(this.state.file)
                .map(file => `<div style="margin-left: 10px;">• ${file.name}</div>`)
                .join("")}
            </div>
          </div>
        `,
            icon: "warning",
            title: "Upload Cancelado",
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(() => {
            this.context.showLoading("Carregando...")
            window.location.reload();
          });
        }
      },
      onError: error => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          html: error,
        });
      },
    });
    this.uploaderInstance.start();
  }

  handleFileChange(e) {
    if (e.target?.files?.length > 0) {
      this.setState({ file: e.target.files }, () => {
        this.executeUpload();
      });
    }
  }

  render() {
    const totalFile = this.state.file?.length ? this.state.file?.length : 0;

    const { pgvalue, fileIndex } = this.state;
    return (
      <div className="precos-e-servicos-file-card" onClick={this.handleClick}>
        <label for="fileinput" className="labelCreateFile">
          <div className="createFileFiv">
            <FaCirclePlus size={50} color={pgvalue ? "#D3D3D3" : "#0394db"} />
            <div>
              <p style={{ color: pgvalue ? "#D3D3D3" : "inherit" }}>{`Clique aqui para adicionar arquivos na pasta ${this.props.folderSelect}`}</p>
              <input disabled={pgvalue !== ""} id="fileinput" type="file" name="userImage" multiple onChange={this.handleFileChange} />
            </div>
          </div>
        </label>
        <div style={{ padding: "10px", margin: "10px" }}>
          <br />
          <span id="output">
            <ProgressBar now={pgvalue} variant="success" />
          </span>
          {fileIndex > 0 && (
            <div className="pgvalue">
              <p>{`${pgvalue}% (` + ` ${fileIndex} de ${totalFile} arquivos concluídos)`}</p>
              <a className="linkBold" onClick={this.cancelProcess}>
                Cancelar upload
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreateFile;
