
import React, { Component } from 'react'

//Import CSS
import './switch.css'

//Import internal components

//Import external components
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

//Import images


class Switch extends Component {

	constructor(props) {
		super(props)
		this.state = {

		}

	}

	render() {

		return (
			<Form>
				<div className="switch-container">

						<OverlayTrigger
							placement="left"
							delay={{ show: 250, hide: 400 }}
							overlay={<Tooltip id="button-tooltip">{this.props.tooltip}</Tooltip>}
						>
							<div className="switch">
								<Form.Check
									label=""
									type="switch"
									id={this.props.id}
									name={this.props.name}
									onClick={this.props.onClick}
									onChange={this.props.onChange}
									checked={this.props.value}
									disabled={this.props.disabled ? this.props.disabled : false}
								/>
							</div>
						</OverlayTrigger>

					<div className="">
						{this.props.label && <p className="switch-lable">{this.props.label}</p>}
					</div>

				</div>
			</Form>
		)
	}
}

export default Switch