import React, { Component } from 'react'
import './search-media.css'

class SearchMedia extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="">
				<label className="x-downloads__search--label" htmlFor="">
				{this.props.label}
				</label>
				<div className="x-downloads__search--position-select">
				<input
					id={this.props.name}
					name={this.props.name}
					value={this.props.value}
					onChange={this.props.onChange}
					className="x-downloads__search--input"
					type="text"
					onKeyDown={this.props.onKeyDown}
				/>
				</div>
			</div>
		)
	}
}

export default SearchMedia