import React, { Component } from "react";

//Import CSS
import "./table.css";

//Import internal components
import TrackingTableLine from "./tracking-table-line";

//Import external components
import Table from "react-bootstrap/Table";

import ReactPaginate from "react-paginate";

//Import images

export class TrackingTableHead extends Component {
  constructor(props) {
    super();

    this.state = {
      data_tabela: props.data_tabela,
      pageCount: Math.ceil(props.data_tabela.length / 10),
      offset: 0,
    };

    this.paginateData = this.paginateData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    this.paginateData();
  }

  paginateData() {
    const dataPagination = this.props.data_tabela.slice(this.state.offset, this.state.offset + 10);
    this.setState({ data_tabela: dataPagination });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState({ offset: offset }, this.paginateData);
  };

  render() {
    return (
      <div ref={this.integraPedido}>
        {this.props.submitted}

        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>

        <Table className="pedidos table text-center">
          <thead className="thead-dark">
            <tr> 
              <th className="tracking-pedidos-thead-th">Pedido laboratório</th>
              <th className="hide-table-column hide-table-column-tablet tracking-pedidos-thead-th">Pedido ótica</th>
              <th>Data do pedido</th>
              <th className="hide-table-column tracking-pedidos-thead-th">Data prevista</th>
              <th className="hide-table-column tracking-pedidos-thead-th">Status</th>
            </tr>
          </thead>

          {this.state.data_tabela &&
            this.state.data_tabela.map((item, index) => {
              return (
                <TrackingTableLine
                  laboratorio_nome={this.props.laboratorio_nome}
                  laboratorio_cnpj={this.props.laboratorio_cnpj}
                  detailOrder={this.props.detailOrder}
                  otica_cnpj={this.props.otica_cnpj}
                  item={item}
                  index={index}
                  offset = {this.state.offset}
                />
              );
            })}
        </Table>
      </div>
    );
  }
}

export default TrackingTableHead;
