import React, { Component } from 'react'

import './teste.css'

import Select from 'react-select'
import Input from '../components/inputs/input'

const options = [
	{ value: 'chocolate', label: 'Chocolate' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]

class Teste extends Component {

    constructor(props){
        super(props);
    }

  	render(){

        return (
			<div>
				<div className="crm-input1" style={{ width: '300px' }}>
					<label className="crm-input-label1" htmlFor={this.props.name}>
						Teste
					</label>
			   		<Select options={options} />
				</div>

				<div className="crm-input1" style={{ width: '300px' }}>
					<label className="crm-input-label1" htmlFor={this.props.name}>
						Teste
					</label>
					<Select options={options} />
				</div>

				<Input label='Teste' width='300px' type='text' />

			</div>

        )
  	}
}

export default Teste;