import React, { Component } from 'react'
import './media-center.css'
import AppContext from '../appContext'
import MediaCenter from './media-center'
import { Redirect } from 'react-router-dom'
import { redirectPage } from '../components/utils/redirectpage'

class MarketingCenter extends Component {
    constructor(props) {
        super(props)

        this.state = {
			// redirectL: false
		}

		if (localStorage.getItem('token') !== null) {
			MarketingCenter.contextType = AppContext
			const role = MarketingCenter.contextType._currentValue.userSession.modulos.find(element => element === 'Marketing Center');
			if (!role) {
				this.state = { redirect: true };
			}
		}else {
			redirectPage(localStorage.getItem('token'), '/marketingcenter')
		}
    }

  render() {
    if (this.state.redirect) {
        return <Redirect to="/home" />;
    }
	// if(this.state.redirectL){
	// 	return <Redirect to="/" />;
	// }

    return (
        <MediaCenter />
    )
  }
}

export default MarketingCenter
