import React, { Component } from "react";

//Import CSS
import "./select-auto.css";

//Import internal components

//Import external components
import Select from "react-select";

//Import images

class SelectAuto extends Component {
  constructor(props) {
    super(props);

    this.selectAuto = React.createRef();
  }

  render() {
    const filterConf = (candidate, input) => {
      if (input.startsWith("+") || input.startsWith("-")) {
        return candidate.data.__isNew__ || candidate.label.includes(input);
      } else {
        input = input.replace(".", "\\.");
        const expr = new RegExp(`^[+|-](${input})|^${input}`);

        return expr.test(candidate.label);
      }
    };

    return (
      <div className="crm-input1" style={{ width: this.props.width }}>
        <label className="crm-input-label1" htmlFor={this.props.name}>
          {this.props.label}
        </label>

        <div className="container-auto-select">
          <Select
            options={this.props.options}
            placeholder={this.props.placeholder}
            name={this.props.name}
            id={this.props.name}
            aria-label={this.props.aria}
            defaultValue={this.props.defaultValue}
            value={this.props.value}
            selectValue={this.props.selectValue}
            isClearable={this.props.isClearable}
            className={this.props.className}
            onKeyDown={this.props.onKeyDown}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            ref={this.selectAuto}
            onInputChange={e => this.props.onlyDot && e.replace(",", ".")}
            required={this.props.required}
            isDisabled={this.props.disabled}
            filterOption={filterConf}
          />
        </div>
      </div>
    );
  }
}

export default SelectAuto;
