import React, { Component } from 'react'

//Import CSS
import './inputs.css'
import 'react-datepicker/dist/react-datepicker.css'

//Import internal components
import Input from '../../components/inputs/input'

//Import external components
import DatePicker from 'react-datepicker'
import MaskedTextInput from 'react-text-mask'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

//Import images

class TrackingInputDate extends Component {

    render() {

        function handleFocus(e) {
            e.target.select()
        }

        return (
            <>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <DatePicker
                            className="tracking-filters-date start-date"
                            locale="pt"
                            name="data_inicio"
                            id="data_inicio"
                            placeholder="dd/mm/aaaa"
                            dateFormat="dd/MM/yyyy"
                            value={this.props.dia_inicio}
                            selected={this.props.dia_inicio}
                            onChange={this.props.onChange_inicio}
                            onFocus={handleFocus}
                            autoComplete="off"
                            customInput={
                                <Input
                                    type="text"
                                    label='Data de início'
                                    mask={[
                                        /\d/,
                                        /\d/,
                                        '/',
                                        /\d/,
                                        /\d/,
                                        '/',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    width='100%'
                                />
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <DatePicker
                            className="tracking-filters-date end-date"
                            locale="pt"
                            name="data_fim"
                            id="data_fim"
                            placeholder="dd/mm/aaaa"
                            dateFormat="dd/MM/yyyy"
                            value={this.props.dia_fim}
                            selected={this.props.dia_fim}
                            onChange={this.props.onChange_fim}
                            onFocus={handleFocus}
                            autoComplete="off"
                            customInput={
                                <Input
                                    type="text"
                                    label='Data final'
                                    mask={[
                                        /\d/,
                                        /\d/,
                                        '/',
                                        /\d/,
                                        /\d/,
                                        '/',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    width='100%'
                                />
                            }
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TrackingInputDate;
