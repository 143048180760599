class CnpjUtils {
	static formatCnpj(cnpj) {
		const digits = cnpj.replace(/\D/g, '');
		return digits.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
	}

	static cleanCnpj(cnpj) {
		return cnpj.replace(/\D/g, '');
	}
}

export default CnpjUtils;