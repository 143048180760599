import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderPersonalizados extends Component {

    render() {
        const { personalizadas } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <h3 className="pdf-subtitle-bold">Medidas personalizadas</h3>
                </div>

                <div className="col-6">
                    {personalizadas.iniciais_cliente && <PDFOrderP label='Iniciais do cliente' value={personalizadas.iniciais_cliente} />}
                    {personalizadas.distancia_leitura && <PDFOrderP label='Distância de Leitura' value={personalizadas.distancia_leitura} />}
                    {personalizadas.cvp && <PDFOrderP label='CVP' value={personalizadas.cvp} />}
                    {personalizadas.pantoscopico && <PDFOrderP label='Ângulo pantoscópico' value={personalizadas.pantoscopico} />}
                    {personalizadas.curvatura && <PDFOrderP label='Curvatura da armação' value={personalizadas.curvatura} />}
                </div>

                <div className='col-6'>
                    {personalizadas.coeficiente && <PDFOrderP label='Coeficiente cabeça-olhos' value={personalizadas.coeficiente} />}
                    {personalizadas.estabilidade && <PDFOrderP label='Estabilidade' value={personalizadas.estabilidade} />}
                    {personalizadas.progressao && <PDFOrderP label='Distância de progressão' value={personalizadas.progressao} />}
                    {personalizadas.olho_dominante && <PDFOrderP label='Olho dominante' value={personalizadas.olho_dominante} />}
                </div>
            </div>
        )
    }
}

export default PDFOrderPersonalizados