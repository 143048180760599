import React, { Component } from "react";
import "./../pedido-atacado.css";
import { Badge } from "react-bootstrap";
import AppContext from "../../appContext";
import Input from "../../components/inputs/input";
import Select from "../../components/selects/select";

class PedidoAtacadoEtapaUm extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    PedidoAtacadoEtapaUm.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChange({ [event.target.name]: event.target.value });
  }

  handleChangeEmpresa = evt => {
    const { name, value, type } = evt.target;
    let selectedText;
    if (type === "select-one") {
      const selectedIndex = evt.target.selectedIndex;
      selectedText = evt.target?.options?.[selectedIndex]?.text || value;
    } else if (type === "text" || type === "input") {
      selectedText = value;
    }
    const removeMask = cnpj => cnpj.replace(/[^\d]/g, "");

    if (name === "dados_empresa_faturamento_cnpj" && selectedText) {
      const cnpjWithoutMask = removeMask(value);
      this.props.onChange(
        {
          otica: {
            nome: selectedText,
            cnpj: value,
            redeOticas: true,
          },
          empresaFaturamento: selectedText,
          dados_empresa_faturamento: selectedText,
          dados_empresa_faturamento_cnpj: cnpjWithoutMask,
        });
    }
  };

  render() {
    const { otica, disableFields, redes = null, dados_empresa_faturamento_cnpj, redeOticas } = this.props;
    let contextAux = this.context;

    return (
      <>
        <p className="titulo-sessao">
          <Badge fill className="me-2">
            1
          </Badge>
          Insira os dados do pedido e solicitante
        </p>
        <div className="row mb-5">
          <div className="col-12 col-md-4">
            <Input
              name="numeroPedido"
              type="text"
              label="Nº do pedido da ótica*"
              aria="Preencha nº do pedido"
              width="100%"
              autocomplete="off"
              disabled={disableFields}
              readOnly={disableFields}
              onChange={this.handleChange}
              value={this.props.numeroPedido}
            />
          </div>
          <div className="col-12 col-md-8">
            {this.context.userSession.modulos.includes("Consulta Cupom") ? (
              <>
                <Input
                  name="dados_empresa_faturamento_cnpj"
                  type="text"
                  label="Empresa de faturamento*"
                  width="100%"
                  aria="Preencha a Empresa de faturamento"
                  value={otica.cnpj || ""}
                  onChange={this.handleChangeEmpresa}
                  max="18"
                  mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                />
              </>
            ) : (
              <>
                {(!redeOticas || !contextAux.userSession.redeOticas) && (
                  <Input
                    name="empresaFaturamento"
                    type="text"
                    label="Empresa de faturamento*"
                    width="100%"
                    aria="Preencha a Empresa de faturamento"
                    value={otica.nome || ""}
                    readOnly={true}
                    disabled={disableFields}
                    onChange={this.handleChange}
                  />
                )}
                {redeOticas && contextAux.userSession.redeOticas && (
                  <Select
                    label="Empresa de faturamento*"
                    width="100%"
                    id="selectEmpresaFaturamento"
                    name="dados_empresa_faturamento_cnpj"
                    itens={redes}
                    value={dados_empresa_faturamento_cnpj || ""}
                    onChange={this.handleChangeEmpresa}
                    aria="Selecione a Empresa de faturamento"
                    placeholder="Selecione a Empresa de faturamento"
                    placeholder_dont_display={true}
                    required
                  />
                )}
              </>
            )}
          </div>
          {
            <div className="col-12 col-md-4">
              {this.props.PossuiProgramaFidelidadeProprio_c && (
                <Input
                  type="number"
                  name="codigoParticipante"
                  label="Código do Participante"
                  width="100%"
                  aria="Código do Participante"
                  tooltip="Informe o seu número do programa de fidelidade do laboratório (caso participe)."
                  value={this.props.codigoParticipante}
                  onChange={this.handleChange}
                />
              )}
            </div>
          }
        </div>
      </>
    );
  }
}

export default PedidoAtacadoEtapaUm;
