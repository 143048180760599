import React, { Component } from 'react'
import './modal-preview.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
	Modal,
	Container,
	Row,
	Col,
} from 'react-bootstrap'

class MediaCenterModalPreview extends Component {
	constructor(props) {
		super(props)

		this.state = {
			show_preview: false,
		}
	}

	componentDidMount() {

	}

	render() {
		const { use_logo, logo } = this.props

		return (
			<div className="media-preview">
				<Modal
					show={this.props.show_preview}
					onHide={this.props.handleClosePreview}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							{this.props.nome}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row>
								<Col sm={12} md={12}>

									<div className="media-center-preview">
										{(this.props.previewList && this.props.previewList.length > 0) && (
											<Carousel
												width='100%'
												showArrows={true}
												showStatus={false}
												showThumbs={false}
												showIndicators={true}
												infiniteLoop={true}
												emulateTouch={true}
												swipeable={true}
												{...this.props}
											>
												{this.props.previewList.map((item) => {
													return (
														<div>
															<img className="media-center-img-preview" src={'data:image/png;base64,' + `${item.base}`} alt="" />
														</div>
													)
												})}
											</Carousel>
										)}

										{(!this.props.previewList || (this.props.previewList && this.props.previewList.length == 0)) && (
											<img className="media-center-img-preview" src={this.props.url_preview ? this.props.url_preview : this.props.url_miniatura} alt="" />
										)}
									</div>

								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

export default MediaCenterModalPreview
