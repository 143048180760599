import React, { Component } from 'react';
import XLSX from 'xlsx';
import { BsDownload } from 'react-icons/bs';

class DownloadXLSX extends Component {
	constructor(props) {
		super(props);
		this.downloadXLSX = this.downloadXLSX.bind(this);
	}

	convertArrayOfObjectsToSheet() {
		const columns = Object.keys(this.props.data[0]);
		const ws = XLSX.utils.json_to_sheet(this.props.data, { header: columns });

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

		return wb;
	}

	downloadXLSX = () => {

		const wb = this.convertArrayOfObjectsToSheet(this.props.data);
		const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		function s2ab(s) {
			const buf = new ArrayBuffer(s.length);
			const view = new Uint8Array(buf);
			for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}

		const blob = new Blob([ s2ab(wbout) ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = this.props.fileName || 'data.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	render() {
		return (
			<>
				{ this.props.linkName && !this.props?.download && (
					<a className="tagADownloadXLSX" onClick={ this.downloadXLSX }>
						{ this.props.linkName }
					</a>
				) }
				{ this.props.BsDownload && (
					<td className={ "financeiro-table-td" }>
						<a title="Baixar Detalhes do Boleto" onClick={ this.props.onClick }>
              <span>
                <BsDownload color="royalblue" size={ 24 }/>
              </span>
						</a>
					</td>
				) }
				{ this.props.download && (
					<td className={ "linkBold" }>
						<a onClick={ this.props.onClick }>
							{ this.props.linkName }
						</a>
					</td>
				) }
			</>
		);
	}
}

export default DownloadXLSX;
