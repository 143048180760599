import React, { Component } from "react";
import TableParLine from "./consultar-tabela-linha-par";

class TabelaPares extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tabela-pares-main">
        <table className="table table-sm text-center text-uppercase table-borderless">
          <thead>
            <tr style={{ backgroundColor: "#E5EDF3" }}>
              <th colSpan="4">informação par:</th>
            </tr>
            <tr className="table-active">
              <th>par</th>
              <th>rpl</th>
              <th>serviço</th>
              <th>status</th>
            </tr>
          </thead>

          <tbody>
            {this.props.data.pares.map(par => {
              return <TableParLine key={par.idPar} par={par} voucherId={this.props.data.id} lab={this.props.lab} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TabelaPares;
