import React, { Component } from "react";

//Import CSS

//Import internal components

//Import external components

//Import images

class Glasses extends Component {
  render() {
    let Glasses;

    if (this.props.glasses == "0") {
      Glasses = <img src={require("../../img/medidas.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "1") {
      Glasses = <img src={require("../../img/1/vertical.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "2") {
      Glasses = <img src={require("../../img/1/horizontal.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "3") {
      Glasses = <img src={require("../../img/1/diametro.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "4") {
      Glasses = <img src={require("../../img/1/ponte.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "5") {
      Glasses = <img src={require("../../img/2/vertical.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "6") {
      Glasses = <img src={require("../../img/2/horizontal.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "7") {
      Glasses = <img src={require("../../img/2/diametro.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "8") {
      Glasses = <img src={require("../../img/2/ponte.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "9") {
      Glasses = <img src={require("../../img/3/vertical.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "10") {
      Glasses = <img src={require("../../img/3/horizontal.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "11") {
      Glasses = <img src={require("../../img/3/diametro.png")} alt="Armação com exemplo de medida" />;
    }
    if (this.props.glasses == "12") {
      Glasses = <img src={require("../../img/3/ponte.png")} alt="Armação com exemplo de medida" />;
    }

    return (
      <div className="x-web-ordering-pedido__frame--body is--glasses">
        {Glasses}
        <span style={{ color: "#424b4a", fontSize: "0.6rem", fontFamily: "'Montserrat', sans-serif", width: "405px" }} className="text-center mt-4">
          A identificação das distâncias é meramente ilustrativa. A depender do modelo da armação escolhido, as medidas podem não corresponder à imagem acima.
        </span>
      </div>
    );
  }
}

export default Glasses;
