async function getRedes(body) {
  let redes = [];
  var result = false;
  try {
    if (body?.consultaRede) {
      var requestOptions = null;

      if (body?.otica) {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: body.laboratorio,
            otica: body.otica,
          },
        };
      } else {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: body.laboratorio,
          },
        };
      }
      await fetch(process.env.REACT_APP_ESSILOR_API + "gestao-laboratorios/v1/redes", requestOptions)
        .then(response => {
          result = response.status;
          return response.json();
        })
        .then(json => {
          if (json.participantes) {
            if (result === 200) {
              redes = {
                status: result,
                redes: json.participantes.map((value, index) => ({
                  label: value.descricao,
                  value: value.cnpj,
                })),
              };
              result = true;
            }
          }
          
        });
    }
  } catch (error) {
    throw error;
  }
  if (result !== true) {
    redes = {
      status: result,
      redes: [
        {
          label: body.usuario.apelidoConta,
          value: body.usuario.userSession.conta,
        },
      ],
    };
  }
  return redes;
}

export { getRedes };
