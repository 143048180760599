import React, { Component } from 'react'
import './reset.css'
import ResetTop from './reset-top'
import Content from '../index/index-content'
import Carousel from '../index/carousel'
import Footer from '../main/footer'

class Reset extends Component {

  	render(){
    	return (
      	<div className='reset' id="main-body">
            <main className='reset-main'>
                <ResetTop></ResetTop>
				<div className="reset-bottom">
      		    	<Content></Content>
					<Carousel></Carousel>
				</div>
            </main>
			<Footer></Footer>
      	</div>
    	);
  	}
}

export default Reset;