import React, { Component } from "react";
import MultipleSelect from "../../components/selects/select-chip";
import SelectGroupMulti from "../../components/selects/select-group-multi";
import AppContext from "../../appContext";

class Filtros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
    this.childRefs = [];
    Filtros.contextType = AppContext;
  }
  cleanFilters() {
    this.props.cleanFilters();
    this.childRefs.forEach(ref => {
      if (ref?.state.personName?.length >= 1) {
        ref.handleClear();
      }
    });
  }
  
  render() {

    return (

      <div>
        {!this.context.userSession?.papelCategoria.includes("LAB")&& (
          <div className="col-2">
            <MultipleSelect label="Laboratório" options={this.props.laboratorio} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
          </div>
        )}
        <div className="col-2">
          <MultipleSelect ref={ref => this.childRefs.push(ref)} label={"Ano"} options={this.props.anoLabel} filter={this.props.filter} />
        </div>

        <div className="col-2">
          <SelectGroupMulti label="Mês" options={this.props.mes} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
        </div>

        {this.props.origem && (
          <div className="col-2">
            <MultipleSelect label="Origem" options={this.props.canal} filter={this.props.filter} ref={ref => this.childRefs.push(ref)} />
          </div>
        )}

        <div className="col-12 CleanAnchorFilter m-3">
          <a className="CleanAnchorFilter" onClick={() => this.cleanFilters("")}>
            Limpar Filtros
          </a>
        </div>
      </div>
    );
  }
}

export default Filtros;
