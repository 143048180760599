import React, { Component } from "react";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,LabelList } from "recharts";
import "../index.css";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
class DoubleBarGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedB: false,
    };

    this.getMidMonthPerYear = this.getMidMonthPerYear.bind(this);
  }
  getMidMonthPerYear(data) {
    const groupedByYear = data.reduce((acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = [];
      }
      acc[item.year].push(item);
      return acc;
    }, {});

    const midMonths = Object.keys(groupedByYear).reduce((acc, year) => {
      const months = groupedByYear[year];
      const midIndex = Math.floor(months.length / 2);
      const midMonthDate = new Date(months[midIndex].date);
      const midMonthIndex = midMonthDate.getUTCMonth() + 1;
      acc[year] = {
        startMonth: new Date(months[0].date).getUTCMonth() + 1,
        midMonth: midMonthIndex,
      };
      return acc;
    }, {});

    return midMonths;
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const monthTickFormatter = tick => {
      const date = new Date(tick + "T00:00:00Z");
      return date.toLocaleString("pt-BR", { month: "short", timeZone: "UTC" });
    };

    const renderQuarterTick = tickProps => {
      const delimita = this.getMidMonthPerYear(this.props.data);
      const { x, y, payload } = tickProps;
      const { value, offset } = payload;
      const date = new Date(value + "T00:00:00Z");
      const ano = date.getUTCFullYear();
      const mes = date.getUTCMonth() + 1;
      if (mes == delimita[ano].midMonth) {
        return <text x={x} y={y - 2} textAnchor="middle">{`${ano}`}</text>;
      }
      if (mes == delimita[ano].startMonth) {
        const pathX = Math.floor(x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="#888585" strokeWidth="1" />;
      }
      return null;
    };
    const labelStyle = {
      fill: "white",
      stroke: "black",
      strokeWidth: 0.5,
      fontWeight: "bold",
      fontSize:"small"
    };
 const formatNumber = number => {
        return number?.toLocaleString("pt-BR");
      };
    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

    const getPercent = (value, total) => {
      const ratio = total > 0 ? value / total : 0;
    
      return toPercent(ratio, 2);
    };
    
    const renderTooltipContent = (o) => {
      const { payload, label } = o;
      const total = payload.reduce((result, entry) => result + entry.value, 0);
     
      return (
        <div className="customized-tooltip-content">
          <p className="total">{`${label} (Total: ${total.toLocaleString('pt-BR')})`}</p>
          <br/>
          <ul className="list">
            {payload.map((entry, index) => (
              <li key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${entry.value.toLocaleString('pt-BR')} (${getPercent(entry.value, total)})`}
              </li>
            ))}
          </ul>
        </div>
      );
    };

    return (
      <ResponsiveContainer width="100%"  id="responsiveContainer" height={250}>
        <div>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={this.state.checkedB} onChange={this.handleChange} name="checkedB" color="primary" />} label="Dados" />
          </FormGroup>
        </div>
        <div className="graph-titulo">
          <p>{this.props.titulo}</p>
        </div>
        <BarChart
          width={500}
          height={300}
          data={this.props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
          <XAxis dataKey="date" axisLine={false} tickLine={false} interval={0} tick={renderQuarterTick} height={1} scale="band" xAxisId="quarter" />
          <YAxis />
          <Tooltip content={renderTooltipContent} />
          <Legend wrapperStyle={{ paddingTop: 20, fontWeight:"bold" }} />
          <Bar dataKey="Pedidos Promocionais" fill="#002060" activeBar={<Rectangle fill="#009dff" stroke="blue" />}>
            {this.state.checkedB && <LabelList dataKey="Pedidos Promocionais" position="Top" style={labelStyle} content={({ x, y, value, width, height }) => (
                  <text
                    dy={+10}
                    style={labelStyle}
                    x={x + width / 2} 
                    y={y + height / 2}
                    textAnchor="middle" >
                    {formatNumber(value)}
                  </text>
                )}/>}
          </Bar>
          <Bar dataKey="Aprovações" fill="#00b050" activeBar={<Rectangle fill="#00f76f" stroke="purple" />}>
            {this.state.checkedB && <LabelList dataKey="Aprovações" position="Top" style={labelStyle} content={({ x, y, value, width, height }) => (
                  <text
                    dy={+10}
                    style={labelStyle}
                    x={x + width / 2} 
                    y={y + height / 2}
                    textAnchor="middle" >
                    {formatNumber(value)}
                  </text>
                )}/>}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
export default DoubleBarGraph;
