import React, { Component } from 'react'

//Import CSS
import './check-box.css'

//Import internal components

//Import external components

//Import images

class CheckBox extends Component {


	render() {

      	return (
            <div className="check-box-container" style={{width:this.props.width}} >
				<input
					type="checkbox"
					name={this.props.name}
					id={this.props.id}
					value={this.props.value}
					onClick={this.props.onClick}
					onChange={this.props.onChange}
					checked={this.props.checked}
				/>
                <label className="check-box-label" htmlFor={this.props.id}>
                    {this.props.label}
                </label>

            </div>
		)
  	}
}

export default CheckBox