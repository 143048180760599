import React, { Component } from "react";
import AppContext from "../appContext";
import SignupLeft from "./signup-left";
import SignupBody from "./signup-body";
import Input from "../components/inputs/input";
import CustomButton from "../components/buttons/button";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import CnpjUtils from "../shered/utils/CnpjUtils";
import showSupportAlertMessage from "../components/alerts/SupportAlertMessage";
import MESSAGES from "../shered/constants/messages";

class SignupAccountCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cnpj: "",
      redirectH: false,
      opacity: "signup-right",
      loading: false,
      otica: "",
      signup_title: "Conecte-se com a gente",
      signup_paragraph:
        "Cadastre-se no Essilor Conecta e garanta mais conhecimento e benefícios para o seu negócio, além de experiências incríveis para seus clientes!",
      signup: false,
      invalid_account: false,
      invalid_account_number: false,
      invalid_message: "",
    };

    SignupAccountCheck.contextType = AppContext;
    this.redirectHome = this.redirectHome.bind(this);
    this.handleCNPJChange = this.handleCNPJChange.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.setBorder = this.setBorder.bind(this);
  }

  redirectHome(e) {
    e.preventDefault();
    this.setState({ redirectH: true });
  }

  handleCNPJChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

	nextButton(e) {
    e.preventDefault();

		const cnpj = CnpjUtils.cleanCnpj(this.state.cnpj);

		this.setState({
      opacity: "signup-right signup-main-opacity",
      loading: true,
    });

    this.context.showLoading("");

    if (cnpj.length === 14) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cnpj: cnpj }),
      };
      fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.status === 200) {
            if (
              !json.tipo ||
              json.tipo === "FORNECEDOR" ||
              json.tipo === "DISTRIBUIDOR" ||
              json.tipo === "EMPREGADOS" ||
              json.tipo === "CONSUMIDORES" ||
              json.tipo === "BALCONISTA" ||
              json.tipo === "CLINICAS E HOSPITAIS"
            ) {
							showSupportAlertMessage(
								`${ MESSAGES.CANNOT_CREATE_LINK }`,
								`${ cnpj }`
							).then(() => {
								this.setState({ redirectH: true });
							});
            } else {
              this.setState({
                opacity: "signup-right",
                loading: false,
                otica: json.nome,
                signup_title: "Falta pouco!",
                signup_paragraph: "Complete seu cadastro e você terá acesso a um mundo de oportunidades no Essilor Conecta.",
                signup: true,
              });
              this.handleClickNext();
            }
            //fechando loading
            this.context.hideLoading();
          } else {
            this.setState({
              opacity: "signup-right",
              loading: false,
              invalid_account: true,
              invalid_message: MESSAGES.CNPJ_NOT_FOUND,
            });
            //fechando loading
            this.context.hideLoading();
          }
        });
    } else {
      this.setState({
        opacity: "signup-right",
        loading: false,
        invalid_account_number: true,
        invalid_message: "Por favor, informe um CNPJ válido.",
      });
      //fechando loading
      this.context.hideLoading();
    }
  }

  handleClickNext() {
    ReactGA.event({
      category: "button",
      action: "click",
      label: "próximo",
    });
  }

  setBorder(e) {
    const { id } = e.target;
    document.getElementById(id).style.borderColor = "red";
  }

  render() {
    const { redirectH, cnpj, redirectL } = this.state;

    const { onlyS9 } = this.props;

    if (redirectH) {
      return <Redirect to="/" />;
    }

    if (redirectL) {
      window.location.href = "/";
    }

    return (
      <div className="row m-0">
        <div className="col-12 col-xl-5 p-0">
          <SignupLeft signup_title={this.state.signup_title} signup_paragraph={this.state.signup_paragraph} />
        </div>
        <div className="col-12 	col-xl-7 p-0">
          {!this.state.signup && (
            <div className={this.state.opacity}>
              <div className="signup-cnpj">
                <h2 className="signup-cnpj-title">Informe o CNPJ da empresa em que você trabalha:</h2>
              </div>
              <div>
                <form>
                  <Input
                    label="CNPJ*"
                    type="text"
                    name="cnpj"
                    id="cnpj"
                    aria="CNPJ"
                    autocomplete="off"
                    defaultValue="cnpj"
                    value={cnpj}
                    required={true}
                    width="100%"
                    onChange={this.handleCNPJChange}
                    onInput={this.handleCNPJChange}
                    onInvalid={this.setBorder}
                    placeholder="00.000.000/0000-00"
                    title="ex:. 00.000.000/0000-00"
                    mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                  />
                  {(this.state.invalid_account || this.state.invalid_account_number) && <small style={{ color: "red" }}>{this.state.invalid_message}</small>}

                  <div className="row mt-4">
                    <div className="col-8">
                      <a href="/#" className="signup-right__next-step--login" onClick={this.redirectHome}>
                        Já tenho uma conta
                      </a>
                    </div>
                    <div className="col-4">
                      <CustomButton
                        label="Próximo"
                        orange_button={false}
                        width="186px"
                        height="3rem"
                        marginTop="0"
                        onClick={this.nextButton}
                        icon_search={false}
                        icon_arrow_left={false}
                        icon_arrow_right={true}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          {this.state.signup && <SignupBody otica={this.state.otica} cnpj={this.state.cnpj} onlyS9={onlyS9} />}
        </div>
      </div>
    );
  }
}

export default SignupAccountCheck;
