import React, { Component } from "react";

import "./precos-e-servicos-card.css";
import AppContext from "../../appContext";

import {
	FaFileWord,
	FaFileExcel,
	FaFilePowerpoint,
	FaFilePdf,
	FaFileArchive,
	FaFileImage,
	FaFileAlt,
	FaFile
} from "react-icons/fa";
import DelectDoc from "../deleteFile";
import Swal from "sweetalert2";

class PrecosEServicosFileCard extends Component {
	constructor(_) {
		super(undefined);

		PrecosEServicosFileCard.contextType = AppContext;

		this.state = {
			extensionImg: "",
			extensionImgValue: "",
			downloadProgress: 0,
			isDownloading: false
		};

		this.downloadFile = this.downloadFile.bind(this);
		this.downloadFileChunked = this.downloadFileChunked.bind(this);
	}

	componentDidMount() {
	}

	downloadFile() {
		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				access_token: localStorage.getItem("token"),
				folder: this.props.file.folder,
				file: this.props.file.filename,
			}),
		};

		this.context.showLoading("Carregando arquivo...");

		fetch(process.env.REACT_APP_LABS_API + `download_file_financial.php`, requestOptions)
			.then(res => {
				return res.blob();
			})
			.then(blob => {
				this.context.hideLoading();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = this.props.file.filename;
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
			})
			.catch(_ => {
				this.context.hideLoading();
				Swal.fire({
					icon: "error",
					title: "Erro ao fazer o download do arquivo. Por favor, tente novamente mais tarde.",
					confirmButtonColor: "#00518b",
					customClass: {
						confirmButton: "x-follow-order__filter-btn",
					},
				});
			});
	}

	async downloadFileChunked() {
		try {
			this.setState({ isDownloading: true, downloadProgress: 0 });

			this.context.showLoading("Carregando arquivo... (0%)");

			const requestOptions = {
				method: "POST",
				body: JSON.stringify({
					access_token: localStorage.getItem("token"),
					folder: this.props.file.folder,
					file: this.props.file.filename,
					partNumber: 0
				}),
			};

			const fileInfoResponse = await fetch(process.env.REACT_APP_LABS_API + `download_file_chunked.php`, requestOptions);

			if (!fileInfoResponse.ok) {
				throw new Error("Não foi possível obter informações do arquivo");
			}

			const fileInfo = await fileInfoResponse.json();

			const { contentType, contentLength, totalParts, filename } = fileInfo;

			const parts = [];

			const downloadPhasePercentage = 90;

			for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
				const downloadPercentage = (partNumber / totalParts) * downloadPhasePercentage;
				const roundedProgress = Math.round(downloadPercentage);

				this.context.showLoading(`Carregando arquivo... (${ roundedProgress }%)`);
				this.setState({ downloadProgress: roundedProgress });

				const partRequestOptions = {
					method: "POST",
					body: JSON.stringify({
						access_token: localStorage.getItem("token"),
						folder: this.props.file.folder,
						file: this.props.file.filename,
						partNumber: partNumber
					}),
				};

				const partResponse = await fetch(process.env.REACT_APP_LABS_API + `download_file_chunked.php`, partRequestOptions);

				if (!partResponse.ok) {
					throw new Error(`Falha ao baixar parte ${ partNumber }`);
				}

				const partData = await partResponse.json();

				if (partData.status === 'success') {
					const binaryString = atob(partData.data);
					const len = binaryString.length;
					const bytes = new Uint8Array(len);

					for (let i = 0; i < len; i++) {
						bytes[i] = binaryString.charCodeAt(i);
					}

					parts.push(bytes);
				} else {
					throw new Error(`Erro ao baixar parte ${ partNumber }: ${ partData.message }`);
				}
			}

			this.context.showLoading("Carregando arquivo... (95%)");
			this.setState({ downloadProgress: 95 });

			let totalLength = 0;
			parts.forEach(part => {
				totalLength += part.length;
			});

			const fileData = new Uint8Array(totalLength);
			let offset = 0;

			parts.forEach(part => {
				fileData.set(part, offset);
				offset += part.length;
			});

			this.context.showLoading("Carregando arquivo... (100%)");
			this.setState({ downloadProgress: 100 });

			const blob = new Blob([ fileData ], { type: contentType });

			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.style.display = "none";
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();

			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);

			this.context.hideLoading();
			this.setState({ isDownloading: false, downloadProgress: 0 });

			Swal.fire({
				icon: "success",
				title: "Download concluído com sucesso!",
				confirmButtonColor: "#00518b",
				customClass: {
					confirmButton: "x-follow-order__filter-btn",
				},
			});

		} catch (error) {
			console.error("Erro no download em partes:", error);
			this.context.hideLoading();
			this.setState({ isDownloading: false, downloadProgress: 0 });

			Swal.fire({
				icon: "error",
				title: "Erro ao fazer o download do arquivo.",
				text: "Por favor, tente novamente mais tarde.",
				confirmButtonColor: "#00518b",
				customClass: {
					confirmButton: "x-follow-order__filter-btn",
				},
			});
		}
	}

	render() {
		var filename = this.props.file.filename;
		var ultimoPonto = filename.lastIndexOf(".");
		var extensao = filename.substring(ultimoPonto + 1);
		switch (extensao) {
			case "pdf":
				break;
			case "docx":
				break;
			case "doc":
				break;
			case "txt":
				break;
			case "pptx":
				break;
			case "ppt":
				break;
			case "xlsx":
				break;
			case "xls":
				break;
			case "zip":
				break;
			case "rar":
				break;
			case "png":
				break;
			case "jpeg":
				break;
			case "jpg":
				break;
			case "gif":
				break;
			default:
				extensao = "";
				break;
		}
		return (
			<div>
				<div className="precos-e-servicos-file-card">
					{ this.props.gestaoArquivos === true &&
						<DelectDoc
							getFolders={ this.props.getFolders }
							arquivo={ this.props.file.filename }
							pasta={ this.props.file.folder }
						/>
					}
					<div className="precos-e-servicos-file-card-field flex-column flex-row--mobile">
						<div className="precos-e-servicos-file-card-bg">
							{ (extensao === "docx" || extensao === "doc") && <FaFileWord size={ 100 } color={ "#295394" }/> }
							{ (extensao === "xlsx" || extensao === "xls") && <FaFileExcel size={ 100 } color={ "#2D7930" }/> }
							{ extensao === "pdf" && <FaFilePdf size={ 100 } color={ "#FA0F00" }/> }
							{ (extensao === "pptx" || extensao === "ppt") && <FaFilePowerpoint size={ 100 } color={ "#CB4424" }/> }
							{ (extensao === "rar" || extensao === "zip") && <FaFileArchive size={ 100 } color={ "#F7BF39" }/> }
							{ (extensao === "png" || extensao === "jpeg" || extensao === "jpg" || extensao === "gif") &&
								<FaFileImage size={ 100 } color={ "#4E8BED" }/> }
							{ extensao === "txt" && <FaFileAlt size={ 100 } color={ "#101E36" }/> }
							{ extensao === "" && <FaFile size={ 100 } color={ "#101E36" }/> }
						</div>

						<small
							className="precos-e-servicos-file-card-text text display-contents--mobile">{ this.props.file.filename || "" }</small>
					</div>

					<div className="precos-e-servicos-file-card-field">
						<button className="precos-e-servicos-file-card-button" onClick={ this.downloadFileChunked }
										disabled={ this.state.isDownloading }>
							<small className="precos-e-servicos-file-card-text is-text--lg">
								{ this.state.isDownloading
									? `Baixando (${ this.state.downloadProgress }%)`
									: "Baixar arquivo" }
							</small>
							<svg className="precos-e-servicos-file-card-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
									 viewBox="0 0 30 30">
								<g id="Group_90" data-name="Group 90" transform="translate(30) rotate(90)">
									<circle id="Ellipse_9" data-name="Ellipse 9" cx="15" cy="15" r="15" fill="#fff"/>
									<path
										id="np_arrow_2957554_000000"
										d="M6.479,10.7l4.832-9.431A.876.876,0,0,0,9.993.188L6.246,3.134a.822.822,0,0,1-1.059,0L1.414.188A.877.877,0,0,0,.1,1.273L4.928,10.7a.879.879,0,0,0,1.551,0Z"
										transform="translate(10.48 21.5) rotate(-90)"
										fill="#0394db"
									/>
								</g>
							</svg>
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default PrecosEServicosFileCard;