import React, { Component } from 'react'

export const eye = 	<svg
						xmlns="http://www.w3.org/2000/svg"
						width="21.288"
						height="18.215"
						viewBox="0 0 21.288 18.215"
					>
						<g id="icon" transform="translate(0 0)">
							{/* <defs>
								<style>
									.a {fill: #0066b3;}
								</style>
							</defs> */}
							<path
								id="np_hide-password-open-eye_2540385_000000"
								d="M9.486,20.882,8.7,20.427a16.153,16.153,0,0,1-1.585-1.134c-1.551-1.253-2.95-2.855-2.95-3.355s1.4-2.1,2.95-3.355A10.959,10.959,0,0,1,13.81,9.8a9.205,9.205,0,0,1,4.324,1.193l.785.455A16.153,16.153,0,0,1,20.5,12.582c1.551,1.253,2.95,2.855,2.95,3.355s-1.4,2.1-2.95,3.355a10.959,10.959,0,0,1-6.694,2.782A9.205,9.205,0,0,1,9.486,20.882Zm12.973-4.824c.069-.1.119-.193.119-.121S22.333,16.24,22.459,16.058Zm-17.3-.242c-.069.1-.119.193-.119.121S5.288,15.635,5.162,15.817Z"
								transform="translate(-2.167 -3.86)"
								fill="#0066b3"
								opacity="0.07"
							/>
							<g id="np_view_1631966_000000" transform="translate(-6.3 -20.095)">
								<path
									id="Path_58"
									data-name="Path 58"
									d="M25.884,28.671,25.7,28.4a19.656,19.656,0,0,0-2.834-2.95,10.906,10.906,0,0,0-6.913-3,10.9,10.9,0,0,0-6.913,3A19.67,19.67,0,0,0,6.2,28.4l-.184.276.184.276A19.656,19.656,0,0,0,9.038,31.9a10.906,10.906,0,0,0,6.913,3,10.906,10.906,0,0,0,6.913-3,19.67,19.67,0,0,0,2.834-2.95Zm-9.932,5.3c-4.24,0-7.881-4.171-8.78-5.3.9-1.129,4.54-5.3,8.78-5.3s7.881,4.171,8.78,5.3C23.833,29.8,20.192,33.971,15.952,33.971Z"
									transform="translate(0)"
									fill="#0066b3"
								/>
								<path
									id="Path_59"
									data-name="Path 59"
									d="M36.887,33.062a3.825,3.825,0,1,0,2.7,1.12A3.826,3.826,0,0,0,36.887,33.062Zm0,6.729h0a2.88,2.88,0,1,1,2.06-.843,2.905,2.905,0,0,1-2.06.843Z"
									transform="translate(-20.936 -8.216)"
									fill="#0066b3"
								/>
							</g>
						</g>
					</svg>

export const eye_crossed = 	<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21.288" height="18.215"
								viewBox="0 0 21.288 18.215"
							>
								<g id="icon" transform="translate(0 0)">
									{/* <defs>
										<style>
											.a {{"fill": "#0066b3"}}
										</style>
									</defs> */}
									<path
										id="np_hide-password-closed-eye_2540385_000000"
										d="M9.486,20.882,5.353,25.015l-.62-.62L8.7,20.427a16.153,16.153,0,0,1-1.585-1.134c-1.551-1.253-2.95-2.855-2.95-3.355s1.4-2.1,2.95-3.355A10.959,10.959,0,0,1,13.81,9.8a9.205,9.205,0,0,1,4.324,1.193L22.268,6.86l.62.62L18.92,11.448A16.153,16.153,0,0,1,20.5,12.582c1.551,1.253,2.95,2.855,2.95,3.355s-1.4,2.1-2.95,3.355a10.959,10.959,0,0,1-6.694,2.782A9.205,9.205,0,0,1,9.486,20.882Zm.65-.651a8.2,8.2,0,0,0,3.674.967,10.141,10.141,0,0,0,6.143-2.588,16.192,16.192,0,0,0,1.975-1.886,7.955,7.955,0,0,0,.53-.666c.069-.1.119-.193.119-.121s-.05-.021-.119-.121a7.966,7.966,0,0,0-.53-.666,16.191,16.191,0,0,0-1.975-1.886,15.092,15.092,0,0,0-1.672-1.179l-1.7,1.7a3.507,3.507,0,0,1-4.921,4.921Zm-.8-.442,1.7-1.7a3.507,3.507,0,0,1,4.921-4.921l1.524-1.523a8.2,8.2,0,0,0-3.674-.967,10.141,10.141,0,0,0-6.143,2.588,16.192,16.192,0,0,0-1.975,1.886,7.955,7.955,0,0,0-.53.666c-.069.1-.119.193-.119.121s.05.021.119.121a7.966,7.966,0,0,0,.53.666A16.191,16.191,0,0,0,7.668,18.61a15.092,15.092,0,0,0,1.672,1.179Zm2.948-1.708a2.63,2.63,0,0,0,3.668-3.668Zm-.62-.62,3.668-3.668a2.63,2.63,0,0,0-3.668,3.668Z"
										transform="translate(-4.167 -6.86)"
										fill="#0066b3"
									/>
									<path
										id="np_hide-password-closed-eye_2540385_000000-2"
										data-name="np_hide-password-closed-eye_2540385_000000"
										d="M9.486,20.882,8.7,20.427a16.153,16.153,0,0,1-1.585-1.134c-1.551-1.253-2.95-2.855-2.95-3.355s1.4-2.1,2.95-3.355A10.959,10.959,0,0,1,13.81,9.8a9.205,9.205,0,0,1,4.324,1.193l.785.455A16.153,16.153,0,0,1,20.5,12.582c1.551,1.253,2.95,2.855,2.95,3.355s-1.4,2.1-2.95,3.355a10.959,10.959,0,0,1-6.694,2.782A9.205,9.205,0,0,1,9.486,20.882Zm12.973-4.824c.069-.1.119-.193.119-.121S22.333,16.24,22.459,16.058Zm-17.3-.242c-.069.1-.119.193-.119.121S5.288,15.635,5.162,15.817Z" transform="translate(-2.167 -3.86)"
										fill="#0066b3"
										opacity="0.07"
									/>
								</g>
							</svg>