import React, { useState, Component } from "react";

//Import CSS
import "./user-menu.css";
//Import internal components
import CustomButton from "../../../components/buttons/button";

//Import external components
import Dropdown from "react-bootstrap/Dropdown";
import HeaderAvatar from "../header-avatar";
import Logout from "../logout";
import { Link } from "react-router-dom";
import AppContext from "../../../appContext";

//Import image

class UserMenu extends Component {

	constructor(props) {
		super(props)

		UserMenu.contextType = AppContext;
	}

	render() {
		const { acessaGestaoPapeis, hasMinhaOtica } = this.props;
		var CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
			<>
				<button
					className="user-menu-btn"
					id="headerCloseMenuUser"
					ref={ref}
					onClick={(e) => {
						e.preventDefault();
						onClick(e);
					}}
				>
					<HeaderAvatar />
					{/* <label className="header__menu-text" htmlFor="headerBtn">
                        Minha conta
                        {children}
                        &#x25bc;
                    </label> */}
				</button>
			</>
		));

		// forwardRef again here!
		// Dropdown needs access to the DOM of the Menu to measure it
		var CustomMenu = React.forwardRef(
			(
				{ children, style, className, "aria-labelledby": labeledBy },
				ref
			) => {
				const [value, setValue] = useState("");

				return (
					<div
						ref={ref}
						style={style}
						className={className}
						aria-labelledby={labeledBy}
					>
						<main className="user-menu-main text-center">
							<div className="row">
								<div className="col-12">
									<HeaderAvatar className="user-menu-img" />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<p>
										{this.context.userSession.nome} <br />
										<small>{this.context.userSession.email}</small>
									</p>
								</div>
							</div>
							<div className="row">
								<ul className="list-unstyled px-0">
									{React.Children.toArray(children).filter(
										(child) =>
											!value ||
											child.props.children
												.toLowerCase()
												.startsWith(value)
									)}
								</ul>
							</div>
							<div className="row justify-content-center ">
								<div className="div-width-logout px-0">
									<Link
										onClick={() => {
											document
												.getElementById(
													"headerCloseMenuUser"
												)
												.click();
										}}
									>
										<Logout />
									</Link>
								</div>
							</div>
						</main>
					</div>
				);
			}
		);

		return (
			<Dropdown className="user-menu">
				<Dropdown.Toggle
					as={CustomToggle}
					id="dropdown-custom-components"
				></Dropdown.Toggle>

				<Dropdown.Menu as={CustomMenu}>
					<div className="button-my-profile mt-3">
						<Link
							onClick={() => {
								document
									.getElementById(
										"headerCloseMenuUser"
									)
									.click();
							}}
							to="/profile"
						>
							<CustomButton
								className="button-my-profile"
								width="100%"
								height="2.3125rem"
								marginTop="0"
								label="Meu perfil" />
						</Link>
					</div>
					{(hasMinhaOtica() || acessaGestaoPapeis()) && (
						<hr></hr>
					)}
					{hasMinhaOtica() && (
						<div className="button-my-profile">
							<Link
								onClick={() => {
									document
										.getElementById(
											"headerCloseMenuUser"
										)
										.click();
								}}
								to="/empresa"
							>
								<CustomButton marginTop="1rem" width="100%" height="2.3125rem" label="Minha Empresa" />
							</Link>
						</div>
					)}
					{acessaGestaoPapeis() && (
						<div className="button-my-profile">
							<Link
								onClick={() => {
									document
										.getElementById(
											"headerCloseMenuUser"
										)
										.click();
								}}
								to="/roles"
							>
								<CustomButton
									marginTop="1rem"
									width="100%"
									height="2.3125rem"
									label="Gestão de Papéis"
								/>
							</Link>
						</div>
					)}
					<hr></hr>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

export default UserMenu;
