import React, { Component } from "react";

import "./filter-input.css";

class FilterInput extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { label, name, id, value, onChange, width, height, display, displayDiv, justifyContentDiv, alignItemsDiv, flexDirectionDiv, marginRight} = this.props;

    const inputStyle = {
      width: width,
      height: height,
      display: display,
    };
     
    const divStyle = {
      display: displayDiv,
      justifyContent: justifyContentDiv,
      alignItems: alignItemsDiv,
      flexDirection: flexDirectionDiv,
      marginRight: marginRight
    };
    return (
      
      <div className="filterContainerDivInput" style={divStyle} >
        <label className="filtroInputLabel">
          {label}
        </label>
        <div className="filtroInputDiv">
          <input type="text" id={id} name={name} value={value == undefined ? "" : value} onChange={onChange} style={inputStyle}
        className="filtroInput" />
        </div>
      </div>
    );
  }
}

export default FilterInput;
