import React, { Component } from 'react'

//Import CSS
import 'bootstrap/dist/css/bootstrap.min.css'

//Import internal components

//Import external components
import Card from 'react-bootstrap/Card'

//Import images

class OrderSummaryDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {

		const { order } = this.props

        return (

			<Card.Body>

				{order.promo_code &&
					<ul className="x-web-ordering-pedido__summary--card-text">
						<li className="x-web-ordering-pedido__summary--subitem-text title">Cupom Promocional</li>
						<li className="x-web-ordering-pedido__summary--subitem-text desc">{order.promo_code || '-'}</li>
					</ul>
				}

				{order.dados_promo_nome &&
					<ul className="x-web-ordering-pedido__summary--card-text">
						<li className="x-web-ordering-pedido__summary--subitem-text title">Nome da promoção</li>
						<li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_promo_nome || '-'}</li>
					</ul>
				}

                <ul className="x-web-ordering-pedido__summary--card-text">
                    <li className="x-web-ordering-pedido__summary--subitem-text title">Nº do pedido da ótica</li>
                    <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_num_pedido || '-'}</li>
                </ul>

                <ul className="x-web-ordering-pedido__summary--card-text">
                    <li className="x-web-ordering-pedido__summary--subitem-text title">Empresa de faturamento</li>
                    <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_empresa_faturamento || '-'}</li>
                </ul>

                {order.dados_nome_cliente &&
                    <ul className="x-web-ordering-pedido__summary--card-text">
                        <li className="x-web-ordering-pedido__summary--subitem-text title">Nome do Cliente</li>
                        <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_nome_cliente}</li>
                    </ul>
                }

                {order.dados_cpf_cliente &&
                    <ul className="x-web-ordering-pedido__summary--card-text">
                        <li className="x-web-ordering-pedido__summary--subitem-text title">CPF do Cliente</li>
                        <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_cpf_cliente_mask}</li>
                    </ul>
                }

				{order.dados_nome_medico &&
                    <ul className="x-web-ordering-pedido__summary--card-text">
                        <li className="x-web-ordering-pedido__summary--subitem-text title">Nome do Médico</li>
                        <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_nome_medico}</li>
                    </ul>
                }

                {order.dados_crm_medico &&
                    <ul className="x-web-ordering-pedido__summary--card-text">
                        <li className="x-web-ordering-pedido__summary--subitem-text title">CRM do Médico</li>
                        <li className="x-web-ordering-pedido__summary--subitem-text desc">{order.dados_crm_medico}</li>
                    </ul>
                }

            </Card.Body>

        )
    }
}

export default OrderSummaryDetail