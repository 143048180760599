import React, { Component } from "react";
import { ModalVerificaRedes } from "./modal-verifica-redes";
import AppContext from "../../appContext";

class InfoClienteResultCard extends Component {
  constructor(props) {
    super(props);
    InfoClienteResultCard.contextType = AppContext;
  }

  handleClick = async cnpj => {
    this.props.handleChangeProps(2);
    this.context.showLoading();
    setTimeout(() => {
      ModalVerificaRedes(cnpj, this.props.data.cnpj, this.context)
        .then(() => {
          this.context.hideLoading();
        })
        .catch(error => {
          console.error("Erro ao verificar redes:", error);
          this.context.hideLoading();
        });
    }, 1000);
  };

  formatCNPJ(cnpj) {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  render() {
    return (
      <div className="info-cliente-resultcard-main">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th scope="col" colspan="2">
                Resultado
              </th>
            </tr>
          </thead>

          <tbody>
            {(this.props.data.email || this.props.email_cliente) && this.props.search_method === "email" && (
              <tr>
                <td className="td-label">E-mail:</td>
                <td className="td-value">{this.props.data.email ? this.props.data.email : this.props.email_cliente}</td>
              </tr>
            )}
            <tr>
              <td className="td-label">Empresa:</td>
              <td className="td-value">{this.props.data.nome}</td>
            </tr>
            <tr>
              <td className="td-label">CNPJ:</td>
              <td className="td-value">{this.formatCNPJ(this.props.data.cnpj)}</td>
            </tr>
            <tr>
              <td className="td-label">Tipo Cliente:</td>
              <td className="td-value">{this.props.data.tipo}</td>
            </tr>
            <tr>
              <td className="td-label">Tipo Varilux Especialista:</td>
              <td className="td-value">{this.props.data.classificacao_varilux_especialista || "-"}</td>
            </tr>
            <tr>
              <td className="td-label" rowSpan={this.props.data.laboratorios.length}>
                Laboratórios:
              </td>
              <td className="td-value">
              {Boolean(this.props.data.laboratorios[0]?.descricao) ? (
                <div className="DivVerificarRedeLink">
                  {this.props.data.laboratorios[0]?.descricao}
                  <a className="LinkVerificaRede" href="#" onClick={() => this.handleClick(this.props.data.laboratorios[0])}>
                    Verificar Rede
                  </a>
                </div>) : (
                  <div className="DivVerificarRedeLink">
                  -
                </div>
                )
              }
              </td>
            </tr>
            {this.props.data.laboratorios.slice(1).map(lab => (
              <tr>
                <td key={lab.codigo} className="td-value">
                  <div className="DivVerificarRedeLink">
                    {lab.descricao}
                    <a className="LinkVerificaRede" href="#" onClick={() => this.handleClick(lab)}>
                      Verificar Rede
                    </a>
                  </div>
                </td>
              </tr>
            ))}
            <tr></tr>
            {this.props.email_cliente && this.props.folders && this.props.search_method === "email" ? (
              <>
                <tr>
                  <td className="td-label" rowSpan={this.props?.folders?.length + 1}>
                    Preços e Serviços:
                  </td>
                </tr>
                {this.props?.folders.slice(0).map(folder => (
                  <tr>
                    <td key={folder} className="td-value">
                      {folder}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              this.props.search_method === "email" && (
                <>
                  <tr>
                    <td className="td-label" rowSpan={2}>
                      Preços e Serviços:
                    </td>
                  </tr>
                  <tr>
                    <td className="td-value">-</td>
                  </tr>
                </>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InfoClienteResultCard;
