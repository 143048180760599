import React, { Component } from "react";
import pedidoslogo from "../img/atacado_fundo_branco.png";
import Barcode from "react-barcode";
import AppContext from "../../appContext";

class PDFOrderHeader extends Component {
	constructor() {
		super();
		PDFOrderHeader.contextType = AppContext;
	}

  render() {
    const { dados } = this.props;

    return (
      <div className="pdf-order-header row">
        <div className="col-6 text-left">
          <img src={pedidoslogo} width="178" height="120" alt="Logo Pedidos" />
        </div>
        <div className="col-6 text-end">
          <Barcode value={dados.pedido} height="70" text={"Nº do Pedido: " + dados.pedido} />
        </div>
        <div className="col-6">
          <p className="pdf-subtitle">
            <b>Laboratório:</b> <br />
            {dados.laboratorio}
          </p>
        </div>
		{dados.valor_pedido && this.context.userSession.modulos.includes("Pedidos Valores") && (
          <div className="col-6">
            <p className="pdf-subtitle">
              <b>Valor total do pedido:</b> <br />
              {dados.valor_pedido.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default PDFOrderHeader;
