function getPactoOtica(cnpj, laboratorio) {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        client_id: localStorage.getItem("clientid"),
        access_token: localStorage.getItem("token"),
        laboratorio: laboratorio.replaceAll(".", "").replace("/", "").replace("-", ""),
      },
    };
    let status = "";
    return fetch(process.env.REACT_APP_ESSILOR_API + "gestao-pedidos-laboratorio/v1/pacto/otica/" + cnpj.replaceAll(".", "").replace("/", "").replace("-", ""), requestOptions)
      .then(response => {
        status = response.status;
        return response.json();
      })
      .then( json => {
        if (status == 200) {
          const response = {
            status: status, mensagem: json 
          }
          return response;
        } else {
          return { status: 400 };
        }
      });
  } catch (error) {
    throw error;
  }
}

export { getPactoOtica };
