import React, { Component } from "react";
import AppContext from "../../appContext";
import { validateFields } from "../../components/utils/Validate-Fields";

import "./cadastral.css";
import Moment from "moment";
import Swal from "sweetalert2";
import Select from "../../components/selects/select";
import Button from "../../components/buttons/button";
import Script from "../script-situacao-cadastral"
class Cadastral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      laboratorio_cnpj: "",
      laboratorio: "",
      selected_option: "",
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      labs: "",
      datatable: [],
      searchTerm: "",
      filteredData: {
        IPREMIOS: {
          filtroRPL: "",
          filtroDescricaoOpticlick: "",
          filtroDescricaoTratamento: "",
          filtroTipoProducao: "",
          filtroCodigoLaboratorio: "",
          filtroCodigoTratamento: "",
          filtroStatus: "",
          items: {
            rpls: [],
          },
        },
        SHOP9: {
          filtroRPL: "",
          filtroLSA: "",
          filtroDescricaoOpticlick: "",
          filtroDescricaoTratamento: "",
          filtroTipoProducao: "",
          filtroCodigoLaboratorio: "",
          filtroCodigoTratamento: "",
          filtroStatus: "",
          items: {
            rpls: [],
          },
        },
      },
    };
    Cadastral.contextType = AppContext;
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.context.showLoading();
if (this.props.selected_option !== "cadastral" && this.state.selected_option !== "dados_encontrados") {
      this.props.handleChangeOption("cadastral");
    }
    if (this.context.userSession.papelCategoria !== "ESSILOR") {
      this.setState({
        selected_option: "SemBusca",
      });
      this.product();
    } else {
      this.setState({
        selected_option: "produtos",
      });
      this.context.hideLoading();
    }
  }

  product = labs => {
    if (this.context.userSession.papelCategoria !== "ESSILOR") {
      this.state.laboratorio_cnpj = this.context.userSession.conta;
    }

    this.context.showLoading("Carregando...");
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpj: this.state.laboratorio_cnpj,
        access_token: localStorage.getItem("token"),
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_rpls.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        let labErro = "";
        if (json.IPREMIOS?.message && json.SHOP9?.message) {
          labErro = "Essilor Promo e Shop9";
        } else if (json.IPREMIOS?.message) {
          labErro = "Essilor Promo";
        } else if (json.SHOP9?.message) {
          labErro = "Shop9";
        }
        if (json.SHOP9?.message || json.IPREMIOS?.message) {
          Swal.fire({
            html: "Não foi possível consultar a situação cadastral na base " + labErro + ".",
            title: "Aviso",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }

        const rpls = this.corrigiValorStatus(json);

        //retira o sla do ipremios para não aparecer no download
        if (rpls.IPREMIOS?.items?.rpls) {
          rpls.IPREMIOS.items.rpls.forEach(rpl => {
            delete rpl.lsa;
          });
        }

        this.setState(
          {
            datatable: rpls,
            filteredData: rpls,
            selected_option: this.context.userSession.papelCategoria !== "ESSILOR" ? "SemBusca" : "dados_encontrados",
          },
          () => {
            if (this.state.selected_option == "dados_encontrados" && this.state.TableDataRPL?.IPREMIOS?.items) {
              this.props.handleChangeOption(this.state.selected_option);
            }
          },
        );
        this.props.BaseDataTable(this.state.datatable, "dados_encontrados");

        this.context.hideLoading();
      });
  };

  corrigiValorStatus = json => {
    var quantidadeEncontrado = 0;
    var quantidadeNEncontrado = 0;
    var quantidadeDuplicada = 0;

    if (json.IPREMIOS.status.http_code == 200) {
      json.IPREMIOS.items.rpls.forEach(item => {
        if (item.status === "Localizado" || item.status === "OK" || item.status === "ENCONTRADO") {
          quantidadeEncontrado += 1;
        } else if (item.status === "NÃO ENCONTRADO") {
          quantidadeNEncontrado += 1;
        } else if (item.status === "DUPLICIDADE") {
          quantidadeDuplicada += 1;
        }
      });
      json.IPREMIOS.quantidadeEncontrado = quantidadeEncontrado;
      json.IPREMIOS.quantidadeNEncontrado = quantidadeNEncontrado;
      json.IPREMIOS.quantidadeDuplicada = quantidadeDuplicada;
      quantidadeEncontrado = 0;
      quantidadeNEncontrado = 0;

      const dataexpedicao = json.IPREMIOS.items?.dataExecucao.substring(0, 10);
      json.IPREMIOS.items.dataExecucao = dataexpedicao ? Moment(dataexpedicao).format("DD/MM/YY") : "";
    }
    if (json.SHOP9.status.http_code == 200) {
      json.SHOP9.items.rpls.forEach(item => {
        if (item.status === "Localizado" || item.status === "OK" || item.status === "ENCONTRADO") {
          quantidadeEncontrado += 1;
        } else if (item.status === "NÃO ENCONTRADO") {
          quantidadeNEncontrado += 1;
        } else if (item.status === "DUPLICIDADE") {
          quantidadeDuplicada += 1;
        }
      });
      json.SHOP9.quantidadeEncontrado = quantidadeEncontrado;
      json.SHOP9.quantidadeNEncontrado = quantidadeNEncontrado;
      json.SHOP9.quantidadeDuplicada = quantidadeDuplicada;

      //correção da data

      const dataexpedicaoShop9 = json.SHOP9.items?.dataExecucao.substring(0, 10);
      json.SHOP9.items.dataExecucao = dataexpedicaoShop9 ? Moment(dataexpedicaoShop9).format("DD/MM/YY") : "";
    }
    return json;
  };
  onChange(e) {
    const campoRecebido = document.getElementById("laboratorio_cnpj");
    this.setState({ laboratorio_cnpj: campoRecebido.value });
  }
  onClick = () => {
    // validação dos dados antes do envio para a proxima etapa
    let campos = [];

    const campo = [{ id: "laboratorio_cnpj", mensagem: "Laboratório" }];

    const camposValidados = validateFields(campo);
    if (camposValidados) this.product(this.state.laboratorio_cnpj);
  };
  render() {
    return (
      <div className="row mt-3 product-managemente-container">
        <div className="product-managemente-card">
          <div className="col-12">
            <h1 className="conecta-h1">
              <strong>Situação Cadastral</strong>
            </h1>
          </div>
          {this.state.selected_option !== "SemBusca" && this.state.selected_option !== "" && (
            <div className="col-12 cadastralBuscaColSelect">
              <Select
                id={this.props.id}
                label="Laboratório*"
                width="50%"
                name="laboratorio_cnpj"
                aria="Selecione um laboratório"
                itens={this.props.laboratorios}
                value={this.state.laboratorio_cnpj}
                onChange={this.onChange}
                placeholder="Selecione um laboratório"
                placeholder_dont_display={true}
                buttonSend={true}
                buttonSendMessege={"Buscar pedido"}
                required
              />
              <Button icon_search={true} label="Consultar" width={"30%"} marginTop={"23px"} onClick={this.onClick} />
              {this.context.userSession.modulos.includes("Script Situação Cadastral") &&(
                <Script laboratorios={this.props.laboratorios}/>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Cadastral;
