import React, { Component } from "react";

//Import CSS
import "./header.css";

//Import internal components
import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import HeaderFaq from "../../faq/header";

//Import external components
import Swal from "sweetalert2";

//Import image

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_loged: false,
      token: "",
      windowWidth: window.innerWidth,
    };
    this.handleResizeWindow = this.handleResizeWindow.bind(this);
  }

  componentDidMount = async () => {
    window.addEventListener("resize", () => this.handleResizeWindow(window.innerWidth));
    var token = await localStorage.getItem("token");
    if (token) {
      this.setState({ is_loged: true });
    }

    if (localStorage.getItem("token") != null) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ token: localStorage.getItem("token") }),
      };
      fetch(process.env.REACT_APP_LABS_API + "validate_user.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.status !== 200) {
            Swal.fire({
              text: "Sessão expirada!",
              icon: "warning",
              title: "Aviso",
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonColor: "#00518b",
              confirmButtonText: "Ok!",
            }).then(result => {
              if (result.value) {
                localStorage.removeItem("token");
                this.setState({
                  redirect: true,
                });
              }
            });
          }
        });
    }
  };

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    window.removeEventListener("resize", () => this.handleResizeWindow(window.innerWidth));
  }

  handleResizeWindow = event => {
    this.setState({ windowWidth: event });
  };

  render() {
    const breakpoint = 767;
    if (this.state.redirect) {
      window.location.href = "/";
    }
    if (this.state.is_loged) {
      return (
        <div>
          <header className="header" id="main-header">
            {this.state.windowWidth < breakpoint ? (
              <HeaderMobile lista_multi_contas={this.props.lista_multi_contas} />
            ) : (
              <HeaderDesktop lista_multi_contas={this.props.lista_multi_contas} />
            )}
          </header>
        </div>
      );
    } else {
      return (
        <div>
          <header className="header" id="main-header">
            <HeaderFaq />
          </header>
        </div>
      );
    }
  }
}

export default Header;
