import React from "react";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";

const GenericSuccessMessage = ({ message }) => (
	<div>
		{ message }
	</div>
);

const showGenericSuccessMessage = (message) => {
	const html = ReactDOMServer.renderToStaticMarkup(
		<GenericSuccessMessage message={ message }/>
	);

	return Swal.fire({
		html,
		icon: "success",
		title: "Pronto!",
		confirmButtonColor: "#00518b",
		allowOutsideClick: false,
		showCancelButton: false,
		confirmButtonText: "Ok!",
		customClass: {
			confirmButton: "x-follow-order__filter-btn",
		},
	});
};

export default showGenericSuccessMessage;
