import React, { Component } from 'react'
import PDFOrderMedidas from './pdf-order-medidas';
import PDFOrderPersonalizados from './pdf-order-personalizadas';
import PDFOrderPrisma from './pdf-order-prisma';
import PDFOrderReceita from './pdf-order-receita';

class PDFOrderPrescricao extends Component {

    constructor(props) {
        super(props)

        this.hasPrisma = this.hasPrisma.bind(this)
        this.hasMedida = this.hasMedida.bind(this)
        this.hasPersonalizada = this.hasPersonalizada.bind(this)
    }

    hasPrisma() {
        const { prescricao } = this.props;

        if (((prescricao.prisma.direito) && (prescricao.prisma.direito.horizontal || prescricao.prisma.direito.base_horizontal || prescricao.prisma.direito.base_vertical || prescricao.prisma.direito.vertical))
            || ((prescricao.prisma.esquerdo) && (prescricao.prisma.esquerdo.horizontal || prescricao.prisma.esquerdo.base_horizontal || prescricao.prisma.esquerdo.base_vertical || prescricao.prisma.esquerdo.vertical))
        ) {
            return true;
        } else {
            return false;
        }
    }

    hasMedida() {
        const { prescricao } = this.props;
        if (((prescricao.medidas.direito) &&
            (
                prescricao.medidas.direito.dnp ||
                prescricao.medidas.direito.altura ||
                prescricao.medidas.direito.dnp_perto ||
                prescricao.medidas.direito.cro ||
                prescricao.medidas.direito.vertice
            ))
            ||
            ((prescricao.medidas.esquerdo)
                && (prescricao.medidas.esquerdo.dnp ||
                    prescricao.medidas.esquerdo.altura ||
                    prescricao.medidas.esquerdo.dnp_perto ||
                    prescricao.medidas.esquerdo.cro ||
                    prescricao.medidas.esquerdo.vertice))) {
            return true;
        } else {
            return false;
        }
    }

    hasPersonalizada() {
        const { prescricao } = this.props;
        var response = false;

        if (
            prescricao.personalizadas.iniciais_cliente ||
            prescricao.personalizadas.distancia_leitura ||
            prescricao.personalizadas.cvp ||
            prescricao.personalizadas.pantoscopico ||
            prescricao.personalizadas.curvatura ||
            prescricao.personalizadas.coeficiente ||
            prescricao.personalizadas.estabilidade ||
            prescricao.personalizadas.progressao ||
            prescricao.personalizadas.olho_dominante
        ) {
            response = true;
        }

        return response
    }

    render() {
        const { prescricao, hasOlhoDireito, hasOlhoEsquerdo } = this.props;

        return (
            <div className="pdf-order-prescricao">
                <div>
                    <h1 className="pdf-title">Prescrição</h1>
                </div>

                <PDFOrderReceita
                    receita={prescricao.receita}
                    hasOlhoDireito={hasOlhoDireito}
                    hasOlhoEsquerdo={hasOlhoEsquerdo}
                />

                {this.hasPrisma() &&
                    (
                        <PDFOrderPrisma
                            prisma={prescricao.prisma}
                            hasOlhoDireito={hasOlhoDireito}
                            hasOlhoEsquerdo={hasOlhoEsquerdo}
                        />
                    )
                }

                {this.hasMedida() &&
                    (
                        <PDFOrderMedidas
                            medidas={prescricao.medidas}
                            hasOlhoDireito={hasOlhoDireito}
                            hasOlhoEsquerdo={hasOlhoEsquerdo}
                        />
                    )
                }

                {this.hasPersonalizada() &&
                    (
                        <PDFOrderPersonalizados
                            personalizadas={prescricao.personalizadas}
                        />
                    )
                }
            </div>
        )
    }
}

export default PDFOrderPrescricao